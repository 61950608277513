import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { analytics } from 'client/utils/ga';
import { GENERAL_TEXTS } from 'client/translations/index';
import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { ALL_COUNTRY_DOC_ID, CALCULATION_TYPES, IS_LOCAL } from 'client/constants';
import { useCalculationType } from 'client/hooks';
import { Icon } from 'client/components/common';

import { BackgroundSvg } from './BackgroundSvg';

import clientLogo from 'client/assets/madlan-white-logo.svg';

import css from './TopSection.module.scss';

interface Props {
  className?: string;
  isNoResults?: boolean;
  cityName?: string;
}

const getNoResultIconColor = (type: CALCULATION_TYPES) => {
  switch (type) {
    case CALCULATION_TYPES.YOUNG:
      return '#66BFFF';
    case CALCULATION_TYPES.LUXURY:
      return '#BFFBFA';
    case CALCULATION_TYPES.INVESTMENT:
      return '#B8E8FF';
    default:
      return '#66BFFF';
  }
};

const TopSection: React.FC<Props> = (props) => {
  const { className = '', isNoResults = false, cityName = '' } = props;
  const { type, texts } = useCalculationType();

  const clickOnBackBtn = () => {
    if (!IS_LOCAL) {
      analytics.gtag.event('Back to search');
    }
  };

  const clickOnMadlanLogo = () => {
    if (!IS_LOCAL) {
      analytics.gtag.event('logo Result page', { CUSTOM_PARAMETER: 'Madlan' });
    }
  };

  const renderInfo = () => {
    if (cityName === ALL_COUNTRY_DOC_ID && !isNoResults) {
      return (
        <div className={cn(css.info, css.withoutCity)}>
          <p className={css.title}>{texts.resultsTopSection.withoutCity.title}</p>
          <p className={css.subTitle}>{texts.resultsTopSection.withoutCity.subtitle}</p>
          <p className={css.clientMoreInfo}>
            {GENERAL_TEXTS.results.madlanLogoText}{' '}
            <a href="https://www.madlan.co.il/" onClick={clickOnMadlanLogo} target="_blank" rel="noreferrer">
              <img src={clientLogo} alt="" />
            </a>
          </p>
        </div>
      );
    }

    if (cityName && !isNoResults) {
      return (
        <div className={cn(css.info, css.withCity)}>
          <p className={css.title}>{texts.resultsTopSection.withCity.title}</p>
          <p className={css.cityName}>ב{cityName}</p>
          <p className={css.clientMoreInfo}>
            {GENERAL_TEXTS.results.madlanLogoText}{' '}
            <a href="https://www.madlan.co.il/" target="_blank" rel="noreferrer">
              <img src={clientLogo} alt="" />
            </a>
          </p>
        </div>
      );
    }

    if (isNoResults) {
      return (
        <div className={cn(css.info, css.noResults)}>
          <div className={css.iconWrapper}>
            <Icon type="no-results" stopColor={getNoResultIconColor(type)} />
          </div>
          <p className={css.title} dangerouslySetInnerHTML={{ __html: texts.resultsTopSection.noResults.title }}></p>
          <p className={css.clientMoreInfo}>
            {GENERAL_TEXTS.results.madlanLogoText}{' '}
            <a href="https://www.madlan.co.il/" target="_blank" rel="noreferrer">
              <img src={clientLogo} alt="" />
            </a>
          </p>
        </div>
      );
    }
  };

  return (
    <div className={cn(css.topSection, className)}>
      <div className={css.wrapper}>
        <BackgroundSvg calculationType={type} />
        <Link to={MAIN_PAGE_ROUTE} onClick={clickOnBackBtn} className={css.backButton}>
          {GENERAL_TEXTS.results.backBtn}
          <Icon type="arrow-left" />
        </Link>
        {renderInfo()}
      </div>
    </div>
  );
};

export default TopSection;
