import { ApiResponse } from 'endpoints/endpoint';
import { leads } from 'endpoints/index';

import api from '..';

export const leadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLead: builder.mutation<ApiResponse<leads.CreateResult, leads.CreateError>, leads.CreateParams>({
      query: (params) => leads.create(params).args,
    }),
  }),
  overrideExisting: false,
});
