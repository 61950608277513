import React from 'react';
import cn from 'classnames';

import { analytics } from 'client/utils/ga';
import { IS_LOCAL } from 'client/constants';
import n12Logo from 'client/assets/n12logo_1.svg';

import css from './Header.module.scss';

type Props = {
  className?: string;
  isOnMain?: boolean;
};

const Header: React.FC<Props> = ({ className = '', isOnMain = false }) => {
  const clickOnLogo = () => {
    if (!IS_LOCAL) {
      if (isOnMain) {
        analytics.gtag.event('logo Login page', { CUSTOM_PARAMETER: 'ZiratNadlan' });
      } else {
        analytics.gtag.event('logo Result page', { CUSTOM_PARAMETER: 'ZiratNadlan' });
      }
    }
  };
  return (
    <header className={cn(css.header, className)}>
      <div className={css.headerWrapper}>
        <a
          href="https://www.mako.co.il/news-special_projects/the_real_estate_arena "
          target="_blank"
          rel="noreferrer"
          onClick={clickOnLogo}
        >
          <img src={n12Logo} alt="n12" />
        </a>
      </div>
    </header>
  );
};

export default Header;
