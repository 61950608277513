import React, { useEffect } from 'react';
import cn from 'classnames';
import { useMediaQuery } from '@mui/material';

import { analytics } from 'client/utils/ga';
import { IS_LOCAL } from 'client/constants';
import { useAppDispatch, useCalculationType } from 'client/hooks';
import { GENERAL_TEXTS as texts } from 'client/translations';
import { setLoading, setItems } from 'client/redux/apartments';
import { Header, RabbiCredit } from 'client/components/common';

import { Visual } from './Visual';
import { SearchForm } from './SearchForm';

import madlanLogo from 'client/assets/madlan-logo.svg';
import mobileBg from 'client/assets/main-mobile-bg.svg';

import css from './Main.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { texts: textByType } = useCalculationType();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setItems([]));
  });

  const clickOnMadlanLogo = () => {
    if (!IS_LOCAL) {
      analytics.gtag.event('logo Login page', { CUSTOM_PARAMETER: 'Madlan' });
    }
  };

  return (
    <>
      {isMobile && <div className={css.mobileBg} style={{ backgroundImage: `url(${mobileBg})` }}></div>}
      <div className={cn(css.main, className)}>
        <Header className={css.desktopHeader} isOnMain />
        <Visual className={css.decor} />

        <div className={css.contentWrap}>
          <div className={css.title}>
            <h1>
              {textByType.main.visual.preTitle} <span>{textByType.main.visual.title}</span>
            </h1>
            <p>{textByType.main.desktopDescription}</p>
          </div>

          <SearchForm />

          <div className={css.borderRight} />

          <div className={css.madlanLogoWrap}>
            <p>{texts.madeWith}</p>
            <a
              href="https://www.madlan.co.il/?utm_source=mako"
              onClick={clickOnMadlanLogo}
              target="_blank"
              rel="noreferrer"
            >
              <img src={madlanLogo} alt="madlan" />
            </a>
          </div>
        </div>

        <RabbiCredit className={css.rabbiCredit} />
      </div>
    </>
  );
};

export default Main;
