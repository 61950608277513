import * as yup from 'yup';

import { Admin } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type SignInParams =
  | {
      provider: 'google';
      email: string;
    }
  | {
      provider: 'phone';
      phone: string;
    };

export type SignInResult = Omit<Admin.Model, 'password'> & { token: string };
export type SignInError = EndpointError;

export const googleSignInParamsSchema = yup.object().shape({
  provider: yup.string().oneOf(['google'], VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
  email: yup.string().email(VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<SignInParams & { provider: 'google' }>);

export const phoneSignInParamsSchema = yup.object().shape({
  provider: yup.string().oneOf(['phone'], VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
  phone: yup
    .string()
    .min(9, VALIDATION_ERROR.INVALID_VALUE)
    .max(13, VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<SignInParams & { provider: 'phone' }>);

export const signIn = endpoint<SignInParams, SignInResult, SignInError>({
  method: 'POST',
  url: () => `/auth/admin`,
});
