import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '..';

export const selectSettings = (state: RootState) => {
  return state.settings;
};

export const selectLang = (state: RootState) => {
  return state.settings.lang;
};

export const selectHost = (state: RootState) => {
  return state.settings.host;
};

export const selectTexts = createSelector(selectSettings, selectLang, (settings, lang) => {
  return settings.texts[lang];
});

export const selectGeneralTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'general', {});
});
