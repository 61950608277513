import cn from 'classnames';
import React from 'react';

import css from './FormFieldTitle.module.scss';

interface Props {
  className?: string;
  title: string;
  additionalText?: string;
}

export const FormFieldTitle: React.FC<Props> = (props) => {
  const { className = '', title, additionalText = '' } = props;

  return (
    <div className={cn(css.formFieldTitle, className)}>
      <h4>
        {title} {additionalText && <span>{additionalText}</span>}
      </h4>
    </div>
  );
};
