import React from 'react';
import cn from 'classnames';

import { useMediaQuery } from '@mui/material';
import { GENERAL_TEXTS } from 'client/translations/index';
import css from './StickyBottom.module.scss';

type Props = {
  onShowForm: () => void;
};

const StickyBottom: React.FC<Props> = ({ onShowForm }) => {
  const isMobile = useMediaQuery('(max-width:745px)');
  return (
    <div className={css.stickyBottom}>
      <div
        className={css.formLink}
        onClick={onShowForm}
        dangerouslySetInnerHTML={{ __html: `${GENERAL_TEXTS.results.registerNow} ${isMobile ? '' : '>>'}` }}
      ></div>
    </div>
  );
};

export default StickyBottom;
