import React, { useState, useRef } from 'react';
import cn from 'classnames';

import useOnClickOutside from 'client/hooks/click-outside';
import { Icon } from 'client/components/common';
import { ORDER_OPTIONS } from 'client/constants';
import { GENERAL_TEXTS } from 'client/translations/index';
import css from './SortSelect.module.scss';

type Props = {
  setOrder: (value: 'asc' | 'desc') => void;
  order: 'asc' | 'desc';
};

const options = [
  { label: GENERAL_TEXTS.sortSelect.ascending, order: ORDER_OPTIONS.ascending },
  { label: GENERAL_TEXTS.sortSelect.descending, order: ORDER_OPTIONS.descending },
];

const SortSelect: React.FC<Props> = ({ setOrder, order }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const renderCurrentOption =
    order === 'asc' ? GENERAL_TEXTS.sortSelect.ascending : GENERAL_TEXTS.sortSelect.descending;

  const hideDropDown = () => {
    setIsOpenDropdown(false);
  };

  const onSelectClick = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const clickOnItem = (e: React.SyntheticEvent<HTMLLIElement>) => {
    const order = e.currentTarget.dataset.order;
    if (order && (order === 'asc' || order === 'desc')) {
      setOrder(order);
      setIsOpenDropdown(false);
    }
  };

  useOnClickOutside(selectRef, hideDropDown);

  return (
    <div className={cn(css.sortSelect, isOpenDropdown && css.showDropdown)} ref={selectRef}>
      <div className={css.text} onClick={onSelectClick}>
        {renderCurrentOption} <Icon type="arrow-down" />
      </div>
      <ul className={css.dropdown}>
        {options.map((option) => {
          if (option.order === order) return null;
          return (
            <li
              className={cn(option.order === order && css.active)}
              key={option.order}
              data-order={option.order}
              onClick={clickOnItem}
            >
              {option.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SortSelect;
