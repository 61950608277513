import * as React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import css from './Checkbox.module.scss';

interface Props {
  className?: string;
  name?: string;
  label: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { className = '', name = '', label } = props;
  const [field, meta] = useField(name);

  return (
    <div className={cn(css.checkbox, className)}>
      <label className={cn(meta.value && css.active)}>
        <input {...field} type="checkbox" />
      </label>
      <div className={css.confirmation} dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
};

export default Checkbox;
