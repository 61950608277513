import React from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { CSSTransition } from 'react-transition-group';
import { Formik, Form, FormikProps } from 'formik';
import _ from 'lodash';

import { useCalculationType } from 'client/hooks';
import { leadApi } from 'client/redux/api/lead';

import { analytics } from 'client/utils/ga';

import TextFieldInput from './TextInput';
import Checkbox from './Checkbox';
import { RedButton } from 'client/components/common';
import { BackgroundSvg } from '../BackgroundSvg';
import { GENERAL_TEXTS } from 'client/translations/index';
import { REG_EXP, CALCULATION_TYPES, IS_LOCAL } from 'client/constants';
import css from './LeadsForm.module.scss';

type Props = {
  onHideForm: () => void;
  visible: boolean;
  showThanks: () => void;
};

type FormData = {
  name: string;
  phoneNumber: string;
  email: string;
  isApprovedConditions: boolean;
  isApprovedTerms: boolean;
};

const formValues: FormData = {
  name: '',
  phoneNumber: '',
  email: '',
  isApprovedConditions: false,
  isApprovedTerms: false,
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(GENERAL_TEXTS.leadsForm.errors.name.required)
    .test('len', GENERAL_TEXTS.leadsForm.errors.name.required, (value) => {
      if (value && value.trim().split(' ').length === 2) {
        return true;
      }

      return false;
    }),
  phoneNumber: yup
    .string()
    .min(10, GENERAL_TEXTS.leadsForm.errors.phoneNumber.invalid)
    .max(10, GENERAL_TEXTS.leadsForm.errors.phoneNumber.invalid)
    .required(GENERAL_TEXTS.leadsForm.errors.phoneNumber.required),
  email: yup
    .string()
    .email(GENERAL_TEXTS.leadsForm.errors.email.invalid)
    .required(GENERAL_TEXTS.leadsForm.errors.email.required),
  isApprovedConditions: yup.boolean().oneOf([true], GENERAL_TEXTS.leadsForm.errors.checkboxes),
  isApprovedTerms: yup.boolean().oneOf([true], GENERAL_TEXTS.leadsForm.errors.checkboxes),
});

const LeadsForm: React.FC<Props> = ({ onHideForm, visible, showThanks }) => {
  const { type } = useCalculationType();
  const formikRef = React.useRef<FormikProps<FormData>>(null);
  const [createLead, { isLoading, isSuccess }] = leadApi.endpoints.createLead.useMutation();

  const handleSubmit = async (values: FormData) => {
    console.log('SUBMIT_LEADS_FORM');

    const leadData: FormData & { leadType: CALCULATION_TYPES } = {
      ...values,
      leadType: type,
    };

    await createLead(leadData);
    if (!IS_LOCAL) {
      analytics.gtag.event('to register');
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      onHideForm();
      showThanks();
    }
  }, [isSuccess]);

  return (
    <CSSTransition
      classNames={{
        enter: css.opacityAnimEnter,
        enterActive: css.opacityAnimEnterActive,
        exit: css.opacityAnimExit,
        exitActive: css.opacityAnimExitActive,
      }}
      in={visible}
      unmountOnExit
      timeout={300}
    >
      <div className={css.leadsForm}>
        <div className={css.overlay} onClick={onHideForm}></div>
        <div className={css.formWrapper}>
          <div className={css.top}>
            <div className={css.closeBtn} onClick={onHideForm}></div>
            <div className={css.backgroundWrapper}>
              <BackgroundSvg calculationType={type} />
            </div>
            <div className={css.text}>
              <p className={css.title} dangerouslySetInnerHTML={{ __html: GENERAL_TEXTS.leadsForm.top.title }}></p>
              <p
                className={css.subTitle}
                dangerouslySetInnerHTML={{ __html: GENERAL_TEXTS.leadsForm.top.subTitle }}
              ></p>
            </div>
          </div>
          <div className={css.form}>
            <Formik
              innerRef={formikRef}
              initialValues={_.cloneDeep(formValues)}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ ...rest }) => {
                return (
                  <Form>
                    <TextFieldInput
                      name="name"
                      placeholder={GENERAL_TEXTS.leadsForm.placeholders.name}
                      label={GENERAL_TEXTS.leadsForm.labels.name}
                      className={cn(css.fieldWrapper, css.textField)}
                      pattern={REG_EXP.HEBREW_ONLY_LETTERS}
                    />
                    <TextFieldInput
                      name="phoneNumber"
                      placeholder={GENERAL_TEXTS.leadsForm.placeholders.phoneNumber}
                      label={GENERAL_TEXTS.leadsForm.labels.phoneNumber}
                      className={cn(css.fieldWrapper, css.textField)}
                      direction="ltr"
                      pattern={REG_EXP.NUMBERS}
                    />
                    <TextFieldInput
                      name="email"
                      placeholder={GENERAL_TEXTS.leadsForm.placeholders.email}
                      label={GENERAL_TEXTS.leadsForm.labels.email}
                      className={cn(css.fieldWrapper, css.textField)}
                      direction="ltr"
                      pattern={REG_EXP.EMAIL}
                    />
                    <div className={css.checkboxesWrapper}>
                      <Checkbox
                        name="isApprovedConditions"
                        label={GENERAL_TEXTS.leadsForm.labels.isApprovedConditions}
                      />
                      <Checkbox name="isApprovedTerms" label={GENERAL_TEXTS.leadsForm.labels.isApprovedTerms} />
                    </div>
                    {
                      <div className={css.checkboxesError}>
                        {rest.errors.isApprovedConditions || rest.errors.isApprovedTerms ? (
                          GENERAL_TEXTS.leadsForm.errors.checkboxes.required
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                    }
                    <div className={css.submitBtnWarpper}>
                      <RedButton
                        type="submit"
                        label={GENERAL_TEXTS.leadsForm.submitButton}
                        withIcon
                        iconType="left-quotes"
                        iconClass="left-quotes"
                        withoutRoundCorners
                        fullWidth
                        className={cn(css.button, isLoading && css.disabled)}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default LeadsForm;
