import { useField } from 'formik';
import cn from 'classnames';
import React from 'react';
import { Slider, styled } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { getThumbBgColor, getTrackBgColor } from 'client/utils/calculation-type-helper';
import { thousandsSeparator } from 'utils/thousand-separator';
import { GENERAL_TEXTS as texts } from 'client/translations';
import { FormFieldTitle } from 'client/components/common';
import { CALCULATION_TYPES } from 'client/constants';

import { fieldToNumber } from '../helpers';

import css from './RangeSlider.module.scss';

const STEP = 100000;

const StyledSlider = styled(Slider)<{ calculation_type: CALCULATION_TYPES }>(({ calculation_type }) => {
  const trackBgColor = getTrackBgColor(calculation_type);
  const thumbBgColor = getThumbBgColor(calculation_type);

  return {
    color: '#3a8589',
    height: 2,
    display: 'block',
    position: 'relative',
    zIndex: 2,
    padding: '13px 0 !important',
    '& .MuiSlider-thumb': {
      height: 30,
      width: 30,
      zIndex: 2,
      background: thumbBgColor,

      border: 'none',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      '&:hover': {
        boxShadow: 'initial',
      },
      '&:focus': {
        boxShadow: 'initial',
      },
    },
    '& .MuiSlider-track': {
      height: 2,
      border: 'none',
      backgroundImage: trackBgColor,
    },
    '& .MuiSlider-rail': {
      color: '#FFFFFF',
      opacity: 1,
      height: 2,
      border: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      zIndex: 1,
      width: 30 / 2,
      height: 2,
      backgroundColor: '#fff',
      transform: `translate(${30 / 2}px, -50%)`,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      zIndex: 1,
      width: 15,
      height: 2,
      backgroundColor: '#fff',
      transform: `translate(-${30 / 2}px, -50%)`,
    },
  };
});

interface Props {
  className?: string;
  min: number;
  max: number;
  name: string;
  calculationType: CALCULATION_TYPES;
}

export const RangeSlider: React.FC<Props> = (props) => {
  const { className = '', name, calculationType, min, max } = props;
  const [field, meta, helpers] = useField(name);
  const fieldValueNumber = [fieldToNumber(field.value[0]), fieldToNumber(field.value[1])];

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const val = newValue as number[];
    let newVal = [];

    if (activeThumb === 0) {
      newVal = [Math.min(val[0], val[1] - STEP), val[1]];
    } else {
      newVal = [val[0], Math.max(newValue[1], val[0] + STEP)];
    }

    helpers.setValue([thousandsSeparator(newVal[0]), thousandsSeparator(newVal[1])]);
  };

  const onMaxValueInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      helpers.setValue([field.value[0], event.target.value]);
    }
  };

  const onInputBlur = () => {
    const from = fieldToNumber(field.value[0]);
    const to = fieldToNumber(field.value[1]);

    if (from === 0) {
      helpers.setValue([thousandsSeparator(min), field.value[1]]);
    }

    if (to === 0) {
      helpers.setValue([field.value[0], thousandsSeparator(max)]);
    }

    if (to > max || to < min) {
      helpers.setValue([field.value[0], thousandsSeparator(max)]);
    } else if (from < min || from > max) {
      helpers.setValue([thousandsSeparator(min), field.value[1]]);
    } else if (to < from) {
      helpers.setValue([field.value[0], thousandsSeparator(Math.min(fieldToNumber(field.value[0]) + 100000, max))]);
    }
  };

  const onMinValueInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue([event.target.value, field.value[1]]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
    }
  };

  return (
    <div className={cn(css.rangeSlider, className)}>
      <FormFieldTitle
        className={css.fieldTitle}
        title={texts.searchForm.rangeSliderTitle}
        additionalText={texts.searchForm.rangeSliderAddText}
      />

      <div className={css.info}>
        <div className={css.infoItem}>
          <p className={css.title}>{texts.searchForm.rangeSliderMaxInputLabel}</p>
          <NumericFormat
            onChange={onMaxValueInputChange}
            onBlur={onInputBlur}
            className={css.input}
            value={field.value[1]}
            thousandSeparator=","
            inputMode="numeric"
            onKeyDown={onKeyDown}
            onFocus={() => {
              helpers.setValue([field.value[0], '']);
            }}
          />
        </div>
        <div className={css.infoItem}>
          <p className={css.title}>{texts.searchForm.rangeSliderMinInputLabel}</p>
          <NumericFormat
            onChange={onMinValueInputChange}
            onBlur={onInputBlur}
            className={css.input}
            value={field.value[0]}
            thousandSeparator=","
            inputMode="numeric"
            onKeyDown={onKeyDown}
            onFocus={() => {
              helpers.setValue(['', field.value[1]]);
            }}
          />
        </div>
      </div>

      <div className={css.sliderWrap}>
        <StyledSlider
          calculation_type={calculationType}
          value={fieldValueNumber}
          onChange={handleChange}
          disableSwap
          step={STEP}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};
