import cn from 'classnames';
import React from 'react';
import { useField } from 'formik';
import _ from 'lodash';

import { FormFieldTitle } from 'client/components/common';
import { useCalculationType } from 'client/hooks';

import css from './RealtySelect.module.scss';
import { CALCULATION_TYPES } from 'client/constants';

interface Props {
  className?: string;
  name: string;
  options: Array<{ value: string; label: string }>;
}

export const RealtySelect: React.FC<Props> = (props) => {
  const { className = '', options, name } = props;
  const [field, meta, helpers] = useField(name);
  const { texts, type } = useCalculationType();

  const handleItemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget.dataset;
    const currentValue = [...field.value];

    if (!value) return;

    const index = currentValue.findIndex((v) => v === value);

    if (type === CALCULATION_TYPES.YOUNG) {
      if (index >= 0) {
        //Click on same option
        currentValue.splice(index, 1);
        helpers.setValue(currentValue);
      } else {
        const isSelectedAll = !!currentValue.find((val) => val === '1');
        if (isSelectedAll) {
          //remove all option from seleted options array
          const selectAllindex = currentValue.findIndex((v) => v === '1');
          currentValue.splice(selectAllindex, 1);
          helpers.setValue([...currentValue, value]);
        } else {
          if (value === '1') {
            //set only all option to array (if selected another options)
            helpers.setValue([value]);
          } else {
            helpers.setValue([...currentValue, value]);
          }
        }
      }
    } else {
      if (index >= 0) {
        currentValue.splice(index, 1);
        helpers.setValue(currentValue);
      } else {
        helpers.setValue([...currentValue, value]);
      }
    }
  };

  return (
    <div className={cn(css.realtySelect, className)}>
      <FormFieldTitle className={css.fieldTitle} title={texts.main.searchForm.realtySelectTitle} />

      <div className={css.items}>
        {_.map(options, (item) => {
          return (
            <button
              onClick={handleItemClick}
              type="button"
              key={item.value}
              data-value={item.value}
              className={cn(css.item, field.value.includes(item.value) && css.selected)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
