import React from 'react';
import { CALCULATION_TYPES } from 'client/constants';
import { useMediaQuery } from '@mui/material';
import { getGradientStartEndColors } from 'client/utils/calculation-type-helper';

type BackgroundSvgProps = {
  calculationType: CALCULATION_TYPES;
};

export const BackgroundSvg = ({ calculationType }: BackgroundSvgProps) => {
  const isMobile = useMediaQuery('(max-width:745px)');
  const isDesktop = useMediaQuery('(min-width:1025px)');
  const { startColor, endColor } = getGradientStartEndColors(calculationType);

  const returnBg = () => {
    if (isDesktop) {
      return (
        <svg width="654" height="972" viewBox="0 0 654 972" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_404_42042)">
            <path
              d="M772.921 1201C357.094 1201 19.9999 864.766 20 450C20 35.2341 357.094 -301 772.921 -301C1188.75 -301 876.448 35.2341 876.448 450C876.448 864.766 1188.75 1201 772.921 1201Z"
              fill="#3D57DF"
            />
            <path
              d="M772.921 1201C357.094 1201 19.9999 864.766 20 450C20 35.2341 357.094 -301 772.921 -301C1188.75 -301 876.448 35.2341 876.448 450C876.448 864.766 1188.75 1201 772.921 1201Z"
              fill="url(#paint0_linear_404_42042)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_404_42042"
              x="0"
              y="-321"
              width="1009"
              height="1542"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_404_42042" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_404_42042" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_404_42042"
              x1="900.092"
              y1="1106.13"
              x2="2.39788"
              y2="-285.266"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={endColor} />
              <stop offset="1" stopColor={startColor} />
            </linearGradient>
          </defs>
        </svg>
      );
    }

    if (isMobile) {
      return (
        <svg width="375" height="198" viewBox="0 0 375 198" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_459_51683)">
            <path
              d="M534 -105.608C534 51.0243 381.105 178 192.5 178C3.89474 178 -149 51.0243 -149 -105.608C-149 -262.241 3.89474 -144.604 192.5 -144.604C381.105 -144.604 534 -262.241 534 -105.608Z"
              fill="white"
              fillOpacity="0.6"
            />
            <path
              d="M534 -105.608C534 51.0243 381.105 178 192.5 178C3.89474 178 -149 51.0243 -149 -105.608C-149 -262.241 3.89474 -144.604 192.5 -144.604C381.105 -144.604 534 -262.241 534 -105.608Z"
              fill="url(#paint0_linear_459_51683)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_459_51683"
              x="-169"
              y="-207"
              width="723"
              height="405"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_459_51683" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_459_51683" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_459_51683"
              x1="490.858"
              y1="-153.51"
              x2="-66.8749"
              y2="280.887"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={endColor} />
              <stop offset="1" stopColor={startColor} />
            </linearGradient>
          </defs>
        </svg>
      );
    }

    // iframe
    return calculationType === CALCULATION_TYPES.INVESTMENT ? (
      <svg width="395" height="393" viewBox="0 0 395 393" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_496_62041)">
          <path
            d="M396.849 590C188.721 590 20 421.659 20 214C20 6.34091 188.721 -162 396.849 -162C604.977 -162 448.666 6.34092 448.666 214C448.666 421.659 604.977 590 396.849 590Z"
            fill="url(#paint0_linear_496_62041)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_496_62041"
            x="0"
            y="-182"
            width="525"
            height="792"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_62041" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_62041" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_496_62041"
            x1="460.5"
            y1="542.5"
            x2="11"
            y2="-154"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#38A4F2" />
            <stop offset="1" stopColor="#2FA5E7" />
          </linearGradient>
        </defs>
      </svg>
    ) : (
      <svg width="395" height="519" viewBox="0 0 395 519" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_400_65266)">
          <path
            d="M396.849 655C188.721 655 20 486.659 20 279C20 71.3409 188.721 -97 396.849 -97C604.977 -97 448.666 71.3409 448.666 279C448.666 486.659 604.977 655 396.849 655Z"
            fill="url(#paint0_linear_400_65266)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_400_65266"
            x="0"
            y="-117"
            width="525"
            height="792"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_400_65266" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_400_65266" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_400_65266"
            x1="460.5"
            y1="607.5"
            x2="11"
            y2="-89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={endColor} />
            <stop offset="1" stopColor={startColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  return <>{returnBg()}</>;
};
