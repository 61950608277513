import React from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useCalculationType } from 'client/hooks';
import { analytics } from 'client/utils/ga';

import { IS_LOCAL } from 'client/constants';
import { RedButton, Icon } from 'client/components/common';
import { BackgroundSvg } from '../BackgroundSvg';
import { GENERAL_TEXTS } from 'client/translations/index';
import css from './ThanksPopup.module.scss';

type Props = {
  visible: boolean;
  hideThanks: () => void;
};

const ThanksPopup: React.FC<Props> = ({ visible, hideThanks }) => {
  const { type } = useCalculationType();

  const clickOnBtn = () => {
    if (!IS_LOCAL) {
      analytics.gtag.event('Back to results page');
    }
    hideThanks();
  };

  return (
    <CSSTransition
      classNames={{
        enter: css.opacityAnimEnter,
        enterActive: css.opacityAnimEnterActive,
        exit: css.opacityAnimExit,
        exitActive: css.opacityAnimExitActive,
      }}
      in={visible}
      unmountOnExit
      timeout={300}
    >
      <div className={css.thanksPopup}>
        <div className={css.overlay} onClick={hideThanks}></div>
        <div className={css.popupWrapper}>
          <div className={css.top}>
            <div className={css.closeBtn} onClick={hideThanks}></div>
            <div className={css.backgroundWrapper}>
              <BackgroundSvg calculationType={type} />
            </div>
            <div className={css.info}>
              <div className={css.iconWrapper}>
                <Icon type="rounded-check" />
              </div>
              <p className={css.title}>{GENERAL_TEXTS.thanksPopup.title}</p>
              <p className={css.subTitle}>{GENERAL_TEXTS.thanksPopup.subTitle}</p>
            </div>
          </div>
          <div className={css.buttonWrapper} onClick={clickOnBtn}>
            <RedButton withoutRoundCorners label={GENERAL_TEXTS.thanksPopup.button} className={css.button} fullWidth />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ThanksPopup;
