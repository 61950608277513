import { selectSettings } from 'client/redux/settings/selectors';
import { useAppSelector } from './redux';
import { useMemo } from 'react';

import buildingYoung from 'client/assets/building-young.svg';
import buildingLuxury from 'client/assets/building-luxury.svg';
import buildingInvestment from 'client/assets/building-investment.svg';
import shareImgYoung from 'client/assets/shareYoung.jpg';
import shareImgLuxury from 'client/assets/shareLuxury.jpg';
import shareImgInvestment from 'client/assets/shareInvestment.jpg';

import { TEXTS_BY_CALC_TYPE, TextByCalcTypItem } from 'client/translations';
import { getGradientStartEndColors, getPrimaryColor } from 'client/utils/calculation-type-helper';
import { CALCULATION_TYPES } from 'client/constants';

const BUILDING_IMAGES_MAP = {
  [CALCULATION_TYPES.YOUNG]: buildingYoung,
  [CALCULATION_TYPES.LUXURY]: buildingLuxury,
  [CALCULATION_TYPES.INVESTMENT]: buildingInvestment,
};

const SHARE_IMAGES_MAP = {
  [CALCULATION_TYPES.YOUNG]: shareImgYoung,
  [CALCULATION_TYPES.LUXURY]: shareImgLuxury,
  [CALCULATION_TYPES.INVESTMENT]: shareImgInvestment,
};

type Result = {
  color: string;
  gradientStartColor: string;
  gradientEndColor: string;
  rangeSliderInitialValues: number[];
  type: CALCULATION_TYPES;
  texts: TextByCalcTypItem;
  images: Record<string, string>;
};

export const useCalculationType = (): Result => {
  const settings = useAppSelector(selectSettings);
  const texts = TEXTS_BY_CALC_TYPE[settings.calculationType];
  const color = getPrimaryColor(settings.calculationType);
  const { startColor, endColor } = getGradientStartEndColors(settings.calculationType);
  const rangeSliderInitialValues = getRangeSliderInitialValues(settings.calculationType);

  return useMemo(() => {
    return {
      type: settings.calculationType,
      color,
      gradientStartColor: startColor,
      gradientEndColor: endColor,
      texts,
      rangeSliderInitialValues,
      images: {
        mainVisualBuilding: BUILDING_IMAGES_MAP[settings.calculationType],
        shareImg: SHARE_IMAGES_MAP[settings.calculationType],
      },
    };
  }, [settings.calculationType]);
};

const getRangeSliderInitialValues = (type: CALCULATION_TYPES) => {
  switch (type) {
    case CALCULATION_TYPES.YOUNG:
      return [1000000, 4000000];
    case CALCULATION_TYPES.LUXURY:
      return [5000000, 20000000];
    case CALCULATION_TYPES.INVESTMENT:
      return [600000, 5000000];
    default:
      return [1000000, 4000000];
  }
};

export default useCalculationType;
