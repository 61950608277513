import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

export type IconType = 'share' | 'arrow-left' | 'no-results' | 'arrow-down' | 'left-quotes' | 'rounded-check';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
  stopColor?: string;
}

const Icon = ({ type, className, width, height, color = '#000', secondaryColor, stopColor, ...iconProps }: Props) => {
  let icon = null;
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width || 38} ${height || 38}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'share':
      icon = (
        <svg {...svgProps} width="18px" height="19px" viewBox="0 0 18 19">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-26.000000, -25.000000)" fill="#FFFFFF">
              <g transform="translate(19.000000, 16.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <path d="M18.9071445,21.0668198 L13.2835484,17.9733159 C13.3708825,17.6968823 13.4182477,17.4037144 13.4182477,17.0999998 C13.4182477,16.7961441 13.3708461,16.5028707 13.2834027,16.2262613 L18.9068895,13.1328629 C19.4752718,13.727109 20.2885685,14.1000465 21.1909483,14.1000465 C22.9056775,14.1000465 24.2999994,12.7545114 24.2999994,11.0999528 C24.2999994,9.44553491 22.9056775,8.09999979 21.1909483,8.09999979 C19.4762191,8.09999979 18.0818972,9.44553491 18.0818972,11.0999528 C18.0818972,11.3449216 18.1131582,11.5828591 18.170798,11.8109528 L12.4707617,14.9465387 C11.9108323,14.4230973 11.1486171,14.1000465 10.3090509,14.1000465 C8.59446744,14.1000465 7.19999981,15.445441 7.19999981,17.0999998 C7.19999981,18.7545229 8.59446744,20.0999526 10.3090509,20.0999526 C11.1487264,20.0999526 11.9110874,19.7767964 12.4710168,19.2532143 L18.1709073,22.3886596 C18.1131947,22.6168588 18.0818972,22.8549017 18.0818972,23.1000463 C18.0818972,24.7544642 19.4762191,26.0999998 21.1909483,26.0999998 C22.9056775,26.0999998 24.2999994,24.7544642 24.2999994,23.1000463 C24.2999994,21.4454877 22.9056775,20.0999526 21.1909483,20.0999526 C20.2887143,20.0999526 19.4755633,20.4727846 18.9071445,21.0668198 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
      break;
    case 'arrow-left':
      icon = (
        <svg {...svgProps} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_496_56208)">
            <path
              d="M23.3334 12.8337H9.13508L15.6567 6.31199L14.0001 4.66699L4.66675 14.0003L14.0001 23.3337L15.6451 21.6887L9.13508 15.167H23.3334V12.8337Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_496_56208">
              <rect width="28" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case 'no-results':
      icon = (
        <svg {...svgProps} viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_496_56665)">
            <path
              d="M62.7826 19.2755C57.0741 13.5671 49.4834 10.4229 41.4096 10.4229C33.3358 10.4229 25.7459 13.5671 20.0367 19.2755C14.3282 24.984 11.184 32.5747 11.184 40.6485C11.184 48.7223 14.3282 56.3122 20.0367 62.0215C25.7451 67.73 33.3358 70.8742 41.4096 70.8742C49.4834 70.8742 57.0733 67.73 62.7826 62.0215C68.4911 56.313 71.6353 48.7223 71.6353 40.6485C71.6353 32.5747 68.4911 24.9848 62.7826 19.2755ZM61.2008 60.4389C55.9143 65.7255 48.8857 68.6366 41.4104 68.6366C33.9351 68.6366 26.9057 65.7255 21.62 60.4389C16.3335 55.1524 13.4223 48.1246 13.4223 40.6485C13.4223 33.1724 16.3335 26.1439 21.62 20.8581C27.0761 15.402 34.2432 12.6736 41.4104 12.6736C48.5776 12.6736 55.7447 15.402 61.2008 20.8581C66.4874 26.1446 69.3985 33.1732 69.3985 40.6485C69.3985 48.1238 66.4874 55.1532 61.2008 60.4389Z"
              fill="url(#paint0_linear_496_56665)"
            />
            <path
              d="M99.3773 86.3349L76.164 65.1369C75.1482 64.2093 73.6872 64.0738 72.5413 64.7118L68.6515 60.8212C72.9679 55.0183 75.3015 48.0122 75.3015 40.6476C75.3015 31.5951 71.7764 23.0839 65.3749 16.6824C58.9735 10.281 50.463 6.75586 41.4098 6.75586C32.3566 6.75586 23.8461 10.281 17.4446 16.6824C11.0432 23.0839 7.51807 31.5943 7.51807 40.6476C7.51807 49.7008 11.0432 58.2113 17.4446 64.6127C23.8461 71.0142 32.3566 74.5393 41.4098 74.5393C48.7744 74.5393 55.7797 72.2057 61.5827 67.8893L65.4733 71.7799C64.8353 72.925 64.9715 74.386 65.8991 75.4018L87.0971 98.6151C87.6778 99.2508 88.4721 99.6124 89.3331 99.6317C89.3579 99.6317 89.3819 99.6325 89.4066 99.6325C90.2405 99.6325 91.0217 99.3089 91.6132 98.7173L99.4803 90.8503C100.09 90.2409 100.414 89.4318 100.395 88.5709C100.375 87.7099 100.014 86.9156 99.3781 86.3349H99.3773ZM19.0264 63.0309C13.0477 57.0522 9.75485 49.1031 9.75485 40.6483C9.75485 32.1936 13.0477 24.2445 19.0264 18.2658C25.0051 12.2871 32.9543 8.9942 41.409 8.9942C49.8637 8.9942 57.8129 12.2871 63.7916 18.2658C69.7703 24.2445 73.0631 32.1936 73.0631 40.6483C73.0631 49.1031 69.7703 57.0522 63.7916 63.0309C51.4494 75.3731 31.3678 75.3731 19.0256 63.0309H19.0264ZM63.3425 66.4864C64.0401 65.8918 64.7191 65.2685 65.3741 64.6135C66.0292 63.9585 66.6524 63.2802 67.247 62.5819L70.8744 66.2092L66.9699 70.1137L63.3425 66.4864ZM97.897 89.2693L90.0299 97.1363C89.8573 97.309 89.6273 97.4004 89.3826 97.3957C89.138 97.3903 88.9127 97.2881 88.7478 97.1069L67.5498 73.8936C67.2292 73.5428 67.2416 73.0047 67.5776 72.6687L73.4294 66.817C73.6028 66.6436 73.8289 66.5568 74.0565 66.5568C74.2702 66.5568 74.4839 66.6343 74.6542 66.7891L97.8676 87.9871C98.048 88.152 98.1502 88.3773 98.1564 88.622C98.1618 88.8667 98.0696 89.0958 97.897 89.2693Z"
              fill="url(#paint1_linear_496_56665)"
            />
            <path
              d="M12.4738 16.6295C12.4738 16.0117 11.9729 15.5107 11.3551 15.5107H1.72425C1.1064 15.5107 0.605469 16.0117 0.605469 16.6295C0.605469 17.2474 1.1064 17.7483 1.72425 17.7483H11.3551C11.9729 17.7483 12.4738 17.2474 12.4738 16.6295Z"
              fill="url(#paint2_linear_496_56665)"
            />
            <path
              d="M17.2347 11.8684C17.8525 11.8684 18.3535 11.3674 18.3535 10.7496V1.11878C18.3535 0.500935 17.8525 0 17.2347 0C16.6168 0 16.1159 0.500935 16.1159 1.11878V10.7496C16.1159 11.3674 16.6168 11.8684 17.2347 11.8684Z"
              fill="url(#paint3_linear_496_56665)"
            />
            <path
              d="M10.3123 11.2891C10.5307 11.5074 10.8171 11.6166 11.1036 11.6166C11.3901 11.6166 11.6765 11.5074 11.8949 11.2891C12.3315 10.8524 12.3315 10.144 11.8949 9.70732L5.17521 2.98766C4.73854 2.55099 4.03011 2.55099 3.59343 2.98766C3.15676 3.42433 3.15676 4.13277 3.59343 4.56944L10.3131 11.2891H10.3123Z"
              fill="url(#paint4_linear_496_56665)"
            />
            <path
              d="M51.5792 3.71803H93.4789V63.5639C93.4789 64.1818 93.9798 64.6827 94.5977 64.6827C95.2155 64.6827 95.7164 64.1818 95.7164 63.5639V2.59925C95.7164 1.9814 95.2155 1.48047 94.5977 1.48047H51.5792C50.9613 1.48047 50.4604 1.9814 50.4604 2.59925C50.4604 3.2171 50.9613 3.71803 51.5792 3.71803Z"
              fill="url(#paint5_linear_496_56665)"
            />
            <path
              d="M76.4224 98.7628H9.67879V69.0504C9.67879 68.4326 9.17785 67.9316 8.56 67.9316C7.94216 67.9316 7.44122 68.4326 7.44122 69.0504V99.8816C7.44122 100.499 7.94216 101 8.56 101H76.4224C77.0402 101 77.5412 100.499 77.5412 99.8816C77.5412 99.2637 77.0402 98.7628 76.4224 98.7628Z"
              fill="url(#paint6_linear_496_56665)"
            />
            <path
              d="M88.0592 26.585H80.998C80.3802 26.585 79.8793 27.0859 79.8793 27.7037C79.8793 28.3216 80.3802 28.8225 80.998 28.8225H88.0592C88.677 28.8225 89.1779 28.3216 89.1779 27.7037C89.1779 27.0859 88.677 26.585 88.0592 26.585Z"
              fill="url(#paint7_linear_496_56665)"
            />
            <path
              d="M88.0592 37.4385H80.998C80.3802 37.4385 79.8793 37.9394 79.8793 38.5573C79.8793 39.1751 80.3802 39.676 80.998 39.676H88.0592C88.677 39.676 89.1779 39.1751 89.1779 38.5573C89.1779 37.9394 88.677 37.4385 88.0592 37.4385Z"
              fill="url(#paint8_linear_496_56665)"
            />
            <path
              d="M88.0592 48.291H80.998C80.3802 48.291 79.8793 48.792 79.8793 49.4098C79.8793 50.0276 80.3802 50.5286 80.998 50.5286H88.0592C88.677 50.5286 89.1779 50.0276 89.1779 49.4098C89.1779 48.792 88.677 48.291 88.0592 48.291Z"
              fill="url(#paint9_linear_496_56665)"
            />
            <path
              d="M34.7984 85.183C34.7984 79.5914 30.2489 75.042 24.6573 75.042C19.0657 75.042 14.5163 79.5914 14.5163 85.183C14.5163 90.7746 19.0657 95.3241 24.6573 95.3241C30.2489 95.3241 34.7984 90.7746 34.7984 85.183ZM16.7539 85.183C16.7539 80.8248 20.2991 77.2796 24.6573 77.2796C29.0155 77.2796 32.5608 80.8248 32.5608 85.183C32.5608 89.5412 29.0155 93.0865 24.6573 93.0865C20.2991 93.0865 16.7539 89.5412 16.7539 85.183Z"
              fill="url(#paint10_linear_496_56665)"
            />
            <path
              d="M46.0513 88.3045C46.0513 88.8658 46.5066 89.3218 47.0687 89.3218H62.1053C62.6666 89.3218 63.1226 88.8666 63.1226 88.3045C63.1226 87.7424 62.6674 87.2871 62.1053 87.2871H47.0687C46.5074 87.2871 46.0513 87.7424 46.0513 88.3045Z"
              fill="url(#paint11_linear_496_56665)"
            />
            <path
              d="M38.0454 89.3218H42.4695C43.0308 89.3218 43.4868 88.8666 43.4868 88.3045C43.4868 87.7424 43.0316 87.2871 42.4695 87.2871H38.0454C37.4841 87.2871 37.0281 87.7424 37.0281 88.3045C37.0281 88.8666 37.4833 89.3218 38.0454 89.3218Z"
              fill="url(#paint12_linear_496_56665)"
            />
            <path
              d="M53.0828 81.0439H38.0462C37.4849 81.0439 37.0289 81.4992 37.0289 82.0613C37.0289 82.6234 37.4841 83.0787 38.0462 83.0787H53.0828C53.6441 83.0787 54.1002 82.6234 54.1002 82.0613C54.1002 81.4992 53.6449 81.0439 53.0828 81.0439Z"
              fill="url(#paint13_linear_496_56665)"
            />
            <path
              d="M62.1053 83.0777C62.6666 83.0777 63.1227 82.6224 63.1227 82.0603C63.1227 81.4982 62.6674 81.043 62.1053 81.043H57.6813C57.12 81.043 56.6639 81.4982 56.6639 82.0603C56.6639 82.6224 57.1192 83.0777 57.6813 83.0777H62.1053Z"
              fill="url(#paint14_linear_496_56665)"
            />
            <path
              d="M89.1779 11.3678C89.1779 10.75 88.6769 10.249 88.0591 10.249H76.0297C75.4118 10.249 74.9109 10.75 74.9109 11.3678V16.6296C74.9109 17.2474 75.4118 17.7483 76.0297 17.7483H88.0591C88.6769 17.7483 89.1779 17.2474 89.1779 16.6296V11.3678ZM86.9403 15.5108H77.1485V12.4866H86.9403V15.5108Z"
              fill="url(#paint15_linear_496_56665)"
            />
            <path
              d="M27.3277 89.0148C27.625 89.0148 27.9223 88.9018 28.1492 88.6749C28.6029 88.2212 28.6029 87.4857 28.1492 87.0328L26.3003 85.1839L28.1492 83.335C28.6029 82.8813 28.6029 82.1458 28.1492 81.6928C27.6955 81.2391 26.9607 81.2391 26.507 81.6928L24.6581 83.5417L22.8092 81.6928C22.3555 81.2391 21.62 81.2391 21.1671 81.6928C20.7134 82.1465 20.7134 82.8821 21.1671 83.335L23.016 85.1839L21.1671 87.0328C20.7134 87.4865 20.7134 88.222 21.1671 88.6749C21.3939 88.9018 21.6912 89.0148 21.9885 89.0148C22.2858 89.0148 22.5832 88.9018 22.81 88.6749L24.6589 86.8261L26.5078 88.6749C26.7346 88.9018 27.032 89.0148 27.3293 89.0148H27.3277Z"
              fill="url(#paint16_linear_496_56665)"
            />
            <path
              d="M41.4103 52.165C39.7193 52.165 38.167 53.0105 37.2565 54.4266C36.7292 55.2218 36.4497 56.1547 36.4497 57.1256C36.4497 57.3416 36.4621 57.5398 36.4884 57.7303C36.7153 59.6241 38.0338 61.2322 39.8517 61.8322C40.3519 62.001 40.8761 62.087 41.4103 62.087C43.9165 62.087 46.0302 60.2156 46.3321 57.7318C46.3585 57.5414 46.3709 57.3432 46.3709 57.1264C46.3709 54.391 44.1457 52.1658 41.4103 52.1658V52.165ZM44.0303 57.4198L44.0272 57.4446C43.8701 58.7662 42.7451 59.7635 41.4111 59.7635C41.1292 59.7635 40.8536 59.7186 40.5927 59.6303L40.5826 59.6272C39.614 59.309 38.9126 58.4534 38.7949 57.4469L38.7918 57.4206C38.7794 57.3347 38.774 57.2379 38.774 57.1264C38.774 56.6123 38.9203 56.1214 39.1967 55.7072L39.2083 55.6886C39.6891 54.9376 40.5129 54.4885 41.4118 54.4885C42.8666 54.4885 44.0497 55.6716 44.0497 57.1264C44.0497 57.2387 44.0435 57.3347 44.0319 57.4206L44.0303 57.4198Z"
              fill="url(#paint17_linear_496_56665)"
            />
            <path
              d="M44.6275 20.3525C43.3059 19.2082 41.9262 19.1927 41.409 19.2353C41.1326 19.2136 40.6115 19.2089 39.9844 19.3862C38.782 19.7176 37.1607 20.7001 36.2657 23.4952C35.3273 26.4249 35.3962 30.8102 36.4709 36.5303C37.1259 40.0036 38.1549 44.0095 39.5299 48.4374C39.7916 49.2805 40.5473 49.8465 41.4113 49.8465C42.2754 49.8465 43.0311 49.2805 43.2928 48.4382C43.4933 47.7924 43.6845 47.1591 43.8456 46.6226C45.9569 39.5181 47.0865 33.6888 47.2027 29.2958C47.3219 24.9445 46.4555 21.9351 44.6283 20.3525H44.6275ZM44.88 29.2323V29.2338C44.7692 33.4233 43.6721 39.0512 41.6196 45.9583C41.5546 46.1743 41.4849 46.4073 41.4113 46.6504C40.2306 42.7436 39.3379 39.1991 38.7534 36.1021C36.4392 23.7824 39.5834 21.908 40.6092 21.6246C40.8353 21.5611 41.0234 21.5487 41.1342 21.5487C41.1798 21.5487 41.2131 21.551 41.2294 21.5526L41.3881 21.5874L41.608 21.5495C41.615 21.5479 42.3652 21.4674 43.1069 22.1093C44.3465 23.1831 44.9767 25.7134 44.88 29.2331V29.2323Z"
              fill="url(#paint18_linear_496_56665)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_496_56665"
              x1="-18.5462"
              y1="-0.511577"
              x2="117.23"
              y2="30.289"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint1_linear_496_56665"
              x1="-38.1594"
              y1="-10.0437"
              x2="170.447"
              y2="37.2788"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint2_linear_496_56665"
              x1="-5.23144"
              y1="15.106"
              x2="6.21923"
              y2="28.8839"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint3_linear_496_56665"
              x1="15.0155"
              y1="-2.14675"
              x2="20.2901"
              y2="-1.92116"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint4_linear_496_56665"
              x1="-1.13888"
              y1="1.04012"
              x2="18.9777"
              y2="5.60352"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint5_linear_496_56665"
              x1="28.2033"
              y1="-9.95154"
              x2="132.334"
              y2="6.96286"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint6_linear_496_56665"
              x1="-27.0342"
              y1="61.9502"
              x2="107.423"
              y2="126.608"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint7_linear_496_56665"
              x1="75.3062"
              y1="26.1802"
              x2="86.9331"
              y2="37.1411"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint8_linear_496_56665"
              x1="75.3062"
              y1="37.0337"
              x2="86.9331"
              y2="47.9946"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint9_linear_496_56665"
              x1="75.3062"
              y1="47.8863"
              x2="86.9331"
              y2="58.8472"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint10_linear_496_56665"
              x1="4.54151"
              y1="71.3734"
              x2="50.096"
              y2="81.7073"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint11_linear_496_56665"
              x1="37.6556"
              y1="86.9191"
              x2="46.3775"
              y2="103.519"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint12_linear_496_56665"
              x1="33.8516"
              y1="86.9191"
              x2="43.8964"
              y2="94.1521"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint13_linear_496_56665"
              x1="28.6332"
              y1="80.6759"
              x2="37.355"
              y2="97.2759"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint14_linear_496_56665"
              x1="53.4875"
              y1="80.6749"
              x2="63.5323"
              y2="87.908"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint15_linear_496_56665"
              x1="67.8943"
              y1="8.89255"
              x2="96.2976"
              y2="21.1504"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint16_linear_496_56665"
              x1="17.0582"
              y1="79.9666"
              x2="34.2689"
              y2="83.871"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint17_linear_496_56665"
              x1="31.5705"
              y1="50.3704"
              x2="53.854"
              y2="55.4249"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <linearGradient
              id="paint18_linear_496_56665"
              x1="29.9032"
              y1="13.6846"
              x2="57.1056"
              y2="16.0228"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <clipPath id="clip0_496_56665">
              <rect width="99.7891" height="101" fill="white" transform="translate(0.605469)" />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case 'arrow-down':
      icon = (
        <svg {...svgProps} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.91 0.0900879L6.5 4.67009L11.09 0.0900879L12.5 1.50009L6.5 7.50009L0.5 1.50009L1.91 0.0900879Z"
            fill="#0C0C0C"
          />
        </svg>
      );
      break;

    case 'left-quotes':
      icon = (
        <svg {...svgProps} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 12L0 6L6 0L7.4 1.4L2.825 6L7.4 10.6L6 12ZM12.6 12L6.6 6L12.6 0L14 1.4L9.425 6L14 10.6L12.6 12Z"
            fill="white"
          />
        </svg>
      );
      break;
    case 'rounded-check':
      icon = (
        <svg {...svgProps} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_496_69258)">
            <path
              d="M4.46011 12.3795C5.12011 11.2195 5.89011 10.1195 6.74011 9.10949C7.00011 8.79949 6.96011 8.33949 6.65011 8.08949C6.34011 7.82949 5.88011 7.86949 5.63011 8.17949C4.72011 9.25949 3.91011 10.4295 3.20011 11.6595C0.160112 16.9295 -0.749888 23.1095 0.630112 29.0395C0.710112 29.3795 1.01011 29.5995 1.34011 29.5995C1.39011 29.5995 1.45011 29.5995 1.51011 29.5795C1.90011 29.4895 2.14011 29.0995 2.05011 28.7095C0.760112 23.1395 1.61011 17.3395 4.47011 12.3795H4.46011Z"
              fill="url(#paint0_linear_496_69258)"
            />
            <path
              d="M35.9701 2.81017C33.6001 1.45017 31.0601 0.510172 28.4001 0.0101723C28.0001 -0.0698277 27.6301 0.200172 27.5501 0.590172C27.4801 0.990172 27.7401 1.37017 28.1301 1.44017C30.6201 1.91017 33.0101 2.79017 35.2401 4.06017C46.0201 10.2602 49.7401 24.0602 43.5501 34.8402C38.9301 42.8802 30.0101 47.2302 20.8301 45.9302C20.4301 45.8602 20.0601 46.1502 20.0101 46.5502C19.9501 46.9502 20.2301 47.3202 20.6301 47.3702C21.7801 47.5302 22.9201 47.6102 24.0601 47.6102C32.5701 47.6102 40.4801 43.1102 44.8201 35.5602C51.4201 24.0902 47.4501 9.39017 35.9801 2.79017L35.9701 2.81017Z"
              fill="url(#paint1_linear_496_69258)"
            />
            <path
              d="M28.3999 42.7496C28.7899 42.6596 29.0399 42.2696 28.9399 41.8796C28.8499 41.4896 28.4599 41.2396 28.0699 41.3296C23.5899 42.3596 18.9299 41.6696 14.9399 39.3696C10.7299 36.9496 7.70989 33.0396 6.44989 28.3496C5.17989 23.6596 5.81989 18.7596 8.23989 14.5496C10.6599 10.3396 14.5799 7.31958 19.2599 6.05958C23.9499 4.79958 28.8499 5.42958 33.0599 7.84958C33.4099 8.04958 33.8499 7.92958 34.0499 7.57958C34.2499 7.22958 34.1299 6.78958 33.7799 6.58958C29.2299 3.96958 23.9399 3.28958 18.8799 4.65958C13.8199 6.02958 9.58989 9.27958 6.96989 13.8296C4.35989 18.3796 3.66989 23.6696 5.03989 28.7296C6.40989 33.7896 9.65989 38.0196 14.2099 40.6396C17.2199 42.3696 20.5799 43.2596 23.9799 43.2596C25.4499 43.2596 26.9299 43.0896 28.3899 42.7596L28.3999 42.7496Z"
              fill="url(#paint2_linear_496_69258)"
            />
            <path
              d="M40.0395 33.6702C40.1495 33.7402 40.2795 33.7702 40.3995 33.7702C40.6495 33.7702 40.8995 33.6402 41.0295 33.4002C43.7395 28.6802 44.3695 22.9902 42.7595 17.7902C42.6395 17.4102 42.2395 17.1902 41.8495 17.3102C41.4695 17.4302 41.2495 17.8402 41.3695 18.2202C42.8695 23.0402 42.2795 28.3102 39.7695 32.6802C39.5695 33.0302 39.6895 33.4702 40.0395 33.6702Z"
              fill="url(#paint3_linear_496_69258)"
            />
            <path
              d="M14.95 19.7408C14.74 19.5308 14.43 19.4708 14.16 19.5808C13.89 19.6908 13.71 19.9608 13.71 20.2508V27.5108C13.71 27.7008 13.79 27.8908 13.92 28.0208L21.46 35.5608C21.6 35.7008 21.78 35.7708 21.97 35.7708C22.16 35.7708 22.35 35.6908 22.48 35.5608L37.17 20.8708C37.31 20.7308 37.38 20.5508 37.38 20.3608V13.1108C37.38 12.8208 37.2 12.5508 36.93 12.4408C36.66 12.3308 36.35 12.3908 36.14 12.6008L21.96 26.7808L14.93 19.7508L14.95 19.7408ZM35.94 14.8608V20.0608L21.98 34.0208L15.17 27.2108V22.0108L21.47 28.3108C21.75 28.5908 22.21 28.5908 22.5 28.3108L35.95 14.8608H35.94Z"
              fill="url(#paint4_linear_496_69258)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_496_69258"
              x1="-3.39625"
              y1="3.99622"
              x2="12.837"
              y2="5.16979"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_496_69258"
              x1="6.22686"
              y1="-8.61633"
              x2="71.2172"
              y2="0.0562977"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_496_69258"
              x1="-10.3021"
              y1="-3.13817"
              x2="58.0408"
              y2="8.61849"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_496_69258"
              x1="37.7242"
              y1="14.2941"
              x2="47.0537"
              y2="14.8025"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_496_69258"
              x1="2.06897"
              y1="8.15701"
              x2="55.1689"
              y2="20.3499"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.194405" stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_496_69258">
              <rect width="48" height="47.62" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  secondaryColor: '#000',
};

export default Icon;
