import cn from 'classnames';
import React from 'react';
import { Autocomplete, TextField, styled } from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';

import { getGradientStartEndColors } from 'client/utils/calculation-type-helper';
import { CALCULATION_TYPES, CITIES_OPTIONS } from 'client/constants';
import { GENERAL_TEXTS as texts } from 'client/translations';
import { FormFieldTitle } from 'client/components/common';

import css from './CitySelect.module.scss';

const SearchIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7983 13.8057L8.07327 9.08066C7.69827 9.38066 7.26702 9.61816 6.77952 9.79316C6.29202 9.96816 5.77327 10.0557 5.22327 10.0557C3.86077 10.0557 2.70777 9.58391 1.76427 8.64041C0.820267 7.69641 0.348267 6.54316 0.348267 5.18066C0.348267 3.81816 0.820267 2.66491 1.76427 1.72091C2.70777 0.777414 3.86077 0.305664 5.22327 0.305664C6.58577 0.305664 7.73902 0.777414 8.68302 1.72091C9.62652 2.66491 10.0983 3.81816 10.0983 5.18066C10.0983 5.73066 10.0108 6.24941 9.83577 6.73691C9.66077 7.22441 9.42327 7.65566 9.12327 8.03066L13.8483 12.7557L12.7983 13.8057ZM5.22327 8.55566C6.16077 8.55566 6.95777 8.22766 7.61427 7.57166C8.27027 6.91516 8.59827 6.11816 8.59827 5.18066C8.59827 4.24316 8.27027 3.44616 7.61427 2.78966C6.95777 2.13366 6.16077 1.80566 5.22327 1.80566C4.28577 1.80566 3.48877 2.13366 2.83227 2.78966C2.17627 3.44616 1.84827 4.24316 1.84827 5.18066C1.84827 6.11816 2.17627 6.91516 2.83227 7.57166C3.48877 8.22766 4.28577 8.55566 5.22327 8.55566Z"
        fill="#212121"
      />
    </svg>
  );
};

const StyledAutocomplete = styled(Autocomplete)<{ calculation_type: CALCULATION_TYPES }>(({ calculation_type }) => {
  const { startColor } = getGradientStartEndColors(calculation_type);

  return {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: startColor,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${startColor} !important`,
    },
    '&:focus .MuiOutlinedInput-notchedOutline': {
      borderColor: `${startColor} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${startColor} !important`,
      borderWidth: '1px !important',
    },
    '& .MuiAutocomplete-popupIndicator': {
      padding: '5px 11px 0 0',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'initial',
    },
  };
});

interface Props {
  className?: string;
  name: string;
  calculationType: CALCULATION_TYPES;
}

export const CitySelect: React.FC<Props> = (props) => {
  const { className = '', name, calculationType } = props;
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: any, values: any, reason: any) => {
    helpers.setValue(values?.value ?? null);
  };

  return (
    <div className={cn(css.citySelect, className)}>
      <FormFieldTitle className={css.fieldTitle} title={texts.searchForm.citySelectTitle} />

      <StyledAutocomplete
        fullWidth
        calculation_type={calculationType}
        onChange={handleChange}
        disablePortal
        defaultValue={null}
        value={_.find(CITIES_OPTIONS, { value: field.value }) || null}
        disableClearable
        noOptionsText={texts.searchForm.noOptionsText}
        classes={{
          paper: css.paper,
          option: css.option,
          inputRoot: css.input,
          popper: css.popper,
          listbox: css.listbox,
          noOptions: css.noOptions,
        }}
        popupIcon={<SearchIcon />}
        options={CITIES_OPTIONS}
        renderInput={(params) => <TextField {...params} placeholder={texts.searchForm.citySelectPlaceholder} />}
      />
    </div>
  );
};
