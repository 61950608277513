import cn from 'classnames';
import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import css from './RabbiCredit.module.scss';

interface Props {
  className?: string;
}

export const RabbiCredit: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isInIframe, setIsInIframe] = React.useState(true);
  const location = useLocation();
  const locationParams = queryString.parse(location.search, { arrayFormat: 'comma' });

  React.useEffect(() => {
    if (!locationParams.iframe) {
      setIsInIframe(false);
    }
  }, []);

  if (isInIframe) {
    return null;
  }

  return (
    <div className={cn(css.rabbiCredit, className)}>
      Developed by{' '}
      <a href="https://www.rabbi.co.il" rel="noreferrer" title="רבי אינטראקטיב" target="_blank">
        Rabbi
      </a>
    </div>
  );
};
