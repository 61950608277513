export enum CLIENT_ROLE {
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export const ANONYMOUS_UID = 'anonymous';

export const ERROR = {
  REQUIRED: 'Required',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  TOO_WEAK: 'Too weak',
  INVALID_FORMAT: 'Invalid format',
  INVALID_VALUE: 'Invalid value',
};

export enum CALCULATION_TYPES {
  YOUNG = 'youngCouples',
  LUXURY = 'luxuryApartments',
  INVESTMENT = 'investment',
}

export enum CALCULATION_TYPES_HOST {
  YOUNG = 'young',
  LUXURY = 'luxury',
  INVESTMENT = 'investment',
}

export const ALL_COUNTRY_DOC_ID = 'ישראל';

export const CITIES_OPTIONS = [
  { label: 'כל הארץ', value: ALL_COUNTRY_DOC_ID },
  {
    label: 'אבו גוש',
    value: 'אבו-גוש-ישראל',
  },
  {
    label: 'אבו סנאן',
    value: 'אבו-סנאן-ישראל',
  },
  {
    label: 'אבטליון',
    value: 'אבטליון-ישראל',
  },
  {
    label: 'אביאל',
    value: 'אביאל-ישראל',
  },
  {
    label: 'אביבים',
    value: 'אביבים-ישראל',
  },
  {
    label: 'אביגדור',
    value: 'אביגדור-ישראל',
  },
  {
    label: 'אביחיל',
    value: 'אביחיל-ישראל',
  },
  {
    label: 'אביטל',
    value: 'אביטל-ישראל',
  },
  {
    label: 'אביעזר',
    value: 'אביעזר-ישראל',
  },
  {
    label: 'אבירים',
    value: 'אבירים-ישראל',
  },
  {
    label: 'אבן יהודה',
    value: 'אבן-יהודה-ישראל',
  },
  {
    label: 'אבן מנחם',
    value: 'אבן-מנחם-ישראל',
  },
  {
    label: 'אבן ספיר',
    value: 'אבן-ספיר-ישראל',
  },
  {
    label: 'אבן שמואל',
    value: 'אבן-שמואל-ישראל',
  },
  {
    label: 'אבני איתן',
    value: 'אבני-איתן-ישראל',
  },
  {
    label: 'אבני חפץ',
    value: 'אבני-חפץ-ישראל',
  },
  {
    label: 'אבנת',
    value: 'אבנת-ישראל',
  },
  {
    label: 'אבשלום',
    value: 'אבשלום-ישראל',
  },
  {
    label: 'אדורה',
    value: 'אדורה-ישראל',
  },
  {
    label: 'אדירים',
    value: 'אדירים-ישראל',
  },
  {
    label: 'אדמית',
    value: 'אדמית-ישראל',
  },
  {
    label: 'אדרת',
    value: 'אדרת-ישראל',
  },
  {
    label: 'אודים',
    value: 'אודים-ישראל',
  },
  {
    label: 'אודם',
    value: 'אודם-ישראל',
  },
  {
    label: 'אוהד',
    value: 'אוהד-ישראל',
  },
  {
    label: 'אום אל-פחם',
    value: 'אום-אל-פחם-ישראל',
  },
  {
    label: 'אום אל-קוטוף',
    value: 'אום-אל-קוטוף-ישראל',
  },
  {
    label: 'אום בטין',
    value: 'אום-בטין-ישראל',
  },
  {
    label: 'אומן',
    value: 'אומן-ישראל',
  },
  {
    label: 'אומץ',
    value: 'אומץ-ישראל',
  },
  {
    label: 'אופקים',
    value: 'אופקים-ישראל',
  },
  {
    label: 'אור הנר',
    value: 'אור-הנר-ישראל',
  },
  {
    label: 'אור יהודה',
    value: 'אור-יהודה-ישראל',
  },
  {
    label: 'אור עקיבא',
    value: 'אור-עקיבא-ישראל',
  },
  {
    label: 'אורה',
    value: 'אורה-ישראל',
  },
  {
    label: 'אורות',
    value: 'אורות-ישראל',
  },
  {
    label: 'אורים',
    value: 'אורים-ישראל',
  },
  {
    label: 'אורנים',
    value: 'אורנים-ישראל',
  },
  {
    label: 'אורנית',
    value: 'אורנית-ישראל',
  },
  {
    label: 'אושה',
    value: 'אושה-ישראל',
  },
  {
    label: 'אזור',
    value: 'אזור-ישראל',
  },
  {
    label: 'אחווה',
    value: 'אחווה-ישראל',
  },
  {
    label: 'אחוזם',
    value: 'אחוזם-ישראל',
  },
  {
    label: 'אחוזת ברק',
    value: 'אחוזת-ברק-ישראל',
  },
  {
    label: 'אחיהוד',
    value: 'אחיהוד-ישראל',
  },
  {
    label: 'אחיטוב',
    value: 'אחיטוב-ישראל',
  },
  {
    label: 'אחיסמך',
    value: 'אחיסמך-ישראל',
  },
  {
    label: 'אחיעזר',
    value: 'אחיעזר-ישראל',
  },
  {
    label: 'איבטין',
    value: 'איבטין-ישראל',
  },
  {
    label: 'אייל',
    value: 'אייל-ישראל',
  },
  {
    label: 'איילת השחר',
    value: 'איילת-השחר-ישראל',
  },
  {
    label: 'אילון',
    value: 'אילון-ישראל',
  },
  {
    label: 'אילות',
    value: 'אילות-ישראל',
  },
  {
    label: 'אילניה',
    value: 'אילניה-ישראל',
  },
  {
    label: 'אילת',
    value: 'אילת-ישראל',
  },
  {
    label: 'אירוס',
    value: 'אירוס-ישראל',
  },
  {
    label: 'איתמר',
    value: 'איתמר-ישראל',
  },
  {
    label: 'איתן',
    value: 'איתן-ישראל',
  },
  {
    label: 'אל סייד',
    value: 'אל-סייד-ישראל',
  },
  {
    label: 'אל-עריאן',
    value: 'אל-עריאן-ישראל',
  },
  {
    label: 'אל-רום',
    value: 'אל-רום-ישראל',
  },
  {
    label: 'אלומה',
    value: 'אלומה-ישראל',
  },
  {
    label: 'אלומות',
    value: 'אלומות-ישראל',
  },
  {
    label: 'אלון הגליל',
    value: 'אלון-הגליל-ישראל',
  },
  {
    label: 'אלון מורה',
    value: 'אלון-מורה-ישראל',
  },
  {
    label: 'אלון שבות',
    value: 'אלון-שבות-ישראל',
  },
  {
    label: 'אלוני אבא',
    value: 'אלוני-אבא-ישראל',
  },
  {
    label: 'אלוני הבשן',
    value: 'אלוני-הבשן-ישראל',
  },
  {
    label: 'אלונים',
    value: 'אלונים-ישראל',
  },
  {
    label: 'אלי עד',
    value: 'אלי-עד-ישראל',
  },
  {
    label: 'אליאב',
    value: 'אליאב-ישראל',
  },
  {
    label: 'אליכין',
    value: 'אליכין-ישראל',
  },
  {
    label: 'אליפלט',
    value: 'אליפלט-ישראל',
  },
  {
    label: 'אליקים',
    value: 'אליקים-ישראל',
  },
  {
    label: 'אלישיב',
    value: 'אלישיב-ישראל',
  },
  {
    label: 'אלישמע',
    value: 'אלישמע-ישראל',
  },
  {
    label: 'אלמגור',
    value: 'אלמגור-ישראל',
  },
  {
    label: 'אלמוג',
    value: 'אלמוג-ישראל',
  },
  {
    label: 'אלעד',
    value: 'אלעד-ישראל',
  },
  {
    label: 'אלעזר',
    value: 'אלעזר-ישראל',
  },
  {
    label: 'אלפי מנשה',
    value: 'אלפי-מנשה-ישראל',
  },
  {
    label: 'אלקוש',
    value: 'אלקוש-ישראל',
  },
  {
    label: 'אלקנה',
    value: 'אלקנה-ישראל',
  },
  {
    label: 'אמונים',
    value: 'אמונים-ישראל',
  },
  {
    label: 'אמירים',
    value: 'אמירים-ישראל',
  },
  {
    label: 'אמנון',
    value: 'אמנון-ישראל',
  },
  {
    label: 'אמציה',
    value: 'אמציה-ישראל',
  },
  {
    label: 'אניעם',
    value: 'אניעם-ישראל',
  },
  {
    label: 'אספר',
    value: 'אספר-ישראל',
  },
  {
    label: 'אעבלין',
    value: 'אעבלין-ישראל',
  },
  {
    label: 'אפיק',
    value: 'אפיק-ישראל',
  },
  {
    label: 'אפיקים',
    value: 'אפיקים-ישראל',
  },
  {
    label: 'אפק',
    value: 'אפק-ישראל',
  },
  {
    label: 'אפרת',
    value: 'אפרת-ישראל',
  },
  {
    label: 'ארבל',
    value: 'ארבל-ישראל',
  },
  {
    label: 'ארגמן',
    value: 'ארגמן-ישראל',
  },
  {
    label: 'ארז',
    value: 'ארז-ישראל',
  },
  {
    label: 'אריאל',
    value: 'אריאל-ישראל',
  },
  {
    label: 'ארסוף',
    value: 'ארסוף-ישראל',
  },
  {
    label: 'אשבול',
    value: 'אשבול-ישראל',
  },
  {
    label: 'אשדוד',
    value: 'אשדוד-ישראל',
  },
  {
    label: 'אשדות יעקב איחוד',
    value: 'אשדות-יעקב-איחוד-ישראל',
  },
  {
    label: 'אשדות יעקב מאוחד',
    value: 'אשדות-יעקב-מאוחד-ישראל',
  },
  {
    label: 'אשחר',
    value: 'אשחר-ישראל',
  },
  {
    label: 'אשכולות',
    value: 'אשכולות-ישראל',
  },
  {
    label: 'אשל הנשיא',
    value: 'אשל-הנשיא-ישראל',
  },
  {
    label: 'אשלים',
    value: 'אשלים-ישראל',
  },
  {
    label: 'אשקלון',
    value: 'אשקלון-ישראל',
  },
  {
    label: 'אשתאול',
    value: 'אשתאול-ישראל',
  },
  {
    label: 'באקה אל-גרביה',
    value: 'באקה-אל-גרביה-ישראל',
  },
  {
    label: 'באר אורה',
    value: 'באר-אורה-ישראל',
  },
  {
    label: 'באר גנים',
    value: 'באר-גנים-ישראל',
  },
  {
    label: 'באר טוביה',
    value: 'באר-טוביה-ישראל',
  },
  {
    label: 'באר יעקב',
    value: 'באר-יעקב-ישראל',
  },
  {
    label: 'באר שבע',
    value: 'באר-שבע-ישראל',
  },
  {
    label: 'בארות יצחק',
    value: 'בארות-יצחק-ישראל',
  },
  {
    label: 'בארותיים',
    value: 'בארותיים-ישראל',
  },
  {
    label: 'בארי',
    value: 'בארי-ישראל',
  },
  {
    label: 'בוסתן הגליל',
    value: 'בוסתן-הגליל-ישראל',
  },
  {
    label: "בועיינה-נוג'ידאת",
    value: 'בועיינה-נוגידאת-ישראל',
  },
  {
    label: 'בורגתה',
    value: 'בורגתה-ישראל',
  },
  {
    label: 'בחן',
    value: 'בחן-ישראל',
  },
  {
    label: 'ביצרון',
    value: 'ביצרון-ישראל',
  },
  {
    label: 'ביר אל-מכסור',
    value: 'ביר-אל-מכסור-ישראל',
  },
  {
    label: "ביר הדאג'",
    value: 'ביר-הדאג-ישראל',
  },
  {
    label: 'ביריה',
    value: 'ביריה-ישראל',
  },
  {
    label: 'בית אורן',
    value: 'בית-אורן-ישראל',
  },
  {
    label: 'בית אל',
    value: 'בית-אל-ישראל',
  },
  {
    label: 'בית אלעזרי',
    value: 'בית-אלעזרי-ישראל',
  },
  {
    label: 'בית אלפא',
    value: 'בית-אלפא-ישראל',
  },
  {
    label: 'בית אריה',
    value: 'בית-אריה-ישראל',
  },
  {
    label: 'בית ברל',
    value: 'בית-ברל-ישראל',
  },
  {
    label: "בית ג'ן",
    value: 'בית-גן-ישראל',
  },
  {
    label: 'בית גוברין',
    value: 'בית-גוברין-ישראל',
  },
  {
    label: 'בית גמליאל',
    value: 'בית-גמליאל-ישראל',
  },
  {
    label: 'בית דגן',
    value: 'בית-דגן-ישראל',
  },
  {
    label: 'בית הגדי',
    value: 'בית-הגדי-ישראל',
  },
  {
    label: 'בית הלוי',
    value: 'בית-הלוי-ישראל',
  },
  {
    label: 'בית הלל',
    value: 'בית-הלל-ישראל',
  },
  {
    label: 'בית העמק',
    value: 'בית-העמק-ישראל',
  },
  {
    label: 'בית הערבה',
    value: 'בית-הערבה-ישראל',
  },
  {
    label: 'בית השיטה',
    value: 'בית-השיטה-ישראל',
  },
  {
    label: 'בית זית',
    value: 'בית-זית-ישראל',
  },
  {
    label: 'בית זרע',
    value: 'בית-זרע-ישראל',
  },
  {
    label: 'בית חורון',
    value: 'בית-חורון-ישראל',
  },
  {
    label: 'בית חירות',
    value: 'בית-חירות-ישראל',
  },
  {
    label: 'בית חלקיה',
    value: 'בית-חלקיה-ישראל',
  },
  {
    label: 'בית חנן',
    value: 'בית-חנן-ישראל',
  },
  {
    label: 'בית חנניה',
    value: 'בית-חנניה-ישראל',
  },
  {
    label: 'בית חשמונאי',
    value: 'בית-חשמונאי-ישראל',
  },
  {
    label: 'בית יהושע',
    value: 'בית-יהושע-ישראל',
  },
  {
    label: 'בית יוסף',
    value: 'בית-יוסף-ישראל',
  },
  {
    label: 'בית ינאי',
    value: 'בית-ינאי-ישראל',
  },
  {
    label: 'בית יצחק שער חפר',
    value: 'בית-יצחק-שער-חפר-ישראל',
  },
  {
    label: 'בית לחם הגלילית',
    value: 'בית-לחם-הגלילית-ישראל',
  },
  {
    label: 'בית מאיר',
    value: 'בית-מאיר-ישראל',
  },
  {
    label: 'בית נחמיה',
    value: 'בית-נחמיה-ישראל',
  },
  {
    label: 'בית ניר',
    value: 'בית-ניר-ישראל',
  },
  {
    label: 'בית נקופה',
    value: 'בית-נקופה-ישראל',
  },
  {
    label: 'בית עובד',
    value: 'בית-עובד-ישראל',
  },
  {
    label: 'בית עוזיאל',
    value: 'בית-עוזיאל-ישראל',
  },
  {
    label: 'בית עזרא',
    value: 'בית-עזרא-ישראל',
  },
  {
    label: 'בית עריף',
    value: 'בית-עריף-ישראל',
  },
  {
    label: 'בית קמה',
    value: 'בית-קמה-ישראל',
  },
  {
    label: 'בית קשת',
    value: 'בית-קשת-ישראל',
  },
  {
    label: 'בית רימון',
    value: 'בית-רימון-ישראל',
  },
  {
    label: 'בית שאן',
    value: 'בית-שאן-ישראל',
  },
  {
    label: 'בית שמש',
    value: 'בית-שמש-ישראל',
  },
  {
    label: 'בית שערים',
    value: 'בית-שערים-ישראל',
  },
  {
    label: 'בית שקמה',
    value: 'בית-שקמה-ישראל',
  },
  {
    label: 'ביתר עילית',
    value: 'ביתר-עילית-ישראל',
  },
  {
    label: 'בלפוריה',
    value: 'בלפוריה-ישראל',
  },
  {
    label: 'בן זכאי',
    value: 'בן-זכאי-ישראל',
  },
  {
    label: 'בן עמי',
    value: 'בן-עמי-ישראל',
  },
  {
    label: 'בן שמן (מושב)',
    value: 'בן-שמן-מושב-ישראל',
  },
  {
    label: 'בני ברק',
    value: 'בני-ברק-ישראל',
  },
  {
    label: 'בני דרום',
    value: 'בני-דרום-ישראל',
  },
  {
    label: 'בני דרור',
    value: 'בני-דרור-ישראל',
  },
  {
    label: 'בני יהודה',
    value: 'בני-יהודה-ישראל',
  },
  {
    label: 'בני עטרות',
    value: 'בני-עטרות-ישראל',
  },
  {
    label: 'בני עי"ש',
    value: 'בני-עיש-ישראל',
  },
  {
    label: 'בני ציון',
    value: 'בני-ציון-ישראל',
  },
  {
    label: 'בניה',
    value: 'בניה-ישראל',
  },
  {
    label: 'בנימינה גבעת עדה',
    value: 'בנימינה-גבעת-עדה-ישראל',
  },
  {
    label: 'בסמ"ה',
    value: 'בסמה-ישראל',
  },
  {
    label: 'בסמת טבעון',
    value: 'בסמת-טבעון-ישראל',
  },
  {
    label: 'בצרה',
    value: 'בצרה-ישראל',
  },
  {
    label: 'בצת',
    value: 'בצת-ישראל',
  },
  {
    label: 'בקוע',
    value: 'בקוע-ישראל',
  },
  {
    label: 'בר גיורא',
    value: 'בר-גיורא-ישראל',
  },
  {
    label: 'בר יוחאי',
    value: 'בר-יוחאי-ישראל',
  },
  {
    label: 'ברור חיל',
    value: 'ברור-חיל-ישראל',
  },
  {
    label: 'ברוש',
    value: 'ברוש-ישראל',
  },
  {
    label: 'ברכה',
    value: 'ברכה-ישראל',
  },
  {
    label: 'ברכיה',
    value: 'ברכיה-ישראל',
  },
  {
    label: 'ברעם',
    value: 'ברעם-ישראל',
  },
  {
    label: 'ברק',
    value: 'ברק-ישראל',
  },
  {
    label: 'ברקאי',
    value: 'ברקאי-ישראל',
  },
  {
    label: 'ברקן',
    value: 'ברקן-ישראל',
  },
  {
    label: 'ברקת',
    value: 'ברקת-ישראל',
  },
  {
    label: 'בת הדר',
    value: 'בת-הדר-ישראל',
  },
  {
    label: 'בת חן',
    value: 'בת-חן-ישראל',
  },
  {
    label: 'בת חפר',
    value: 'בת-חפר-ישראל',
  },
  {
    label: 'בת ים',
    value: 'בת-ים-ישראל',
  },
  {
    label: 'בת עין',
    value: 'בת-עין-ישראל',
  },
  {
    label: 'בת שלמה',
    value: 'בת-שלמה-ישראל',
  },
  {
    label: "ג'ולס",
    value: 'גולס-ישראל',
  },
  {
    label: "ג'לג'וליה",
    value: 'גלגוליה-ישראל',
  },
  {
    label: "ג'ש (גוש חלב)",
    value: 'גש-גוש-חלב-ישראל',
  },
  {
    label: 'גאולי תימן',
    value: 'גאולי-תימן-ישראל',
  },
  {
    label: 'גאולים',
    value: 'גאולים-ישראל',
  },
  {
    label: 'גאליה',
    value: 'גאליה-ישראל',
  },
  {
    label: 'גבולות',
    value: 'גבולות-ישראל',
  },
  {
    label: 'גבים',
    value: 'גבים-ישראל',
  },
  {
    label: 'גבע',
    value: 'גבע-ישראל',
  },
  {
    label: 'גבע בנימין',
    value: 'גבע-בנימין-ישראל',
  },
  {
    label: 'גבע כרמל',
    value: 'גבע-כרמל-ישראל',
  },
  {
    label: 'גבעולים',
    value: 'גבעולים-ישראל',
  },
  {
    label: 'גבעון החדשה',
    value: 'גבעון-החדשה-ישראל',
  },
  {
    label: 'גבעות בר',
    value: 'גבעות-בר-ישראל',
  },
  {
    label: 'גבעת אבני',
    value: 'גבעת-אבני-ישראל',
  },
  {
    label: 'גבעת אלה',
    value: 'גבעת-אלה-ישראל',
  },
  {
    label: 'גבעת ברנר',
    value: 'גבעת-ברנר-ישראל',
  },
  {
    label: 'גבעת השלושה',
    value: 'גבעת-השלושה-ישראל',
  },
  {
    label: 'גבעת זאב',
    value: 'גבעת-זאב-ישראל',
  },
  {
    label: 'גבעת חיים איחוד',
    value: 'גבעת-חיים-איחוד-ישראל',
  },
  {
    label: 'גבעת חיים מאוחד',
    value: 'גבעת-חיים-מאוחד-ישראל',
  },
  {
    label: 'גבעת יואב',
    value: 'גבעת-יואב-ישראל',
  },
  {
    label: 'גבעת יערים',
    value: 'גבעת-יערים-ישראל',
  },
  {
    label: 'גבעת ישעיהו',
    value: 'גבעת-ישעיהו-ישראל',
  },
  {
    label: 'גבעת כ"ח',
    value: 'גבעת-כח-ישראל',
  },
  {
    label: 'גבעת ניל"י',
    value: 'גבעת-נילי-ישראל',
  },
  {
    label: 'גבעת עוז',
    value: 'גבעת-עוז-ישראל',
  },
  {
    label: 'גבעת שמואל',
    value: 'גבעת-שמואל-ישראל',
  },
  {
    label: 'גבעת שמש',
    value: 'גבעת-שמש-ישראל',
  },
  {
    label: 'גבעת שפירא',
    value: 'גבעת-שפירא-ישראל',
  },
  {
    label: 'גבעתי',
    value: 'גבעתי-ישראל',
  },
  {
    label: 'גבעתיים',
    value: 'גבעתיים-ישראל',
  },
  {
    label: 'גברעם',
    value: 'גברעם-ישראל',
  },
  {
    label: 'גבת',
    value: 'גבת-ישראל',
  },
  {
    label: 'גדות',
    value: 'גדות-ישראל',
  },
  {
    label: 'גדיש',
    value: 'גדיש-ישראל',
  },
  {
    label: 'גדעונה',
    value: 'גדעונה-ישראל',
  },
  {
    label: 'גדרה',
    value: 'גדרה-ישראל',
  },
  {
    label: 'גונן',
    value: 'גונן-ישראל',
  },
  {
    label: 'גורן',
    value: 'גורן-ישראל',
  },
  {
    label: 'גזית',
    value: 'גזית-ישראל',
  },
  {
    label: 'גזר',
    value: 'גזר-ישראל',
  },
  {
    label: 'גיאה',
    value: 'גיאה-ישראל',
  },
  {
    label: 'גיבתון',
    value: 'גיבתון-ישראל',
  },
  {
    label: 'גילון',
    value: 'גילון-ישראל',
  },
  {
    label: 'גילת',
    value: 'גילת-ישראל',
  },
  {
    label: 'גינוסר',
    value: 'גינוסר-ישראל',
  },
  {
    label: 'גינתון',
    value: 'גינתון-ישראל',
  },
  {
    label: 'גיתה',
    value: 'גיתה-ישראל',
  },
  {
    label: 'גיתית',
    value: 'גיתית-ישראל',
  },
  {
    label: 'גלאון',
    value: 'גלאון-ישראל',
  },
  {
    label: 'גלגל',
    value: 'גלגל-ישראל',
  },
  {
    label: 'גליל ים',
    value: 'גליל-ים-ישראל',
  },
  {
    label: 'גן הדרום',
    value: 'גן-הדרום-ישראל',
  },
  {
    label: 'גן השומרון',
    value: 'גן-השומרון-ישראל',
  },
  {
    label: 'גן חיים',
    value: 'גן-חיים-ישראל',
  },
  {
    label: 'גן יאשיה',
    value: 'גן-יאשיה-ישראל',
  },
  {
    label: 'גן יבנה',
    value: 'גן-יבנה-ישראל',
  },
  {
    label: 'גן נר',
    value: 'גן-נר-ישראל',
  },
  {
    label: 'גן שורק',
    value: 'גן-שורק-ישראל',
  },
  {
    label: 'גן שלמה',
    value: 'גן-שלמה-ישראל',
  },
  {
    label: 'גן שמואל',
    value: 'גן-שמואל-ישראל',
  },
  {
    label: 'גנות',
    value: 'גנות-ישראל',
  },
  {
    label: 'גני הדר',
    value: 'גני-הדר-ישראל',
  },
  {
    label: 'גני טל',
    value: 'גני-טל-ישראל',
  },
  {
    label: 'גני יוחנן',
    value: 'גני-יוחנן-ישראל',
  },
  {
    label: 'גני עם',
    value: 'גני-עם-ישראל',
  },
  {
    label: 'גני תקווה',
    value: 'גני-תקווה-ישראל',
  },
  {
    label: 'געש',
    value: 'געש-ישראל',
  },
  {
    label: 'געתון',
    value: 'געתון-ישראל',
  },
  {
    label: 'גפן',
    value: 'גפן-ישראל',
  },
  {
    label: 'גשור',
    value: 'גשור-ישראל',
  },
  {
    label: 'גשר',
    value: 'גשר-ישראל',
  },
  {
    label: 'גשר הזיו',
    value: 'גשר-הזיו-ישראל',
  },
  {
    label: 'גת (קיבוץ)',
    value: 'גת-קיבוץ-ישראל',
  },
  {
    label: 'גת רימון',
    value: 'גת-רימון-ישראל',
  },
  {
    label: 'דאלית אל-כרמל',
    value: 'דאלית-אל-כרמל-ישראל',
  },
  {
    label: 'דבורה',
    value: 'דבורה-ישראל',
  },
  {
    label: 'דבירה',
    value: 'דבירה-ישראל',
  },
  {
    label: 'דברת',
    value: 'דברת-ישראל',
  },
  {
    label: "דגניה א'",
    value: 'דגניה-א-ישראל',
  },
  {
    label: "דגניה ב'",
    value: 'דגניה-ב-ישראל',
  },
  {
    label: 'דוב"ב',
    value: 'דובב-ישראל',
  },
  {
    label: 'דולב',
    value: 'דולב-ישראל',
  },
  {
    label: 'דור',
    value: 'דור-ישראל',
  },
  {
    label: 'דורות',
    value: 'דורות-ישראל',
  },
  {
    label: 'דחי',
    value: 'דחי-ישראל',
  },
  {
    label: 'דימונה',
    value: 'דימונה-ישראל',
  },
  {
    label: 'דישון',
    value: 'דישון-ישראל',
  },
  {
    label: 'דלתון',
    value: 'דלתון-ישראל',
  },
  {
    label: 'דמיידה',
    value: 'דמיידה-ישראל',
  },
  {
    label: 'דן',
    value: 'דן-ישראל',
  },
  {
    label: 'דפנה',
    value: 'דפנה-ישראל',
  },
  {
    label: 'דקל',
    value: 'דקל-ישראל',
  },
  {
    label: "דריג'את",
    value: 'דריגאת-ישראל',
  },
  {
    label: 'האון',
    value: 'האון-ישראל',
  },
  {
    label: 'הבונים',
    value: 'הבונים-ישראל',
  },
  {
    label: 'הגושרים',
    value: 'הגושרים-ישראל',
  },
  {
    label: 'הדר עם',
    value: 'הדר-עם-ישראל',
  },
  {
    label: 'הוד השרון',
    value: 'הוד-השרון-ישראל',
  },
  {
    label: 'הושעיה',
    value: 'הושעיה-ישראל',
  },
  {
    label: 'הזורע',
    value: 'הזורע-ישראל',
  },
  {
    label: 'הזורעים',
    value: 'הזורעים-ישראל',
  },
  {
    label: 'החותרים',
    value: 'החותרים-ישראל',
  },
  {
    label: 'היוגב',
    value: 'היוגב-ישראל',
  },
  {
    label: 'הילה',
    value: 'הילה-ישראל',
  },
  {
    label: 'המעפיל',
    value: 'המעפיל-ישראל',
  },
  {
    label: 'הסוללים',
    value: 'הסוללים-ישראל',
  },
  {
    label: 'העוגן',
    value: 'העוגן-ישראל',
  },
  {
    label: 'הר אדר',
    value: 'הר-אדר-ישראל',
  },
  {
    label: 'הר גילה',
    value: 'הר-גילה-ישראל',
  },
  {
    label: 'הר עמשא',
    value: 'הר-עמשא-ישראל',
  },
  {
    label: 'הראל',
    value: 'הראל-ישראל',
  },
  {
    label: 'הרדוף',
    value: 'הרדוף-ישראל',
  },
  {
    label: 'הרצליה',
    value: 'הרצליה-ישראל',
  },
  {
    label: 'הררית',
    value: 'הררית-ישראל',
  },
  {
    label: 'ורד יריחו',
    value: 'ורד-יריחו-ישראל',
  },
  {
    label: 'ורדון',
    value: 'ורדון-ישראל',
  },
  {
    label: 'זבדיאל',
    value: 'זבדיאל-ישראל',
  },
  {
    label: 'זוהר',
    value: 'זוהר-ישראל',
  },
  {
    label: 'זיקים',
    value: 'זיקים-ישראל',
  },
  {
    label: 'זיתן',
    value: 'זיתן-ישראל',
  },
  {
    label: 'זכרון יעקב',
    value: 'זכרון-יעקב-ישראל',
  },
  {
    label: 'זכריה',
    value: 'זכריה-ישראל',
  },
  {
    label: 'זמר',
    value: 'זמר-ישראל',
  },
  {
    label: 'זנוח',
    value: 'זנוח-ישראל',
  },
  {
    label: 'זרועה',
    value: 'זרועה-ישראל',
  },
  {
    label: 'זרזיר',
    value: 'זרזיר-ישראל',
  },
  {
    label: 'זרחיה',
    value: 'זרחיה-ישראל',
  },
  {
    label: 'חבצלת השרון',
    value: 'חבצלת-השרון-ישראל',
  },
  {
    label: 'חבר',
    value: 'חבר-ישראל',
  },
  {
    label: 'חגור',
    value: 'חגור-ישראל',
  },
  {
    label: 'חגי',
    value: 'חגי-ישראל',
  },
  {
    label: 'חדיד',
    value: 'חדיד-ישראל',
  },
  {
    label: 'חדרה',
    value: 'חדרה-ישראל',
  },
  {
    label: 'חולדה',
    value: 'חולדה-ישראל',
  },
  {
    label: 'חולון',
    value: 'חולון-ישראל',
  },
  {
    label: 'חולתה',
    value: 'חולתה-ישראל',
  },
  {
    label: 'חוסן',
    value: 'חוסן-ישראל',
  },
  {
    label: 'חוסנייה',
    value: 'חוסנייה-ישראל',
  },
  {
    label: 'חופית',
    value: 'חופית-ישראל',
  },
  {
    label: 'חוקוק',
    value: 'חוקוק-ישראל',
  },
  {
    label: 'חורה',
    value: 'חורה-ישראל',
  },
  {
    label: 'חורפיש',
    value: 'חורפיש-ישראל',
  },
  {
    label: 'חורשים',
    value: 'חורשים-ישראל',
  },
  {
    label: 'חזון',
    value: 'חזון-ישראל',
  },
  {
    label: 'חיבת ציון',
    value: 'חיבת-ציון-ישראל',
  },
  {
    label: 'חיננית',
    value: 'חיננית-ישראל',
  },
  {
    label: 'חיפה',
    value: 'חיפה-ישראל',
  },
  {
    label: 'חירות',
    value: 'חירות-ישראל',
  },
  {
    label: 'חלוץ',
    value: 'חלוץ-ישראל',
  },
  {
    label: 'חלמיש',
    value: 'חלמיש-ישראל',
  },
  {
    label: 'חלץ',
    value: 'חלץ-ישראל',
  },
  {
    label: 'חמאם',
    value: 'חמאם-ישראל',
  },
  {
    label: 'חמד',
    value: 'חמד-ישראל',
  },
  {
    label: 'חמדיה',
    value: 'חמדיה-ישראל',
  },
  {
    label: 'חמדת',
    value: 'חמדת-ישראל',
  },
  {
    label: 'חמרה',
    value: 'חמרה-ישראל',
  },
  {
    label: 'חניאל',
    value: 'חניאל-ישראל',
  },
  {
    label: 'חניתה',
    value: 'חניתה-ישראל',
  },
  {
    label: 'חנתון',
    value: 'חנתון-ישראל',
  },
  {
    label: 'חספין',
    value: 'חספין-ישראל',
  },
  {
    label: 'חפץ חיים',
    value: 'חפץ-חיים-ישראל',
  },
  {
    label: 'חפצי בה',
    value: 'חפצי-בה-ישראל',
  },
  {
    label: 'חצב',
    value: 'חצב-ישראל',
  },
  {
    label: 'חצבה',
    value: 'חצבה-ישראל',
  },
  {
    label: 'חצור הגלילית',
    value: 'חצור-הגלילית-ישראל',
  },
  {
    label: 'חצרים',
    value: 'חצרים-ישראל',
  },
  {
    label: 'חרב לאת',
    value: 'חרב-לאת-ישראל',
  },
  {
    label: 'חרוצים',
    value: 'חרוצים-ישראל',
  },
  {
    label: 'חריש',
    value: 'חריש-ישראל',
  },
  {
    label: 'חרמש',
    value: 'חרמש-ישראל',
  },
  {
    label: 'חרשים',
    value: 'חרשים-ישראל',
  },
  {
    label: 'חשמונאים',
    value: 'חשמונאים-ישראל',
  },
  {
    label: 'טבריה',
    value: 'טבריה-ישראל',
  },
  {
    label: 'טורעאן',
    value: 'טורעאן-ישראל',
  },
  {
    label: 'טייבה',
    value: 'טייבה-ישראל',
  },
  {
    label: 'טייבה (בעמק)',
    value: 'טייבה-בעמק-ישראל',
  },
  {
    label: 'טירה',
    value: 'טירה-ישראל',
  },
  {
    label: 'טירת הכרמל',
    value: 'טירת-הכרמל-ישראל',
  },
  {
    label: 'טירת יהודה',
    value: 'טירת-יהודה-ישראל',
  },
  {
    label: 'טירת צבי',
    value: 'טירת-צבי-ישראל',
  },
  {
    label: 'טל אל',
    value: 'טל-אל-ישראל',
  },
  {
    label: 'טל שחר',
    value: 'טל-שחר-ישראל',
  },
  {
    label: 'טללים',
    value: 'טללים-ישראל',
  },
  {
    label: 'טלמון',
    value: 'טלמון-ישראל',
  },
  {
    label: 'טמרה',
    value: 'טמרה-ישראל',
  },
  {
    label: 'טמרה (יזרעאל)',
    value: 'טמרה-יזרעאל-ישראל',
  },
  {
    label: 'טנא',
    value: 'טנא-ישראל',
  },
  {
    label: 'טפחות',
    value: 'טפחות-ישראל',
  },
  {
    label: "יאנוח ג'ת",
    value: 'יאנוח-גת-ישראל',
  },
  {
    label: 'יבול',
    value: 'יבול-ישראל',
  },
  {
    label: 'יבנאל',
    value: 'יבנאל-ישראל',
  },
  {
    label: 'יבנה',
    value: 'יבנה-ישראל',
  },
  {
    label: 'יגור',
    value: 'יגור-ישראל',
  },
  {
    label: 'יגל',
    value: 'יגל-ישראל',
  },
  {
    label: 'יד בנימין',
    value: 'יד-בנימין-ישראל',
  },
  {
    label: 'יד השמונה',
    value: 'יד-השמונה-ישראל',
  },
  {
    label: 'יד חנה',
    value: 'יד-חנה-ישראל',
  },
  {
    label: 'יד מרדכי',
    value: 'יד-מרדכי-ישראל',
  },
  {
    label: 'יד נתן',
    value: 'יד-נתן-ישראל',
  },
  {
    label: 'יד רמב"ם',
    value: 'יד-רמבם-ישראל',
  },
  {
    label: 'ידידה',
    value: 'ידידה-ישראל',
  },
  {
    label: 'יהל',
    value: 'יהל-ישראל',
  },
  {
    label: 'יובל',
    value: 'יובל-ישראל',
  },
  {
    label: 'יובלים',
    value: 'יובלים-ישראל',
  },
  {
    label: 'יודפת',
    value: 'יודפת-ישראל',
  },
  {
    label: 'יונתן',
    value: 'יונתן-ישראל',
  },
  {
    label: 'יושיביה',
    value: 'יושיביה-ישראל',
  },
  {
    label: 'יזרעאל',
    value: 'יזרעאל-ישראל',
  },
  {
    label: 'יחיעם',
    value: 'יחיעם-ישראל',
  },
  {
    label: 'יכיני',
    value: 'יכיני-ישראל',
  },
  {
    label: 'ינוב',
    value: 'ינוב-ישראל',
  },
  {
    label: 'ינון',
    value: 'ינון-ישראל',
  },
  {
    label: 'יסוד המעלה',
    value: 'יסוד-המעלה-ישראל',
  },
  {
    label: 'יסודות',
    value: 'יסודות-ישראל',
  },
  {
    label: 'יסעור',
    value: 'יסעור-ישראל',
  },
  {
    label: 'יעד',
    value: 'יעד-ישראל',
  },
  {
    label: 'יעל',
    value: 'יעל-ישראל',
  },
  {
    label: 'יערה',
    value: 'יערה-ישראל',
  },
  {
    label: 'יפיע',
    value: 'יפיע-ישראל',
  },
  {
    label: 'יפית',
    value: 'יפית-ישראל',
  },
  {
    label: 'יפעת',
    value: 'יפעת-ישראל',
  },
  {
    label: 'יפתח',
    value: 'יפתח-ישראל',
  },
  {
    label: 'יצהר',
    value: 'יצהר-ישראל',
  },
  {
    label: 'יציץ',
    value: 'יציץ-ישראל',
  },
  {
    label: 'יקום',
    value: 'יקום-ישראל',
  },
  {
    label: 'יקיר',
    value: 'יקיר-ישראל',
  },
  {
    label: 'ירדנה',
    value: 'ירדנה-ישראל',
  },
  {
    label: 'ירוחם',
    value: 'ירוחם-ישראל',
  },
  {
    label: 'ירושלים',
    value: 'ירושלים-ישראל',
  },
  {
    label: 'ירחיב',
    value: 'ירחיב-ישראל',
  },
  {
    label: 'ירכא',
    value: 'ירכא-ישראל',
  },
  {
    label: 'ירקונה',
    value: 'ירקונה-ישראל',
  },
  {
    label: 'ישע',
    value: 'ישע-ישראל',
  },
  {
    label: 'ישעי',
    value: 'ישעי-ישראל',
  },
  {
    label: 'ישרש',
    value: 'ישרש-ישראל',
  },
  {
    label: 'יתד',
    value: 'יתד-ישראל',
  },
  {
    label: 'כאבול',
    value: 'כאבול-ישראל',
  },
  {
    label: 'כברי',
    value: 'כברי-ישראל',
  },
  {
    label: 'כדורי',
    value: 'כדורי-ישראל',
  },
  {
    label: 'כוכב השחר',
    value: 'כוכב-השחר-ישראל',
  },
  {
    label: 'כוכב יעקב',
    value: 'כוכב-יעקב-ישראל',
  },
  {
    label: 'כוכב מיכאל',
    value: 'כוכב-מיכאל-ישראל',
  },
  {
    label: 'כורזים',
    value: 'כורזים-ישראל',
  },
  {
    label: 'כחל',
    value: 'כחל-ישראל',
  },
  {
    label: 'כיסופים',
    value: 'כיסופים-ישראל',
  },
  {
    label: 'כישור',
    value: 'כישור-ישראל',
  },
  {
    label: 'כליל',
    value: 'כליל-ישראל',
  },
  {
    label: 'כלנית',
    value: 'כלנית-ישראל',
  },
  {
    label: 'כמאנה',
    value: 'כמאנה-ישראל',
  },
  {
    label: 'כמון',
    value: 'כמון-ישראל',
  },
  {
    label: 'כנות',
    value: 'כנות-ישראל',
  },
  {
    label: 'כנף',
    value: 'כנף-ישראל',
  },
  {
    label: 'כסיפה',
    value: 'כסיפה-ישראל',
  },
  {
    label: 'כסלון',
    value: 'כסלון-ישראל',
  },
  {
    label: "כעביה טבאש חג'אג'רה",
    value: 'כעביה-טבאש-חגאגרה-ישראל',
  },
  {
    label: 'כפר אביב',
    value: 'כפר-אביב-ישראל',
  },
  {
    label: 'כפר אדומים',
    value: 'כפר-אדומים-ישראל',
  },
  {
    label: 'כפר אוריה',
    value: 'כפר-אוריה-ישראל',
  },
  {
    label: 'כפר אחים',
    value: 'כפר-אחים-ישראל',
  },
  {
    label: 'כפר ביאליק',
    value: 'כפר-ביאליק-ישראל',
  },
  {
    label: 'כפר ביל"ו',
    value: 'כפר-בילו-ישראל',
  },
  {
    label: 'כפר בלום',
    value: 'כפר-בלום-ישראל',
  },
  {
    label: 'כפר ברא',
    value: 'כפר-ברא-ישראל',
  },
  {
    label: 'כפר ברוך',
    value: 'כפר-ברוך-ישראל',
  },
  {
    label: 'כפר גדעון',
    value: 'כפר-גדעון-ישראל',
  },
  {
    label: 'כפר גליקסון',
    value: 'כפר-גליקסון-ישראל',
  },
  {
    label: 'כפר גלעדי',
    value: 'כפר-גלעדי-ישראל',
  },
  {
    label: 'כפר דניאל',
    value: 'כפר-דניאל-ישראל',
  },
  {
    label: 'כפר האורנים',
    value: 'כפר-האורנים-ישראל',
  },
  {
    label: 'כפר החורש',
    value: 'כפר-החורש-ישראל',
  },
  {
    label: 'כפר המכבי',
    value: 'כפר-המכבי-ישראל',
  },
  {
    label: 'כפר הנגיד',
    value: 'כפר-הנגיד-ישראל',
  },
  {
    label: 'כפר הנוער הדתי',
    value: 'כפר-הנוער-הדתי-ישראל',
  },
  {
    label: 'כפר הנשיא',
    value: 'כפר-הנשיא-ישראל',
  },
  {
    label: 'כפר הס',
    value: 'כפר-הס-ישראל',
  },
  {
    label: 'כפר הרא"ה',
    value: 'כפר-הראה-ישראל',
  },
  {
    label: 'כפר הרי"ף',
    value: 'כפר-הריף-ישראל',
  },
  {
    label: 'כפר ויתקין',
    value: 'כפר-ויתקין-ישראל',
  },
  {
    label: 'כפר ורבורג',
    value: 'כפר-ורבורג-ישראל',
  },
  {
    label: 'כפר ורדים',
    value: 'כפר-ורדים-ישראל',
  },
  {
    label: 'כפר זוהרים',
    value: 'כפר-זוהרים-ישראל',
  },
  {
    label: 'כפר זיתים',
    value: 'כפר-זיתים-ישראל',
  },
  {
    label: 'כפר חב"ד',
    value: 'כפר-חבד-ישראל',
  },
  {
    label: 'כפר חושן',
    value: 'כפר-חושן-ישראל',
  },
  {
    label: 'כפר חיטים',
    value: 'כפר-חיטים-ישראל',
  },
  {
    label: 'כפר חיים',
    value: 'כפר-חיים-ישראל',
  },
  {
    label: 'כפר חנניה',
    value: 'כפר-חנניה-ישראל',
  },
  {
    label: "כפר חסידים א'",
    value: 'כפר-חסידים-א-ישראל',
  },
  {
    label: 'כפר חרוב',
    value: 'כפר-חרוב-ישראל',
  },
  {
    label: 'כפר טרומן',
    value: 'כפר-טרומן-ישראל',
  },
  {
    label: 'כפר יאסיף',
    value: 'כפר-יאסיף-ישראל',
  },
  {
    label: 'כפר ידידיה',
    value: 'כפר-ידידיה-ישראל',
  },
  {
    label: 'כפר יהושע',
    value: 'כפר-יהושע-ישראל',
  },
  {
    label: 'כפר יונה',
    value: 'כפר-יונה-ישראל',
  },
  {
    label: 'כפר יחזקאל',
    value: 'כפר-יחזקאל-ישראל',
  },
  {
    label: 'כפר יעבץ',
    value: 'כפר-יעבץ-ישראל',
  },
  {
    label: 'כפר כמא',
    value: 'כפר-כמא-ישראל',
  },
  {
    label: 'כפר כנא',
    value: 'כפר-כנא-ישראל',
  },
  {
    label: 'כפר מונש',
    value: 'כפר-מונש-ישראל',
  },
  {
    label: 'כפר מימון',
    value: 'כפר-מימון-ישראל',
  },
  {
    label: 'כפר מל"ל',
    value: 'כפר-מלל-ישראל',
  },
  {
    label: 'כפר מנדא',
    value: 'כפר-מנדא-ישראל',
  },
  {
    label: 'כפר מנחם',
    value: 'כפר-מנחם-ישראל',
  },
  {
    label: 'כפר מסריק',
    value: 'כפר-מסריק-ישראל',
  },
  {
    label: 'כפר מצר',
    value: 'כפר-מצר-ישראל',
  },
  {
    label: 'כפר מרדכי',
    value: 'כפר-מרדכי-ישראל',
  },
  {
    label: 'כפר נטר',
    value: 'כפר-נטר-ישראל',
  },
  {
    label: 'כפר סאלד',
    value: 'כפר-סאלד-ישראל',
  },
  {
    label: 'כפר סבא',
    value: 'כפר-סבא-ישראל',
  },
  {
    label: 'כפר סילבר',
    value: 'כפר-סילבר-ישראל',
  },
  {
    label: 'כפר סירקין',
    value: 'כפר-סירקין-ישראל',
  },
  {
    label: 'כפר עזה',
    value: 'כפר-עזה-ישראל',
  },
  {
    label: 'כפר עציון',
    value: 'כפר-עציון-ישראל',
  },
  {
    label: 'כפר פינס',
    value: 'כפר-פינס-ישראל',
  },
  {
    label: 'כפר קאסם',
    value: 'כפר-קאסם-ישראל',
  },
  {
    label: 'כפר קיש',
    value: 'כפר-קיש-ישראל',
  },
  {
    label: 'כפר קרע',
    value: 'כפר-קרע-ישראל',
  },
  {
    label: 'כפר רופין',
    value: 'כפר-רופין-ישראל',
  },
  {
    label: 'כפר רות',
    value: 'כפר-רות-ישראל',
  },
  {
    label: 'כפר שמאי',
    value: 'כפר-שמאי-ישראל',
  },
  {
    label: 'כפר שמואל',
    value: 'כפר-שמואל-ישראל',
  },
  {
    label: 'כפר שמריהו',
    value: 'כפר-שמריהו-ישראל',
  },
  {
    label: 'כפר תבור',
    value: 'כפר-תבור-ישראל',
  },
  {
    label: 'כפר תפוח',
    value: 'כפר-תפוח-ישראל',
  },
  {
    label: 'כרכום',
    value: 'כרכום-ישראל',
  },
  {
    label: 'כרם בן זמרה',
    value: 'כרם-בן-זמרה-ישראל',
  },
  {
    label: 'כרם בן שמן',
    value: 'כרם-בן-שמן-ישראל',
  },
  {
    label: 'כרם מהר"ל',
    value: 'כרם-מהרל-ישראל',
  },
  {
    label: 'כרם שלום',
    value: 'כרם-שלום-ישראל',
  },
  {
    label: 'כרמי יוסף',
    value: 'כרמי-יוסף-ישראל',
  },
  {
    label: 'כרמי צור',
    value: 'כרמי-צור-ישראל',
  },
  {
    label: 'כרמיאל',
    value: 'כרמיאל-ישראל',
  },
  {
    label: 'כרמים',
    value: 'כרמים-ישראל',
  },
  {
    label: 'כרמל',
    value: 'כרמל-ישראל',
  },
  {
    label: 'לבון',
    value: 'לבון-ישראל',
  },
  {
    label: 'לביא',
    value: 'לביא-ישראל',
  },
  {
    label: 'להב',
    value: 'להב-ישראל',
  },
  {
    label: 'להבות הבשן',
    value: 'להבות-הבשן-ישראל',
  },
  {
    label: 'להבות חביבה',
    value: 'להבות-חביבה-ישראל',
  },
  {
    label: 'להבים',
    value: 'להבים-ישראל',
  },
  {
    label: 'לוד',
    value: 'לוד-ישראל',
  },
  {
    label: 'לוזית',
    value: 'לוזית-ישראל',
  },
  {
    label: 'לוטם',
    value: 'לוטם-ישראל',
  },
  {
    label: 'לוטן',
    value: 'לוטן-ישראל',
  },
  {
    label: 'לימן',
    value: 'לימן-ישראל',
  },
  {
    label: 'לכיש',
    value: 'לכיש-ישראל',
  },
  {
    label: 'לפיד',
    value: 'לפיד-ישראל',
  },
  {
    label: 'לפידות',
    value: 'לפידות-ישראל',
  },
  {
    label: 'לקיה',
    value: 'לקיה-ישראל',
  },
  {
    label: 'מאור',
    value: 'מאור-ישראל',
  },
  {
    label: 'מאיר שפיה',
    value: 'מאיר-שפיה-ישראל',
  },
  {
    label: 'מבוא ביתר',
    value: 'מבוא-ביתר-ישראל',
  },
  {
    label: 'מבוא דותן',
    value: 'מבוא-דותן-ישראל',
  },
  {
    label: 'מבוא חורון',
    value: 'מבוא-חורון-ישראל',
  },
  {
    label: 'מבוא חמה',
    value: 'מבוא-חמה-ישראל',
  },
  {
    label: 'מבוא מודיעים',
    value: 'מבוא-מודיעים-ישראל',
  },
  {
    label: 'מבועים',
    value: 'מבועים-ישראל',
  },
  {
    label: 'מבטחים',
    value: 'מבטחים-ישראל',
  },
  {
    label: 'מבקיעים',
    value: 'מבקיעים-ישראל',
  },
  {
    label: 'מבשרת ציון',
    value: 'מבשרת-ציון-ישראל',
  },
  {
    label: "מג'ד אל-כרום",
    value: 'מגד-אל-כרום-ישראל',
  },
  {
    label: 'מגאר',
    value: 'מגאר-ישראל',
  },
  {
    label: 'מגדים',
    value: 'מגדים-ישראל',
  },
  {
    label: 'מגדל',
    value: 'מגדל-ישראל',
  },
  {
    label: 'מגדל העמק',
    value: 'מגדל-העמק-ישראל',
  },
  {
    label: 'מגדל תפן',
    value: 'מגדל-תפן-ישראל',
  },
  {
    label: 'מגדלים',
    value: 'מגדלים-ישראל',
  },
  {
    label: 'מגידו',
    value: 'מגידו-ישראל',
  },
  {
    label: 'מגל',
    value: 'מגל-ישראל',
  },
  {
    label: 'מגן',
    value: 'מגן-ישראל',
  },
  {
    label: 'מגן שאול',
    value: 'מגן-שאול-ישראל',
  },
  {
    label: 'מגשימים',
    value: 'מגשימים-ישראל',
  },
  {
    label: 'מדרך עוז',
    value: 'מדרך-עוז-ישראל',
  },
  {
    label: 'מודיעין מכבים רעות',
    value: 'מודיעין-מכבים-רעות-ישראל',
  },
  {
    label: 'מודיעין עילית',
    value: 'מודיעין-עילית-ישראל',
  },
  {
    label: 'מולדה',
    value: 'מולדה-ישראל',
  },
  {
    label: 'מולדת',
    value: 'מולדת-ישראל',
  },
  {
    label: 'מוצא עילית',
    value: 'מוצא-עילית-ישראל',
  },
  {
    label: 'מורן',
    value: 'מורן-ישראל',
  },
  {
    label: 'מורשת',
    value: 'מורשת-ישראל',
  },
  {
    label: 'מזור',
    value: 'מזור-ישראל',
  },
  {
    label: 'מזכרת בתיה',
    value: 'מזכרת-בתיה-ישראל',
  },
  {
    label: 'מזרע',
    value: 'מזרע-ישראל',
  },
  {
    label: 'מזרעה',
    value: 'מזרעה-ישראל',
  },
  {
    label: 'מחולה',
    value: 'מחולה-ישראל',
  },
  {
    label: 'מחניים',
    value: 'מחניים-ישראל',
  },
  {
    label: 'מחסיה',
    value: 'מחסיה-ישראל',
  },
  {
    label: 'מטולה',
    value: 'מטולה-ישראל',
  },
  {
    label: 'מטע',
    value: 'מטע-ישראל',
  },
  {
    label: 'מי עמי',
    value: 'מי-עמי-ישראל',
  },
  {
    label: 'מיטב',
    value: 'מיטב-ישראל',
  },
  {
    label: 'מייסר',
    value: 'מייסר-ישראל',
  },
  {
    label: 'מיצר',
    value: 'מיצר-ישראל',
  },
  {
    label: 'מירב',
    value: 'מירב-ישראל',
  },
  {
    label: 'מירון',
    value: 'מירון-ישראל',
  },
  {
    label: 'מישר',
    value: 'מישר-ישראל',
  },
  {
    label: 'מיתר',
    value: 'מיתר-ישראל',
  },
  {
    label: 'מכורה',
    value: 'מכורה-ישראל',
  },
  {
    label: 'מכחול',
    value: 'מכחול-ישראל',
  },
  {
    label: 'מכמורת',
    value: 'מכמורת-ישראל',
  },
  {
    label: 'מכמנים',
    value: 'מכמנים-ישראל',
  },
  {
    label: 'מלאה',
    value: 'מלאה-ישראל',
  },
  {
    label: 'מלילות',
    value: 'מלילות-ישראל',
  },
  {
    label: 'מנוחה',
    value: 'מנוחה-ישראל',
  },
  {
    label: 'מנוף',
    value: 'מנוף-ישראל',
  },
  {
    label: 'מנות',
    value: 'מנות-ישראל',
  },
  {
    label: 'מנחמיה',
    value: 'מנחמיה-ישראל',
  },
  {
    label: 'מנרה',
    value: 'מנרה-ישראל',
  },
  {
    label: 'מסד',
    value: 'מסד-ישראל',
  },
  {
    label: 'מסדה',
    value: 'מסדה-ישראל',
  },
  {
    label: 'מסילות',
    value: 'מסילות-ישראל',
  },
  {
    label: 'מסילת ציון',
    value: 'מסילת-ציון-ישראל',
  },
  {
    label: 'מסלול',
    value: 'מסלול-ישראל',
  },
  {
    label: 'מסעדה',
    value: 'מסעדה-ישראל',
  },
  {
    label: 'מעגלים',
    value: 'מעגלים-ישראל',
  },
  {
    label: 'מעגן',
    value: 'מעגן-ישראל',
  },
  {
    label: 'מעגן מיכאל',
    value: 'מעגן-מיכאל-ישראל',
  },
  {
    label: 'מעוז חיים',
    value: 'מעוז-חיים-ישראל',
  },
  {
    label: 'מעון',
    value: 'מעון-ישראל',
  },
  {
    label: 'מעונה',
    value: 'מעונה-ישראל',
  },
  {
    label: 'מעיין ברוך',
    value: 'מעיין-ברוך-ישראל',
  },
  {
    label: 'מעיין צבי',
    value: 'מעיין-צבי-ישראל',
  },
  {
    label: 'מעיליא',
    value: 'מעיליא-ישראל',
  },
  {
    label: 'מעלה אדומים',
    value: 'מעלה-אדומים-ישראל',
  },
  {
    label: 'מעלה אפרים',
    value: 'מעלה-אפרים-ישראל',
  },
  {
    label: 'מעלה גלבוע',
    value: 'מעלה-גלבוע-ישראל',
  },
  {
    label: 'מעלה גמלא',
    value: 'מעלה-גמלא-ישראל',
  },
  {
    label: 'מעלה החמישה',
    value: 'מעלה-החמישה-ישראל',
  },
  {
    label: 'מעלה לבונה',
    value: 'מעלה-לבונה-ישראל',
  },
  {
    label: 'מעלה מכמש',
    value: 'מעלה-מכמש-ישראל',
  },
  {
    label: 'מעלה עירון',
    value: 'מעלה-עירון-ישראל',
  },
  {
    label: 'מעלה עמוס',
    value: 'מעלה-עמוס-ישראל',
  },
  {
    label: 'מעלה שומרון',
    value: 'מעלה-שומרון-ישראל',
  },
  {
    label: 'מענית',
    value: 'מענית-ישראל',
  },
  {
    label: 'מפלסים',
    value: 'מפלסים-ישראל',
  },
  {
    label: 'מצדות יהודה',
    value: 'מצדות-יהודה-ישראל',
  },
  {
    label: 'מצובה',
    value: 'מצובה-ישראל',
  },
  {
    label: 'מצליח',
    value: 'מצליח-ישראל',
  },
  {
    label: 'מצפה',
    value: 'מצפה-ישראל',
  },
  {
    label: 'מצפה אבי"ב',
    value: 'מצפה-אביב-ישראל',
  },
  {
    label: 'מצפה אילן',
    value: 'מצפה-אילן-ישראל',
  },
  {
    label: 'מצפה יריחו',
    value: 'מצפה-יריחו-ישראל',
  },
  {
    label: 'מצפה נטופה',
    value: 'מצפה-נטופה-ישראל',
  },
  {
    label: 'מצפה רמון',
    value: 'מצפה-רמון-ישראל',
  },
  {
    label: 'מצפה שלם',
    value: 'מצפה-שלם-ישראל',
  },
  {
    label: 'מצר',
    value: 'מצר-ישראל',
  },
  {
    label: 'מרגליות',
    value: 'מרגליות-ישראל',
  },
  {
    label: 'מרום גולן',
    value: 'מרום-גולן-ישראל',
  },
  {
    label: 'מרחב עם',
    value: 'מרחב-עם-ישראל',
  },
  {
    label: 'מרחביה קיבוץ',
    value: 'מרחביה-קיבוץ-ישראל',
  },
  {
    label: 'מרחביה-מושב',
    value: 'מרחביה-מושב-ישראל',
  },
  {
    label: 'מרכז שפירא',
    value: 'מרכז-שפירא-ישראל',
  },
  {
    label: 'משאבי שדה',
    value: 'משאבי-שדה-ישראל',
  },
  {
    label: 'משגב דב',
    value: 'משגב-דב-ישראל',
  },
  {
    label: 'משגב עם',
    value: 'משגב-עם-ישראל',
  },
  {
    label: 'משהד',
    value: 'משהד-ישראל',
  },
  {
    label: 'משואה',
    value: 'משואה-ישראל',
  },
  {
    label: 'משואות יצחק',
    value: 'משואות-יצחק-ישראל',
  },
  {
    label: 'משכיות',
    value: 'משכיות-ישראל',
  },
  {
    label: 'משמר איילון',
    value: 'משמר-איילון-ישראל',
  },
  {
    label: 'משמר דוד',
    value: 'משמר-דוד-ישראל',
  },
  {
    label: 'משמר הירדן',
    value: 'משמר-הירדן-ישראל',
  },
  {
    label: 'משמר הנגב',
    value: 'משמר-הנגב-ישראל',
  },
  {
    label: 'משמר העמק',
    value: 'משמר-העמק-ישראל',
  },
  {
    label: 'משמר השבעה',
    value: 'משמר-השבעה-ישראל',
  },
  {
    label: 'משמר השרון',
    value: 'משמר-השרון-ישראל',
  },
  {
    label: 'משמרת',
    value: 'משמרת-ישראל',
  },
  {
    label: 'משען',
    value: 'משען-ישראל',
  },
  {
    label: 'מתן',
    value: 'מתן-ישראל',
  },
  {
    label: 'מתת',
    value: 'מתת-ישראל',
  },
  {
    label: 'מתתיהו',
    value: 'מתתיהו-ישראל',
  },
  {
    label: 'נאות גולן',
    value: 'נאות-גולן-ישראל',
  },
  {
    label: 'נאות הכיכר',
    value: 'נאות-הכיכר-ישראל',
  },
  {
    label: 'נאות מרדכי',
    value: 'נאות-מרדכי-ישראל',
  },
  {
    label: 'נאות סמדר',
    value: 'נאות-סמדר-ישראל',
  },
  {
    label: 'נאעורה',
    value: 'נאעורה-ישראל',
  },
  {
    label: 'נבטים',
    value: 'נבטים-ישראל',
  },
  {
    label: 'נגבה',
    value: 'נגבה-ישראל',
  },
  {
    label: 'נגוהות',
    value: 'נגוהות-ישראל',
  },
  {
    label: 'נהורה',
    value: 'נהורה-ישראל',
  },
  {
    label: 'נהלל',
    value: 'נהלל-ישראל',
  },
  {
    label: 'נהריה',
    value: 'נהריה-ישראל',
  },
  {
    label: 'נוב',
    value: 'נוב-ישראל',
  },
  {
    label: 'נוגה',
    value: 'נוגה-ישראל',
  },
  {
    label: 'נווה אבות',
    value: 'נווה-אבות-ישראל',
  },
  {
    label: 'נווה אור',
    value: 'נווה-אור-ישראל',
  },
  {
    label: 'נווה אטי"ב',
    value: 'נווה-אטיב-ישראל',
  },
  {
    label: 'נווה אילן',
    value: 'נווה-אילן-ישראל',
  },
  {
    label: 'נווה איתן',
    value: 'נווה-איתן-ישראל',
  },
  {
    label: 'נווה דניאל',
    value: 'נווה-דניאל-ישראל',
  },
  {
    label: 'נווה זוהר',
    value: 'נווה-זוהר-ישראל',
  },
  {
    label: 'נווה זיו',
    value: 'נווה-זיו-ישראל',
  },
  {
    label: 'נווה ים',
    value: 'נווה-ים-ישראל',
  },
  {
    label: 'נווה ימין',
    value: 'נווה-ימין-ישראל',
  },
  {
    label: 'נווה ירק',
    value: 'נווה-ירק-ישראל',
  },
  {
    label: 'נווה מבטח',
    value: 'נווה-מבטח-ישראל',
  },
  {
    label: 'נווה מיכאל',
    value: 'נווה-מיכאל-ישראל',
  },
  {
    label: 'נווה שלום',
    value: 'נווה-שלום-ישראל',
  },
  {
    label: 'נועם',
    value: 'נועם-ישראל',
  },
  {
    label: 'נוף איילון',
    value: 'נוף-איילון-ישראל',
  },
  {
    label: 'נוף הגליל',
    value: 'נוף-הגליל-ישראל',
  },
  {
    label: 'נופים',
    value: 'נופים-ישראל',
  },
  {
    label: 'נופית',
    value: 'נופית-ישראל',
  },
  {
    label: 'נופך',
    value: 'נופך-ישראל',
  },
  {
    label: 'נוקדים',
    value: 'נוקדים-ישראל',
  },
  {
    label: 'נורית',
    value: 'נורית-ישראל',
  },
  {
    label: 'נחושה',
    value: 'נחושה-ישראל',
  },
  {
    label: 'נחל עוז',
    value: 'נחל-עוז-ישראל',
  },
  {
    label: 'נחלה',
    value: 'נחלה-ישראל',
  },
  {
    label: 'נחליאל',
    value: 'נחליאל-ישראל',
  },
  {
    label: 'נחלים',
    value: 'נחלים-ישראל',
  },
  {
    label: 'נחם',
    value: 'נחם-ישראל',
  },
  {
    label: 'נחף',
    value: 'נחף-ישראל',
  },
  {
    label: 'נחשולים',
    value: 'נחשולים-ישראל',
  },
  {
    label: 'נחשון',
    value: 'נחשון-ישראל',
  },
  {
    label: 'נחשונים',
    value: 'נחשונים-ישראל',
  },
  {
    label: 'נטועה',
    value: 'נטועה-ישראל',
  },
  {
    label: 'נטור',
    value: 'נטור-ישראל',
  },
  {
    label: 'נטעים',
    value: 'נטעים-ישראל',
  },
  {
    label: 'נטף',
    value: 'נטף-ישראל',
  },
  {
    label: 'ניל"י',
    value: 'נילי-ישראל',
  },
  {
    label: 'ניצן',
    value: 'ניצן-ישראל',
  },
  {
    label: 'ניצנה (קהילת חינוך)',
    value: 'ניצנה-קהילת-חינוך-ישראל',
  },
  {
    label: 'ניצני עוז',
    value: 'ניצני-עוז-ישראל',
  },
  {
    label: 'ניצנים',
    value: 'ניצנים-ישראל',
  },
  {
    label: 'ניר אליהו',
    value: 'ניר-אליהו-ישראל',
  },
  {
    label: 'ניר בנים',
    value: 'ניר-בנים-ישראל',
  },
  {
    label: 'ניר גלים',
    value: 'ניר-גלים-ישראל',
  },
  {
    label: 'ניר דוד (תל עמל)',
    value: 'ניר-דוד-תל-עמל-ישראל',
  },
  {
    label: 'ניר ח"ן',
    value: 'ניר-חן-ישראל',
  },
  {
    label: 'ניר יפה',
    value: 'ניר-יפה-ישראל',
  },
  {
    label: 'ניר יצחק',
    value: 'ניר-יצחק-ישראל',
  },
  {
    label: 'ניר ישראל',
    value: 'ניר-ישראל-ישראל',
  },
  {
    label: 'ניר משה',
    value: 'ניר-משה-ישראל',
  },
  {
    label: 'ניר עם',
    value: 'ניר-עם-ישראל',
  },
  {
    label: 'ניר עציון',
    value: 'ניר-עציון-ישראל',
  },
  {
    label: 'ניר עקיבא',
    value: 'ניר-עקיבא-ישראל',
  },
  {
    label: 'ניר צבי',
    value: 'ניר-צבי-ישראל',
  },
  {
    label: 'נירים',
    value: 'נירים-ישראל',
  },
  {
    label: 'נירית',
    value: 'נירית-ישראל',
  },
  {
    label: 'נס הרים',
    value: 'נס-הרים-ישראל',
  },
  {
    label: 'נס עמים',
    value: 'נס-עמים-ישראל',
  },
  {
    label: 'נס ציונה',
    value: 'נס-ציונה-ישראל',
  },
  {
    label: 'נעורים',
    value: 'נעורים-ישראל',
  },
  {
    label: 'נעלה',
    value: 'נעלה-ישראל',
  },
  {
    label: 'נעמ"ה',
    value: 'נעמה-ישראל',
  },
  {
    label: 'נען',
    value: 'נען-ישראל',
  },
  {
    label: 'נצר חזני',
    value: 'נצר-חזני-ישראל',
  },
  {
    label: 'נצר סרני',
    value: 'נצר-סרני-ישראל',
  },
  {
    label: 'נצרת',
    value: 'נצרת-ישראל',
  },
  {
    label: 'נשר',
    value: 'נשר-ישראל',
  },
  {
    label: 'נתיב הגדוד',
    value: 'נתיב-הגדוד-ישראל',
  },
  {
    label: 'נתיב הל"ה',
    value: 'נתיב-הלה-ישראל',
  },
  {
    label: 'נתיב העשרה',
    value: 'נתיב-העשרה-ישראל',
  },
  {
    label: 'נתיב השיירה',
    value: 'נתיב-השיירה-ישראל',
  },
  {
    label: 'נתיבות',
    value: 'נתיבות-ישראל',
  },
  {
    label: 'נתניה',
    value: 'נתניה-ישראל',
  },
  {
    label: "סאג'ור",
    value: 'סאגור-ישראל',
  },
  {
    label: 'סאסא',
    value: 'סאסא-ישראל',
  },
  {
    label: 'סביון',
    value: 'סביון-ישראל',
  },
  {
    label: 'סגולה',
    value: 'סגולה-ישראל',
  },
  {
    label: 'סולם',
    value: 'סולם-ישראל',
  },
  {
    label: 'סוסיה',
    value: 'סוסיה-ישראל',
  },
  {
    label: 'סופה',
    value: 'סופה-ישראל',
  },
  {
    label: "סח'נין",
    value: 'סחנין-ישראל',
  },
  {
    label: 'סלמה',
    value: 'סלמה-ישראל',
  },
  {
    label: 'סלעית',
    value: 'סלעית-ישראל',
  },
  {
    label: 'סמר',
    value: 'סמר-ישראל',
  },
  {
    label: 'סעד',
    value: 'סעד-ישראל',
  },
  {
    label: 'סער',
    value: 'סער-ישראל',
  },
  {
    label: 'ספיר',
    value: 'ספיר-ישראל',
  },
  {
    label: 'עבדון',
    value: 'עבדון-ישראל',
  },
  {
    label: 'עברון',
    value: 'עברון-ישראל',
  },
  {
    label: 'עגור',
    value: 'עגור-ישראל',
  },
  {
    label: 'עדי',
    value: 'עדי-ישראל',
  },
  {
    label: 'עדנים',
    value: 'עדנים-ישראל',
  },
  {
    label: 'עוזה',
    value: 'עוזה-ישראל',
  },
  {
    label: 'עוזייר',
    value: 'עוזייר-ישראל',
  },
  {
    label: 'עולש',
    value: 'עולש-ישראל',
  },
  {
    label: 'עומר',
    value: 'עומר-ישראל',
  },
  {
    label: 'עופר',
    value: 'עופר-ישראל',
  },
  {
    label: 'עוצם',
    value: 'עוצם-ישראל',
  },
  {
    label: 'עזר',
    value: 'עזר-ישראל',
  },
  {
    label: 'עזריאל',
    value: 'עזריאל-ישראל',
  },
  {
    label: 'עזריה',
    value: 'עזריה-ישראל',
  },
  {
    label: 'עזריקם',
    value: 'עזריקם-ישראל',
  },
  {
    label: 'עטרת',
    value: 'עטרת-ישראל',
  },
  {
    label: 'עידן',
    value: 'עידן-ישראל',
  },
  {
    label: 'עיילבון',
    value: 'עיילבון-ישראל',
  },
  {
    label: 'עיינות',
    value: 'עיינות-ישראל',
  },
  {
    label: 'עילוט',
    value: 'עילוט-ישראל',
  },
  {
    label: 'עין איילה',
    value: 'עין-איילה-ישראל',
  },
  {
    label: 'עין אל-אסד',
    value: 'עין-אל-אסד-ישראל',
  },
  {
    label: 'עין גב',
    value: 'עין-גב-ישראל',
  },
  {
    label: 'עין גדי',
    value: 'עין-גדי-ישראל',
  },
  {
    label: 'עין דור',
    value: 'עין-דור-ישראל',
  },
  {
    label: 'עין הבשור',
    value: 'עין-הבשור-ישראל',
  },
  {
    label: 'עין הוד',
    value: 'עין-הוד-ישראל',
  },
  {
    label: 'עין החורש',
    value: 'עין-החורש-ישראל',
  },
  {
    label: 'עין המפרץ',
    value: 'עין-המפרץ-ישראל',
  },
  {
    label: 'עין הנצי"ב',
    value: 'עין-הנציב-ישראל',
  },
  {
    label: 'עין העמק',
    value: 'עין-העמק-ישראל',
  },
  {
    label: 'עין השופט',
    value: 'עין-השופט-ישראל',
  },
  {
    label: 'עין השלושה',
    value: 'עין-השלושה-ישראל',
  },
  {
    label: 'עין ורד',
    value: 'עין-ורד-ישראל',
  },
  {
    label: 'עין זיוון',
    value: 'עין-זיוון-ישראל',
  },
  {
    label: 'עין חוד',
    value: 'עין-חוד-ישראל',
  },
  {
    label: 'עין יהב',
    value: 'עין-יהב-ישראל',
  },
  {
    label: 'עין יעקב',
    value: 'עין-יעקב-ישראל',
  },
  {
    label: 'עין כרמל',
    value: 'עין-כרמל-ישראל',
  },
  {
    label: 'עין מאהל',
    value: 'עין-מאהל-ישראל',
  },
  {
    label: 'עין נקובא',
    value: 'עין-נקובא-ישראל',
  },
  {
    label: 'עין עירון',
    value: 'עין-עירון-ישראל',
  },
  {
    label: 'עין צורים',
    value: 'עין-צורים-ישראל',
  },
  {
    label: 'עין ראפה',
    value: 'עין-ראפה-ישראל',
  },
  {
    label: 'עין שמר',
    value: 'עין-שמר-ישראל',
  },
  {
    label: 'עין שריד',
    value: 'עין-שריד-ישראל',
  },
  {
    label: 'עין תמר',
    value: 'עין-תמר-ישראל',
  },
  {
    label: 'עינת',
    value: 'עינת-ישראל',
  },
  {
    label: 'עכו',
    value: 'עכו-ישראל',
  },
  {
    label: 'עלומים',
    value: 'עלומים-ישראל',
  },
  {
    label: 'עלי',
    value: 'עלי-ישראל',
  },
  {
    label: 'עלי זהב',
    value: 'עלי-זהב-ישראל',
  },
  {
    label: 'עלמה',
    value: 'עלמה-ישראל',
  },
  {
    label: 'עלמון',
    value: 'עלמון-ישראל',
  },
  {
    label: 'עמוקה',
    value: 'עמוקה-ישראל',
  },
  {
    label: 'עמינדב',
    value: 'עמינדב-ישראל',
  },
  {
    label: 'עמיעד',
    value: 'עמיעד-ישראל',
  },
  {
    label: 'עמיעוז',
    value: 'עמיעוז-ישראל',
  },
  {
    label: 'עמיקם',
    value: 'עמיקם-ישראל',
  },
  {
    label: 'עמיר',
    value: 'עמיר-ישראל',
  },
  {
    label: 'עמנואל',
    value: 'עמנואל-ישראל',
  },
  {
    label: 'עמקה',
    value: 'עמקה-ישראל',
  },
  {
    label: 'עפולה',
    value: 'עפולה-ישראל',
  },
  {
    label: 'עפרה',
    value: 'עפרה-ישראל',
  },
  {
    label: 'עץ אפרים',
    value: 'עץ-אפרים-ישראל',
  },
  {
    label: 'עצמון שגב',
    value: 'עצמון-שגב-ישראל',
  },
  {
    label: 'עראבה',
    value: 'עראבה-ישראל',
  },
  {
    label: 'עראמשה',
    value: 'עראמשה-ישראל',
  },
  {
    label: 'ערב אל נעים',
    value: 'ערב-אל-נעים-ישראל',
  },
  {
    label: 'ערד',
    value: 'ערד-ישראל',
  },
  {
    label: 'ערוגות',
    value: 'ערוגות-ישראל',
  },
  {
    label: 'ערערה',
    value: 'ערערה-ישראל',
  },
  {
    label: 'עשרת',
    value: 'עשרת-ישראל',
  },
  {
    label: 'עתלית',
    value: 'עתלית-ישראל',
  },
  {
    label: 'פארן',
    value: 'פארן-ישראל',
  },
  {
    label: 'פדואל',
    value: 'פדואל-ישראל',
  },
  {
    label: 'פדויים',
    value: 'פדויים-ישראל',
  },
  {
    label: 'פדיה',
    value: 'פדיה-ישראל',
  },
  {
    label: 'פוריידיס',
    value: 'פוריידיס-ישראל',
  },
  {
    label: 'פורת',
    value: 'פורת-ישראל',
  },
  {
    label: 'פטיש',
    value: 'פטיש-ישראל',
  },
  {
    label: 'פלך',
    value: 'פלך-ישראל',
  },
  {
    label: 'פלמחים',
    value: 'פלמחים-ישראל',
  },
  {
    label: 'פני חבר',
    value: 'פני-חבר-ישראל',
  },
  {
    label: 'פסגות',
    value: 'פסגות-ישראל',
  },
  {
    label: 'פסוטה',
    value: 'פסוטה-ישראל',
  },
  {
    label: 'פעמי תש"ז',
    value: 'פעמי-תשז-ישראל',
  },
  {
    label: 'פצאל',
    value: 'פצאל-ישראל',
  },
  {
    label: 'פקיעין (בוקייעה)',
    value: 'פקיעין-בוקייעה-ישראל',
  },
  {
    label: 'פרדסיה',
    value: 'פרדסיה-ישראל',
  },
  {
    label: 'פרוד',
    value: 'פרוד-ישראל',
  },
  {
    label: 'פרזון',
    value: 'פרזון-ישראל',
  },
  {
    label: 'פרי גן',
    value: 'פרי-גן-ישראל',
  },
  {
    label: 'פתח תקווה',
    value: 'פתח-תקווה-ישראל',
  },
  {
    label: 'פתחיה',
    value: 'פתחיה-ישראל',
  },
  {
    label: 'צאלים',
    value: 'צאלים-ישראל',
  },
  {
    label: 'צביה',
    value: 'צביה-ישראל',
  },
  {
    label: 'צבעון',
    value: 'צבעון-ישראל',
  },
  {
    label: 'צובה',
    value: 'צובה-ישראל',
  },
  {
    label: 'צוחר',
    value: 'צוחר-ישראל',
  },
  {
    label: 'צופים',
    value: 'צופים-ישראל',
  },
  {
    label: 'צופית',
    value: 'צופית-ישראל',
  },
  {
    label: 'צופר',
    value: 'צופר-ישראל',
  },
  {
    label: 'צוקי ים',
    value: 'צוקי-ים-ישראל',
  },
  {
    label: 'צוקים',
    value: 'צוקים-ישראל',
  },
  {
    label: 'צור הדסה',
    value: 'צור-הדסה-ישראל',
  },
  {
    label: 'צור יצחק',
    value: 'צור-יצחק-ישראל',
  },
  {
    label: 'צור משה',
    value: 'צור-משה-ישראל',
  },
  {
    label: 'צור נתן',
    value: 'צור-נתן-ישראל',
  },
  {
    label: 'צוריאל',
    value: 'צוריאל-ישראל',
  },
  {
    label: 'צורית',
    value: 'צורית-ישראל',
  },
  {
    label: 'ציפורי',
    value: 'ציפורי-ישראל',
  },
  {
    label: 'צלפון',
    value: 'צלפון-ישראל',
  },
  {
    label: 'צנדלה',
    value: 'צנדלה-ישראל',
  },
  {
    label: 'צפרירים',
    value: 'צפרירים-ישראל',
  },
  {
    label: 'צפת',
    value: 'צפת-ישראל',
  },
  {
    label: 'צרופה',
    value: 'צרופה-ישראל',
  },
  {
    label: 'צרעה',
    value: 'צרעה-ישראל',
  },
  {
    label: 'קבוצת יבנה',
    value: 'קבוצת-יבנה-ישראל',
  },
  {
    label: 'קדומים',
    value: 'קדומים-ישראל',
  },
  {
    label: 'קדמת צבי',
    value: 'קדמת-צבי-ישראל',
  },
  {
    label: 'קדרון',
    value: 'קדרון-ישראל',
  },
  {
    label: 'קדרים',
    value: 'קדרים-ישראל',
  },
  {
    label: 'קוממיות',
    value: 'קוממיות-ישראל',
  },
  {
    label: 'קורנית',
    value: 'קורנית-ישראל',
  },
  {
    label: 'קטורה',
    value: 'קטורה-ישראל',
  },
  {
    label: 'קיסריה',
    value: 'קיסריה-ישראל',
  },
  {
    label: 'קלחים',
    value: 'קלחים-ישראל',
  },
  {
    label: 'קליה',
    value: 'קליה-ישראל',
  },
  {
    label: 'קלנסווה',
    value: 'קלנסווה-ישראל',
  },
  {
    label: 'קלע',
    value: 'קלע-ישראל',
  },
  {
    label: 'קציר',
    value: 'קציר-ישראל',
  },
  {
    label: 'קצר א-סר',
    value: 'קצר-א-סר-ישראל',
  },
  {
    label: 'קצרין',
    value: 'קצרין-ישראל',
  },
  {
    label: 'קרית אונו',
    value: 'קרית-אונו-ישראל',
  },
  {
    label: 'קרית ארבע',
    value: 'קרית-ארבע-ישראל',
  },
  {
    label: 'קרית אתא',
    value: 'קרית-אתא-ישראל',
  },
  {
    label: 'קרית ביאליק',
    value: 'קרית-ביאליק-ישראל',
  },
  {
    label: 'קרית גת',
    value: 'קרית-גת-ישראל',
  },
  {
    label: 'קרית טבעון',
    value: 'קרית-טבעון-ישראל',
  },
  {
    label: 'קרית ים',
    value: 'קרית-ים-ישראל',
  },
  {
    label: 'קרית יערים',
    value: 'קרית-יערים-ישראל',
  },
  {
    label: 'קרית מוצקין',
    value: 'קרית-מוצקין-ישראל',
  },
  {
    label: 'קרית מלאכי',
    value: 'קרית-מלאכי-ישראל',
  },
  {
    label: 'קרית נטפים',
    value: 'קרית-נטפים-ישראל',
  },
  {
    label: 'קרית ענבים',
    value: 'קרית-ענבים-ישראל',
  },
  {
    label: 'קרית עקרון',
    value: 'קרית-עקרון-ישראל',
  },
  {
    label: 'קרית שמונה',
    value: 'קרית-שמונה-ישראל',
  },
  {
    label: 'קרני שומרון',
    value: 'קרני-שומרון-ישראל',
  },
  {
    label: 'קשת',
    value: 'קשת-ישראל',
  },
  {
    label: 'ראמה',
    value: 'ראמה-ישראל',
  },
  {
    label: 'ראס אל-עין',
    value: 'ראס-אל-עין-ישראל',
  },
  {
    label: 'ראס עלי',
    value: 'ראס-עלי-ישראל',
  },
  {
    label: 'ראש העין',
    value: 'ראש-העין-ישראל',
  },
  {
    label: 'ראש פינה',
    value: 'ראש-פינה-ישראל',
  },
  {
    label: 'ראש צורים',
    value: 'ראש-צורים-ישראל',
  },
  {
    label: 'ראשון לציון',
    value: 'ראשון-לציון-ישראל',
  },
  {
    label: 'רבבה',
    value: 'רבבה-ישראל',
  },
  {
    label: 'רבדים',
    value: 'רבדים-ישראל',
  },
  {
    label: 'רביבים',
    value: 'רביבים-ישראל',
  },
  {
    label: 'רגבה',
    value: 'רגבה-ישראל',
  },
  {
    label: 'רגבים',
    value: 'רגבים-ישראל',
  },
  {
    label: 'רהט',
    value: 'רהט-ישראל',
  },
  {
    label: 'רווחה',
    value: 'רווחה-ישראל',
  },
  {
    label: 'רוויה',
    value: 'רוויה-ישראל',
  },
  {
    label: 'רוחמה',
    value: 'רוחמה-ישראל',
  },
  {
    label: 'רומאנה',
    value: 'רומאנה-ישראל',
  },
  {
    label: 'רועי',
    value: 'רועי-ישראל',
  },
  {
    label: 'רחוב',
    value: 'רחוב-ישראל',
  },
  {
    label: 'רחובות',
    value: 'רחובות-ישראל',
  },
  {
    label: 'ריחן',
    value: 'ריחן-ישראל',
  },
  {
    label: 'ריינה',
    value: 'ריינה-ישראל',
  },
  {
    label: 'רימונים',
    value: 'רימונים-ישראל',
  },
  {
    label: 'רינתיה',
    value: 'רינתיה-ישראל',
  },
  {
    label: 'רכסים',
    value: 'רכסים-ישראל',
  },
  {
    label: 'רמות',
    value: 'רמות-ישראל',
  },
  {
    label: 'רמות השבים',
    value: 'רמות-השבים-ישראל',
  },
  {
    label: 'רמות מאיר',
    value: 'רמות-מאיר-ישראל',
  },
  {
    label: 'רמות מנשה',
    value: 'רמות-מנשה-ישראל',
  },
  {
    label: 'רמות נפתלי',
    value: 'רמות-נפתלי-ישראל',
  },
  {
    label: 'רמלה',
    value: 'רמלה-ישראל',
  },
  {
    label: 'רמת גן',
    value: 'רמת-גן-ישראל',
  },
  {
    label: 'רמת דוד',
    value: 'רמת-דוד-ישראל',
  },
  {
    label: 'רמת הכובש',
    value: 'רמת-הכובש-ישראל',
  },
  {
    label: 'רמת השופט',
    value: 'רמת-השופט-ישראל',
  },
  {
    label: 'רמת השרון',
    value: 'רמת-השרון-ישראל',
  },
  {
    label: 'רמת יוחנן',
    value: 'רמת-יוחנן-ישראל',
  },
  {
    label: 'רמת ישי',
    value: 'רמת-ישי-ישראל',
  },
  {
    label: 'רמת מגשימים',
    value: 'רמת-מגשימים-ישראל',
  },
  {
    label: 'רמת צבי',
    value: 'רמת-צבי-ישראל',
  },
  {
    label: 'רמת רזיאל',
    value: 'רמת-רזיאל-ישראל',
  },
  {
    label: 'רמת רחל',
    value: 'רמת-רחל-ישראל',
  },
  {
    label: 'רנן',
    value: 'רנן-ישראל',
  },
  {
    label: 'רעים',
    value: 'רעים-ישראל',
  },
  {
    label: 'רעננה',
    value: 'רעננה-ישראל',
  },
  {
    label: 'רקפת',
    value: 'רקפת-ישראל',
  },
  {
    label: 'רשפון',
    value: 'רשפון-ישראל',
  },
  {
    label: 'רשפים',
    value: 'רשפים-ישראל',
  },
  {
    label: 'רתמים',
    value: 'רתמים-ישראל',
  },
  {
    label: 'שאר ישוב',
    value: 'שאר-ישוב-ישראל',
  },
  {
    label: 'שבי ציון',
    value: 'שבי-ציון-ישראל',
  },
  {
    label: 'שבי שומרון',
    value: 'שבי-שומרון-ישראל',
  },
  {
    label: 'שבלי - אום אל-גנם',
    value: 'שבלי---אום-אל-גנם-ישראל',
  },
  {
    label: 'שגב-שלום',
    value: 'שגב-שלום-ישראל',
  },
  {
    label: 'שדה אילן',
    value: 'שדה-אילן-ישראל',
  },
  {
    label: 'שדה אליהו',
    value: 'שדה-אליהו-ישראל',
  },
  {
    label: 'שדה אליעזר',
    value: 'שדה-אליעזר-ישראל',
  },
  {
    label: 'שדה בוקר',
    value: 'שדה-בוקר-ישראל',
  },
  {
    label: 'שדה דוד',
    value: 'שדה-דוד-ישראל',
  },
  {
    label: 'שדה ורבורג',
    value: 'שדה-ורבורג-ישראל',
  },
  {
    label: 'שדה יואב',
    value: 'שדה-יואב-ישראל',
  },
  {
    label: 'שדה יעקב',
    value: 'שדה-יעקב-ישראל',
  },
  {
    label: 'שדה יצחק',
    value: 'שדה-יצחק-ישראל',
  },
  {
    label: 'שדה משה',
    value: 'שדה-משה-ישראל',
  },
  {
    label: 'שדה נחום',
    value: 'שדה-נחום-ישראל',
  },
  {
    label: 'שדה נחמיה',
    value: 'שדה-נחמיה-ישראל',
  },
  {
    label: 'שדה ניצן',
    value: 'שדה-ניצן-ישראל',
  },
  {
    label: 'שדה עוזיהו',
    value: 'שדה-עוזיהו-ישראל',
  },
  {
    label: 'שדה צבי',
    value: 'שדה-צבי-ישראל',
  },
  {
    label: 'שדות ים',
    value: 'שדות-ים-ישראל',
  },
  {
    label: 'שדות מיכה',
    value: 'שדות-מיכה-ישראל',
  },
  {
    label: 'שדי אברהם',
    value: 'שדי-אברהם-ישראל',
  },
  {
    label: 'שדי חמד',
    value: 'שדי-חמד-ישראל',
  },
  {
    label: 'שדי תרומות',
    value: 'שדי-תרומות-ישראל',
  },
  {
    label: 'שדמה',
    value: 'שדמה-ישראל',
  },
  {
    label: 'שדמות דבורה',
    value: 'שדמות-דבורה-ישראל',
  },
  {
    label: 'שדמות מחולה',
    value: 'שדמות-מחולה-ישראל',
  },
  {
    label: 'שדרות',
    value: 'שדרות-ישראל',
  },
  {
    label: 'שואבה',
    value: 'שואבה-ישראל',
  },
  {
    label: 'שובה',
    value: 'שובה-ישראל',
  },
  {
    label: 'שובל',
    value: 'שובל-ישראל',
  },
  {
    label: 'שוהם',
    value: 'שוהם-ישראל',
  },
  {
    label: 'שומרה',
    value: 'שומרה-ישראל',
  },
  {
    label: 'שוקדה',
    value: 'שוקדה-ישראל',
  },
  {
    label: 'שורש',
    value: 'שורש-ישראל',
  },
  {
    label: 'שורשים',
    value: 'שורשים-ישראל',
  },
  {
    label: 'שושנת העמקים',
    value: 'שושנת-העמקים-ישראל',
  },
  {
    label: 'שזור',
    value: 'שזור-ישראל',
  },
  {
    label: 'שחר',
    value: 'שחר-ישראל',
  },
  {
    label: 'שיבולים',
    value: 'שיבולים-ישראל',
  },
  {
    label: 'שילה',
    value: 'שילה-ישראל',
  },
  {
    label: 'שילת',
    value: 'שילת-ישראל',
  },
  {
    label: 'שכניה',
    value: 'שכניה-ישראל',
  },
  {
    label: 'שלווה',
    value: 'שלווה-ישראל',
  },
  {
    label: 'שלוחות',
    value: 'שלוחות-ישראל',
  },
  {
    label: 'שלומי',
    value: 'שלומי-ישראל',
  },
  {
    label: 'שמיר',
    value: 'שמיר-ישראל',
  },
  {
    label: 'שמעה',
    value: 'שמעה-ישראל',
  },
  {
    label: 'שמרת',
    value: 'שמרת-ישראל',
  },
  {
    label: 'שמשית',
    value: 'שמשית-ישראל',
  },
  {
    label: 'שני',
    value: 'שני-ישראל',
  },
  {
    label: 'שניר',
    value: 'שניר-ישראל',
  },
  {
    label: 'שעב',
    value: 'שעב-ישראל',
  },
  {
    label: 'שעל',
    value: 'שעל-ישראל',
  },
  {
    label: 'שעלבים',
    value: 'שעלבים-ישראל',
  },
  {
    label: 'שער אפרים',
    value: 'שער-אפרים-ישראל',
  },
  {
    label: 'שער הגולן',
    value: 'שער-הגולן-ישראל',
  },
  {
    label: 'שער העמקים',
    value: 'שער-העמקים-ישראל',
  },
  {
    label: 'שער מנשה',
    value: 'שער-מנשה-ישראל',
  },
  {
    label: 'שערי תקווה',
    value: 'שערי-תקווה-ישראל',
  },
  {
    label: 'שפיים',
    value: 'שפיים-ישראל',
  },
  {
    label: 'שפיר',
    value: 'שפיר-ישראל',
  },
  {
    label: 'שפר',
    value: 'שפר-ישראל',
  },
  {
    label: 'שפרעם',
    value: 'שפרעם-ישראל',
  },
  {
    label: 'שקד',
    value: 'שקד-ישראל',
  },
  {
    label: 'שקף',
    value: 'שקף-ישראל',
  },
  {
    label: 'שרונה',
    value: 'שרונה-ישראל',
  },
  {
    label: 'שריד',
    value: 'שריד-ישראל',
  },
  {
    label: 'שרשרת',
    value: 'שרשרת-ישראל',
  },
  {
    label: 'שתולה',
    value: 'שתולה-ישראל',
  },
  {
    label: 'שתולים',
    value: 'שתולים-ישראל',
  },
  {
    label: 'תאשור',
    value: 'תאשור-ישראל',
  },
  {
    label: 'תדהר',
    value: 'תדהר-ישראל',
  },
  {
    label: 'תובל',
    value: 'תובל-ישראל',
  },
  {
    label: 'תומר',
    value: 'תומר-ישראל',
  },
  {
    label: 'תושיה',
    value: 'תושיה-ישראל',
  },
  {
    label: 'תימורים',
    value: 'תימורים-ישראל',
  },
  {
    label: 'תירוש',
    value: 'תירוש-ישראל',
  },
  {
    label: 'תל אביב יפו',
    value: 'תל-אביב-יפו-ישראל',
  },
  {
    label: 'תל יוסף',
    value: 'תל-יוסף-ישראל',
  },
  {
    label: 'תל יצחק',
    value: 'תל-יצחק-ישראל',
  },
  {
    label: 'תל מונד',
    value: 'תל-מונד-ישראל',
  },
  {
    label: 'תל עדשים',
    value: 'תל-עדשים-ישראל',
  },
  {
    label: 'תל קציר',
    value: 'תל-קציר-ישראל',
  },
  {
    label: 'תל שבע',
    value: 'תל-שבע-ישראל',
  },
  {
    label: 'תל תאומים',
    value: 'תל-תאומים-ישראל',
  },
  {
    label: 'תלם',
    value: 'תלם-ישראל',
  },
  {
    label: 'תלמי אליהו',
    value: 'תלמי-אליהו-ישראל',
  },
  {
    label: 'תלמי אלעזר',
    value: 'תלמי-אלעזר-ישראל',
  },
  {
    label: 'תלמי ביל"ו',
    value: 'תלמי-בילו-ישראל',
  },
  {
    label: 'תלמי יוסף',
    value: 'תלמי-יוסף-ישראל',
  },
  {
    label: 'תלמי יחיאל',
    value: 'תלמי-יחיאל-ישראל',
  },
  {
    label: 'תלמי יפה',
    value: 'תלמי-יפה-ישראל',
  },
  {
    label: 'תלמים',
    value: 'תלמים-ישראל',
  },
  {
    label: 'תמרת',
    value: 'תמרת-ישראל',
  },
  {
    label: 'תנובות',
    value: 'תנובות-ישראל',
  },
  {
    label: 'תעוז',
    value: 'תעוז-ישראל',
  },
  {
    label: 'תפרח',
    value: 'תפרח-ישראל',
  },
  {
    label: 'תקומה',
    value: 'תקומה-ישראל',
  },
  {
    label: 'תקוע',
    value: 'תקוע-ישראל',
  },
  {
    label: 'תרום',
    value: 'תרום-ישראל',
  },
];

export const CONDITION_OPTIONS = [
  { value: '1', label: 'כל הנכסים' },
  { value: 'asNew', label: 'חדש' },
  { value: 'new', label: 'מקבלן' },
  { value: 'renovated', label: 'משופץ' },
  { value: 'preserved', label: 'שמור' },
  { value: 'toRenovated', label: 'זקוק לתחזוקה' },
];

export const CONDITION_OPTIONS_GA = [
  { value: '1', label: 'all assets' },
  { value: 'asNew', label: 'new' },
  { value: 'new', label: 'from a contractor' },
  { value: 'renovated', label: 'renovated' },
  { value: 'preserved', label: 'save' },
  { value: 'toRenovated', label: 'needs maintenance' },
];

export const TYPE_OPTIONS = [
  { value: 'flat', label: 'דירה' },
  { value: 'villa', label: 'בית פרטי' },
  { value: 'gardenApartment', label: 'דירת גן' },
  { value: 'cottage', label: 'קוטג' },
  { value: 'penthouse', label: 'פנטהאוז' },
  { value: 'attic', label: 'דירת גג' },
];
export const ORDER_OPTIONS = {
  ascending: 'asc',
  descending: 'desc',
};

export const REG_EXP = {
  NUMBERS: /^[0-9\b]+$/,
  HEBREW_ONLY_LETTERS: /^[\u0590-\u05FF ,.'-]+$/i,
  EMAIL: /[a-zA-Z@. 0-9]/,
};

export const IS_LOCAL = process.env.MODE === 'local';
