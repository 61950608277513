import { createAsyncThunk } from '@reduxjs/toolkit';

import { Params, Result, getApartments } from 'controllers/apartments';

import { getCityById } from '../helpers';

export const getItems = createAsyncThunk<Result & { cityName: string }, Params, { rejectValue: string }>(
  'client/get-apartments',
  async (data, { rejectWithValue }) => {
    try {
      const result = await getApartments(data);
      const cityName = getCityById(data.city || '');

      if (result.success) {
        return { ...result, cityName };
      }

      throw new Error(result.error);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
