import { RootState } from '..';

export const selectItems = (state: RootState) => {
  return state.apartments.items;
};

export const selectCity = (state: RootState) => {
  return state.apartments.cityName;
};

export const selectIsNotFounded = (state: RootState) => {
  return state.apartments.isNotFounded;
};

export const selectIsLoading = (state: RootState) => {
  return state.apartments.isLoading;
};

export const selectError = (state: RootState) => {
  return state.apartments.error;
};

export const selectSimilarResultsUrl = (state: RootState) => {
  return state.apartments.similarResultsUrl;
};
