import _fetch from 'isomorphic-fetch';

import { ALL_COUNTRY_DOC_ID, CALCULATION_TYPES } from 'client/constants';
import { Item, MAKO_MADLAN_API_COOKIE_HEADER, MAKO_MADLAN_API_ENDPOINT } from 'client/constants/external-api';

export type Result =
  | { success: true; items: Array<Item>; isSuggestedItems: boolean; similarResultsUrl?: string }
  | { success: false; items: Array<Item>; error: string };
export type Params = {
  city?: string;
  condition?: Array<string> | string;
  type?: Array<string> | string;
  budget: Array<number>;
  calculationType: CALCULATION_TYPES;
  order: 'asc' | 'desc';
};

type ExternalAPIResult =
  | {
      data: {
        fetchCalculatorListings: {
          listings: Array<Item>;
          errorMessage: string | null;
          totalCount: number;
          similarListingsUrl?: string;
        };
      };
    }
  | { errors: [{ message: string }] };

const getGeneralCondition = (condition?: Array<string> | string) => {
  if (!condition || (typeof condition === 'string' && condition === '1') || condition.includes('1')) {
    return '[]';
  }

  if (typeof condition === 'string') {
    return `[${condition}]`;
  }

  return `[${condition.join(',')}]`;
};

const getBuildingType = (buildingType?: Array<string> | string) => {
  if (!buildingType) {
    return '[]';
  }

  if (typeof buildingType === 'string') {
    return `[${buildingType}]`;
  }

  return `[${buildingType.join(',')}]`;
};

const makeApiRequest = async (params: Params) => {
  const maxPrice = params.budget[1];
  const minPrice = params.budget[0];
  const calcType = params.calculationType;
  const generalCondition = getGeneralCondition(params.condition);
  const buildingType = getBuildingType(params.type);
  const cityName = params.city ?? '';
  const order = params.order;
  const body = {
    query: `query fetchCalculatorListings($query: ListingQuery) { fetchCalculatorListings(query: { maxPrice: ${maxPrice}, minPrice: ${minPrice}, limit: 9, calculatorType: ${calcType}, city: "${cityName}" ,generalCondition: ${generalCondition}, buildingType: ${buildingType}, order: ${order}}) { listings {address,price,buildingType,area,roomsNumber,generalCondition,cityName,imageUrl,neighborhoodUrl,neighborhoodName,listingUrl }errorMessage,similarListingsUrl,totalCount}}`,
    operationName: 'fetchCalculatorListings',
    variables: {},
  };

  const response: Response = await _fetch(MAKO_MADLAN_API_ENDPOINT, {
    method: 'POST',
    headers: {
      Cookie: MAKO_MADLAN_API_COOKIE_HEADER,
      'content-type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
    body: JSON.stringify(body),
  });

  if (response.ok === false) {
    throw new Error('Response failed');
  }

  const result: ExternalAPIResult = await response.json();

  if ('errors' in result) {
    throw new Error(result.errors[0].message);
  }

  return result;
};

export const getApartments = async (params: Params): Promise<Result> => {
  try {
    let result = await makeApiRequest(params);
    let isNotFounded = false;

    if (result.data.fetchCalculatorListings.errorMessage === 'No results matching this search') {
      isNotFounded = true;
      result = await makeApiRequest({
        ...params,
        condition: '1',
        type: undefined,
        city: ALL_COUNTRY_DOC_ID,
      });
    }

    return {
      success: true,
      isSuggestedItems: isNotFounded,
      items: result.data.fetchCalculatorListings.listings,
      similarResultsUrl: result.data.fetchCalculatorListings.similarListingsUrl,
    };
  } catch (e) {
    return {
      success: false,
      items: [],
      error: (e as Error).message,
    };
  }
};
