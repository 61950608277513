import cn from 'classnames';
import React from 'react';

import { useCalculationType } from 'client/hooks';

import { BackgroundSvg } from './BackgroundSvg';

import clientLogo from 'client/assets/client-logo-mob.svg';
import n12Logo from 'client/assets/n12-mobile.svg';

import css from './Visual.module.scss';

interface Props {
  className?: string;
}

export const Visual: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { texts, images, type } = useCalculationType();

  return (
    <div className={cn(css.visual, className)}>
      <BackgroundSvg calculationType={type} />
      <div className={css.content}>
        <div className={css.top}>
          <div className={css.preTitle}>{texts.main.visual.preTitle}</div>
          <div>{texts.main.visual.title}</div>
        </div>

        <img className={css.buildingImg} src={images.mainVisualBuilding} alt="" />
      </div>
    </div>
  );
};
