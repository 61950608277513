import { CALCULATION_TYPES } from 'client/constants';

const definedPrimaryColors = {
  [CALCULATION_TYPES.YOUNG]: '#564bce',
  [CALCULATION_TYPES.LUXURY]: '#229da0',
  [CALCULATION_TYPES.INVESTMENT]: '#36a5ed',
};

const definedGaFourIds = {
  [CALCULATION_TYPES.YOUNG]: 'G-6Y7B0P2QYB',
  [CALCULATION_TYPES.LUXURY]: 'G-JP1RCBTB2X',
  [CALCULATION_TYPES.INVESTMENT]: 'G-VKPCPDJ897',
};

const definedStartEndColors = {
  [CALCULATION_TYPES.YOUNG]: {
    startColor: '#564bce',
    endColor: '#3880e8',
  },
  [CALCULATION_TYPES.LUXURY]: {
    startColor: '#229da0',
    endColor: '#26bcb5',
  },
  [CALCULATION_TYPES.INVESTMENT]: {
    startColor: '#35a5ea',
    endColor: '#1d8ad4',
  },
};

export const getPrimaryColor = (calculationType: CALCULATION_TYPES) => {
  if (typeof window !== 'undefined') {
    const el = document.getElementById('root');
    const color = getComputedStyle(el as HTMLDivElement).getPropertyValue('--primary-color');

    return color;
  }

  return definedPrimaryColors[calculationType];
};

export const getGaFourId = (calculationType: CALCULATION_TYPES) => {
  return definedGaFourIds[calculationType];
};

export const getGradientStartEndColors = (calculationType: CALCULATION_TYPES) => {
  if (typeof window !== 'undefined') {
    const el = document.getElementById('root');
    const startColor = getComputedStyle(el as HTMLDivElement).getPropertyValue('--gradient-start-color');
    const endColor = getComputedStyle(el as HTMLDivElement).getPropertyValue('--gradient-end-color');

    return { startColor, endColor };
  }

  return definedStartEndColors[calculationType];
};

export const getTrackBgColor = (type: CALCULATION_TYPES) => {
  switch (type) {
    case CALCULATION_TYPES.YOUNG:
      return 'linear-gradient(90.01deg, #4F5BCB 0%, #4E3CBF 108.51%), linear-gradient(90.01deg, #4F80CB 0%, #165CC7 108.51%), linear-gradient(180deg, #564BCE 0%, #3880E8 100%)';
    case CALCULATION_TYPES.LUXURY:
      return 'linear-gradient(180deg, #229DA0 0%, #26BCB6 100%)';
    case CALCULATION_TYPES.INVESTMENT:
      return 'linear-gradient(90.01deg, #48B0F7 0%, #48B0F7 108.51%), linear-gradient(90.01deg, #4F80CB 0%, #165CC7 108.51%), linear-gradient(180deg, #35A5EA 0%, #37A5F0 100%)';
    default:
      return 'linear-gradient(90.01deg, #4F5BCB 0%, #4E3CBF 108.51%), linear-gradient(90.01deg, #4F80CB 0%, #165CC7 108.51%), linear-gradient(180deg, #564BCE 0%, #3880E8 100%)';
  }
};

export const getThumbBgColor = (type: CALCULATION_TYPES) => {
  switch (type) {
    case CALCULATION_TYPES.YOUNG:
      return 'linear-gradient(180deg, #564BCE 0%, #3880E8 100%)';
    case CALCULATION_TYPES.LUXURY:
      return 'linear-gradient(180deg, #229DA0 0%, #26BCB6 100%)';
    case CALCULATION_TYPES.INVESTMENT:
      return 'linear-gradient(180deg, #35A5EA 0%, #1D8AD5 100%)';
    default:
      return 'linear-gradient(180deg, #564BCE 0%, #3880E8 100%)';
  }
};
