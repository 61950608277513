import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as SettingsModel } from 'models/settings';
import { CALCULATION_TYPES } from 'client/constants';

export type Settings = SettingsModel & {
  lang: 'he' | 'en';
  host: string;
};

const initialState: Settings = {
  lang: 'he',
  host: '',
  texts: {},
  calculationType: CALCULATION_TYPES.YOUNG,
  general: {},
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Partial<Settings>>) => {
      state.texts = action.payload.texts ? action.payload.texts : {};
      state.general = action.payload.general ? action.payload.general : {};
      state.calculationType = action.payload.calculationType ?? CALCULATION_TYPES.YOUNG;
      state.host = action.payload.host || '';
    },
  },
});

export const { init } = settings.actions;
export default settings.reducer;
