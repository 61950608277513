import React from 'react';
import { CALCULATION_TYPES } from 'client/constants';
import { getGradientStartEndColors } from 'client/utils/calculation-type-helper';

type BackgroundSvgProps = {
  calculationType: CALCULATION_TYPES;
};

export const BackgroundSvg = ({ calculationType }: BackgroundSvgProps) => {
  const { startColor, endColor } = getGradientStartEndColors(calculationType);

  const returnBg = () => {
    return (
      <svg width="375" height="322" viewBox="0 0 375 322" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_496_56068)">
          <path
            d="M529 -39.8838C529 148.933 376.105 302 187.5 302C-1.10526 302 -154 148.933 -154 -39.8838C-154 -228.701 -1.10526 -86.8927 187.5 -86.8927C376.105 -86.8927 529 -228.701 529 -39.8838Z"
            fill="white"
            fillOpacity="0.6"
          />
          <path
            d="M529 -39.8838C529 148.933 376.105 302 187.5 302C-1.10526 302 -154 148.933 -154 -39.8838C-154 -228.701 -1.10526 -86.8927 187.5 -86.8927C376.105 -86.8927 529 -228.701 529 -39.8838Z"
            fill="url(#paint0_linear_496_56068)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_496_56068"
            x="-174"
            y="-158"
            width="723"
            height="480"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_56068" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_56068" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_496_56068"
            x1="485.858"
            y1="-97.6289"
            x2="-146.312"
            y2="310.817"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={endColor} />
            <stop offset="1" stopColor={startColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  return <>{returnBg()}</>;
};
