import * as yup from 'yup';

import { CALCULATION_TYPES, CONDITION_OPTIONS, TYPE_OPTIONS, CONDITION_OPTIONS_GA } from 'client/constants';

export const getRealtyFieldFormName = (type: CALCULATION_TYPES) => {
  const namesByType = {
    [CALCULATION_TYPES.YOUNG]: 'condition',
    [CALCULATION_TYPES.LUXURY]: 'type',
    [CALCULATION_TYPES.INVESTMENT]: 'none',
  };

  return namesByType[type];
};

export const fieldToNumber = (field: string) => {
  return Number(field.replace(/\,/g, ''));
};

export const getRealtySelectOptions = (type: CALCULATION_TYPES) => {
  // on investment subdomain we don't render RealtySelect component
  const REALTY_SELECT_OPTIONS_MAP = {
    [CALCULATION_TYPES.YOUNG]: CONDITION_OPTIONS,
    [CALCULATION_TYPES.LUXURY]: TYPE_OPTIONS,
    [CALCULATION_TYPES.INVESTMENT]: [],
  };

  return REALTY_SELECT_OPTIONS_MAP[type];
};

export const getValidationSchema = (type: CALCULATION_TYPES) => {
  const schemasByType = {
    [CALCULATION_TYPES.YOUNG]: yup.object().shape({
      city: yup.string().nullable(),
      budget: yup.array(yup.string()).length(2).required(),
      condition: yup.array(yup.string()).required(),
    }),
    [CALCULATION_TYPES.LUXURY]: yup.object().shape({
      city: yup.string().nullable(),
      budget: yup.array(yup.string()).length(2).required(),
      type: yup.array(yup.string()).required(),
    }),
    [CALCULATION_TYPES.INVESTMENT]: yup.object().shape({
      city: yup.string().nullable(),
      budget: yup.array(yup.string()).length(2).required(),
    }),
  };

  return schemasByType[type];
};

export const getConditionsArray = (type: CALCULATION_TYPES, condition: string[]) => {
  const conditionsArray: string[] = [];

  if (type === CALCULATION_TYPES.YOUNG) {
    if (condition) {
      condition.forEach((element) => {
        const option = CONDITION_OPTIONS_GA.find((opt) => opt.value === element);
        if (option) conditionsArray.push(option.label);
      });
    }
  }
  if (type === CALCULATION_TYPES.LUXURY) {
    if (condition) {
      condition.forEach((element) => {
        const option = TYPE_OPTIONS.find((opt) => opt.value === element);
        if (option) conditionsArray.push(option.label);
      });
    }
  }

  return conditionsArray;
};
