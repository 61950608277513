export const analytics = {
  gtag: {
    event: (action: string, params?: Record<string, string>) => {
      const { gtag } = window;

      if (gtag) {
        console.info('gtag:', action, params);
        gtag('event', action, params);
      } else {
        console.info('%cgtag is undefined', 'color: red');
      }
    },
  },
};
