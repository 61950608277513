import cn from 'classnames';
import React from 'react';

import Icon from '../Icon';
import css from './RedButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  fullWidth?: boolean;
  label: string;
  iconType?: 'left-quotes';
  iconClass?: string;
  withIcon?: boolean;
  withoutRoundCorners?: boolean;
}

export const RedButton: React.FC<Props> = (props) => {
  const {
    className = '',
    fullWidth = false,
    label,
    iconType = 'left-quotes',
    iconClass,
    withIcon,
    withoutRoundCorners,
    ...rest
  } = props;

  return (
    <button
      className={cn(css.redButton, fullWidth && css.fullWidth, withoutRoundCorners && css.withoutCorners, className)}
      {...rest}
    >
      {label}
      {withIcon && iconClass && <Icon type={iconType} className={cn(css[iconClass])} />}
    </button>
  );
};
