import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useCalculationType } from 'client/hooks';
import { analytics } from 'client/utils/ga';
import { IS_LOCAL } from 'client/constants';

import { Item } from 'client/constants/external-api';
import { GENERAL_TEXTS } from 'client/translations/index';
import { CALCULATION_TYPES, CONDITION_OPTIONS } from 'client/constants';

import css from './Apartment.module.scss';

interface Props {
  item: Item;
}

const Apartment: React.FC<Props> = (props) => {
  const {
    item: { buildingType, area, listingUrl, price, imageUrl, generalCondition, cityName, roomsNumber, address },
  } = props;
  const { texts, type: calculationType } = useCalculationType();

  const onClick = () => {
    window.open(listingUrl, '_blank');
    if (!IS_LOCAL) {
      analytics.gtag.event('logo');
    }
  };

  const clickOnButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { address } = e.currentTarget.dataset;
    e.stopPropagation();
    window.open(listingUrl, '_blank');
    if (!IS_LOCAL) {
      analytics.gtag.event('More info', { CUSTOM_PARAMETER: `${address}` });
    }
  };

  if (calculationType === CALCULATION_TYPES.INVESTMENT) {
    return (
      <div className={cn(css.apartmentItem)}>
        <div className={cn(css.apartmentInfo, css.investment)}>
          <div className={css.row}>
            <div className={css.name}>קרית חתני פרס נובל</div>
            <div className={css.cityName}>{cityName}</div>
          </div>
          <div className={css.row}>
            <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.investments.avaragePrice}</div>
            <div className={css.info}>₪ 126,000</div>
          </div>
          <div className={css.row}>
            <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.investments.yieldPercentage}</div>
            <div className={css.info}>85%</div>
          </div>
          <div className={css.row}>
            <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.investments.apartmentsSold}</div>
            <div className={css.info}>1,000 בשנה האחרונה</div>
          </div>
          <div className={css.row}>
            <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.investments.futureProjects}</div>
            <div className={css.info}>1,000 בשנה האחרונה</div>
          </div>
        </div>
        <div className={css.buttonWrapper}>
          <button>{texts.resultsItems.itemBtn}</button>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(css.apartmentItem, css.withHover)} onClick={onClick}>
      <div className={css.photo} style={{ backgroundImage: `url('${imageUrl}')` }}></div>
      <div className={css.apartmentInfo}>
        <div className={css.row}>
          <div className={css.price}>{price}₪</div>
          <div className={css.cityName}>{cityName}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.youngAndLuxury.propertyType}</div>
          <div className={css.info}>{buildingType}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.youngAndLuxury.area}</div>
          <div className={css.info}>{area ?? 0}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.youngAndLuxury.numberOfRooms}</div>
          <div className={css.info}>{parseFloat(roomsNumber)}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.youngAndLuxury.propretyCondition}</div>
          <div className={css.info}>{_.find(CONDITION_OPTIONS, { value: generalCondition })?.label || ''}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowTitle}>{GENERAL_TEXTS.itemsTexts.youngAndLuxury.address}</div>
          <div className={css.info}>
            <span>{address}</span>
          </div>
        </div>
      </div>
      <div className={css.buttonWrapper}>
        <button onClick={clickOnButton} data-address={address}>
          {texts.resultsItems.itemBtn}
        </button>
      </div>
    </div>
  );
};

export default Apartment;
