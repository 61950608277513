import * as yup from 'yup';

import { CALCULATION_TYPES } from 'client/constants';
import { Leads } from 'models/index';
import { ValidationShape, ERROR as VALIDATION_ERROR } from 'endpoints/validation';

import { endpoint, EndpointError } from '../endpoint';

export type CreateParams = Omit<Leads.Model, 'createdAt' | 'id'>;
export type CreateResult = { data: Leads.Model };
export type CreateError = EndpointError;

export const createParamsSchema = yup.object().shape({
  name: yup.string().required('name is required'),
  phoneNumber: yup.string().required('phone number is required'),
  email: yup.string().required('email is required'),
  isApprovedConditions: yup.boolean(),
  isApprovedTerms: yup.boolean(),
  leadType: yup
    .string()
    .oneOf(Object.values(CALCULATION_TYPES), 'lead type is invalid')
    .required('leadType is required'),
} as ValidationShape<CreateParams>);

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => `/leads`,
});
