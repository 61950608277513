import { CALCULATION_TYPES } from 'client/constants';

export type TextByCalcTypItem = {
  main: {
    visual: {
      title: string;
      preTitle: string;
    };
    searchForm: {
      realtySelectTitle: string;
    };
    desktopDescription: string;
  };
  resultsTopSection: {
    withCity: {
      title: string;
    };
    withoutCity: {
      title: string;
      subtitle: string;
    };
    noResults: {
      title: string;
    };
  };
  resultsItems: {
    noResultsTitle: string;
    itemBtn: string;
  };
  seo: {
    title: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
  };
};

export const TEXTS_BY_CALC_TYPE: Record<CALCULATION_TYPES, TextByCalcTypItem> = {
  [CALCULATION_TYPES.YOUNG]: {
    main: {
      visual: {
        title: 'לזוגות צעירים',
        preTitle: 'דירות',
      },
      searchForm: {
        realtySelectTitle: 'מצב הנכס',
      },
      desktopDescription:
        'מחפשים דירה שאתם יכולים להרשות לעצמכם? סמנו את התקציב, את האזור שאתם מחפשים ואנחנו נמצא לכם את הדירות שהכי מתאימות לכם',
    },
    resultsTopSection: {
      withCity: {
        title: 'דירות',
      },
      withoutCity: {
        title: 'דירות',
        subtitle: 'ברחבי הארץ',
      },
      noResults: {
        title: 'לא מצאנו דירות ביישוב בסכום <br> ההשקעה שבחרת',
      },
    },
    resultsItems: {
      noResultsTitle: 'דירות אחרות שיכולות לעניין אותך:',
      itemBtn: 'למידע נוסף',
    },
    seo: {
      title: 'חיפוש דירות לזוגות צעירים | זירת הנדל"ן',
      description: 'מחפשים דירה? סמנו תקציב ואזור ואנחנו כבר נעשה את השאר',
      ogTitle: 'זירת הנדל"ן: חיפוש דירה לזוגות צעירים',
      ogDescription: 'זירת הנדל"ן: חיפוש דירה לזוגות צעירים',
    },
  },
  [CALCULATION_TYPES.LUXURY]: {
    main: {
      visual: {
        title: 'יוקרה',
        preTitle: 'נכסי',
      },
      searchForm: {
        realtySelectTitle: 'סוג הנכס',
      },
      desktopDescription:
        'הבתים הכי יפים, באזורים הכי נחשקים: מחפשים דירה מפנקת? סמנו את האזור שאתם רוצים למצוא בו ואנחנו נמצא את הדירות הנחשקות ביותר',
    },
    resultsTopSection: {
      withCity: {
        title: 'דירות יוקרה',
      },
      withoutCity: {
        title: 'דירות יוקרה',
        subtitle: 'ברחבי הארץ',
      },
      noResults: {
        title: 'לא מצאנו דירות ביישוב בסכום <br> ההשקעה שבחרת',
      },
    },
    resultsItems: {
      noResultsTitle: 'דירות אחרות שיכולות לעניין אותך:',
      itemBtn: 'למידע נוסף',
    },
    seo: {
      title: 'חיפוש דירות יוקרה | זירת הנדל"ן',
      description: 'הבתים הכי יפים, באזורים הכי נחשקים – רשמו תקציב ואזור ואנחנו כבר נעשה את השאר',
      ogTitle: 'זירת הנדל"ן: חיפוש דירות יוקרה',
      ogDescription: 'זירת הנדל"ן: חיפוש דירות יוקרה',
    },
  },
  [CALCULATION_TYPES.INVESTMENT]: {
    main: {
      visual: {
        title: 'להשקעה',
        preTitle: 'שכונות',
      },
      searchForm: {
        realtySelectTitle: '',
      },
      desktopDescription:
        'רוצים להתחיל להשקיע בנדל"ן? מצאתם דירה מתאימה להשקעה? הזינו את כל הפרטים של הנכס ואנחנו נחשב לכם את התשואה החזויה להשקעה שלכם',
    },
    resultsTopSection: {
      withCity: {
        title: 'שכונות להשקעה',
      },
      withoutCity: {
        title: 'שכונות להשקעה',
        subtitle: 'ברחבי הארץ',
      },
      noResults: {
        title: 'לא מצאנו דירות ביישוב בסכום <br> ההשקעה שבחרת',
      },
    },
    resultsItems: {
      noResultsTitle: 'שכונות אחרות שיכולות לעניין אותך:',
      itemBtn: 'נכסים להשקעה',
    },
    seo: {
      title: 'חישוב תשואה על דירה | זירת הנדל"ן',
      description: 'המחשבון שיחשב לכם מה התשואה שתקבלו על דירה להשקעה',
      ogTitle: 'זירת הנדל"ן: כמה תשואה תקבלו על הדירה?',
      ogDescription: 'זירת הנדל"ן: כמה תשואה תקבלו על הדירה?',
    },
  },
};

export const GENERAL_TEXTS = {
  searchForm: {
    rangeSliderTitle: 'כמה תרצו להשקיע?',
    rangeSliderMinInputLabel: 'ממחיר',
    rangeSliderMaxInputLabel: 'עד מחיר',
    rangeSliderAddText: '(טווח מחירים)*',
    citySelectTitle: 'באיזה יישוב?',
    citySelectPlaceholder: 'בכל הארץ',
    conditionSelectTitle: 'מצב הנכס',
    submitBtnLabel: 'להשקעה הכי משתלמת',
    noOptionsText: 'אין אפשרויות',
  },
  madeWith: 'בשיתוף:',
  results: {
    backBtn: 'חזרה לחיפוש',
    moreBtn: 'לנכסים נוספים עבורך >>',
    moreBtnInvestment: 'לשכונות נוספות עבורך >>',
    madlanLogoText: 'המידע בשיתוף:',
    registerNow: 'הירשמו עכשיו ונחזור אליכם עם פרטים נוספים',
  },
  itemsTexts: {
    investments: {
      avaragePrice: 'מחיר ממוצע למ״ר',
      yieldPercentage: 'אחוז התשואה',
      apartmentsSold: 'דירות שנמכרו',
      futureProjects: 'פרוןיקטים עתידיים',
    },
    youngAndLuxury: {
      propertyType: 'סוג נכס',
      area: 'שטח במ״ר',
      numberOfRooms: 'מס׳ חדרים',
      propretyCondition: 'מצב הנכס',
      address: 'כתובת',
    },
  },
  sortSelect: {
    sortBy: 'מיין לפי',
    descending: 'מהמחיר הגבוה לנמוך',
    ascending: 'מהמחיר הנמוך לגבוה',
  },
  leadsForm: {
    top: {
      title: `איפה כדאי לכם<br> <span>להשקיע?</span>`,
      subTitle: `הירשמו עכשיו ונחזור <br> אליכם עם פרטים נוספים`,
    },
    placeholders: {
      name: 'הקלידו את שמכם המלא',
      phoneNumber: 'הקלידו את מספר הטלפון',
      email: 'הקלידו את כתובת המייל',
    },
    labels: {
      name: 'שם מלא*',
      phoneNumber: 'טלפון*',
      email: 'כתובת מייל*',
      isApprovedConditions: `אני מאשר/ת את <a href="">התקנון</a>`,
      isApprovedTerms: `ידוע לי כי לא חלה עליי חובה חוקית למסור את המידע, ואני מוסר/ת את המידע מרצוני ובהסכמתי, לצורך השתתפות בפעילות. המידע יישמר בידי מאקו ויועבר לחברת משלוחים במקרה של זכייה בפרס שמצריך משלוח`,
    },
    errors: {
      name: {
        required: 'יש להזין שם מלא',
      },
      phoneNumber: {
        required: 'יש להזין מספר טלפון',
        invalid: 'יש להזין מספר טלפון תקין',
      },
      email: {
        required: 'יש להזין כתובת אימייל',
        invalid: 'יש להזין כתובת אימייל תקינה',
      },
      checkboxes: {
        required: 'יש לאשר את התקנון ותנאי ההרשמה',
      },
    },
    submitButton: 'להרשמה',
  },
  thanksPopup: {
    title: 'תודה שנרשמתם!',
    subTitle: 'אחד מנציגנו יצור איתך בקשר בהקדם',
    button: 'חזרה לעמוד התוצאות',
  },
};
