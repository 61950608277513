import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import 'client/styles/index.scss';

import { IFRAME_EXAMPLES_PAGE_ROUTE, MAIN_PAGE_ROUTE, RESULTS_PAGE_ROUTE } from 'client/constants/routes';
import { useCalculationType } from 'client/hooks';

import { IframeExamples } from './pages/IframeExamples';
import Results from './pages/Results';
import Main from './pages/Main';

import css from './App.module.scss';

const App: React.FC = () => {
  const { texts, images } = useCalculationType();

  React.useEffect(() => {
    removeRootPreloader();
  }, []);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);
      setTimeout(() => {
        document?.querySelector('.main-preloader')?.remove();
      }, 1500);
    }
  };

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{texts.seo.title}</title>
        <link rel="apple-touch-icon" type="image/png" href="//rcs.mako.co.il/images/apple-touch-icon.png" />
        <link rel="shortcut icon" type="image/ico" href="//rcs.mako.co.il/images/N12favicon/favicon.ico" />
        <meta name="description" content={texts.seo.description} />
        <meta property="og:title" content={texts.seo.ogTitle} />
        <meta property="og:description" content={texts.seo.ogDescription} />
        <meta property="og:image" content={images.shareImg} />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>

        <Route exact path={RESULTS_PAGE_ROUTE}>
          <Results />
        </Route>

        {process.env.MODE !== 'prod' && (
          <Route exact path={IFRAME_EXAMPLES_PAGE_ROUTE}>
            <IframeExamples />
          </Route>
        )}

        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>
    </div>
  );
};

export default App;
