import React from 'react';
import { CSSTransition } from 'react-transition-group';

import css from './Preloader.module.scss';

type Props = {
  visible: boolean;
};

const Preloader: React.FC<Props> = ({ visible }) => {
  return (
    <CSSTransition
      classNames={{
        enter: css.opacityAnimEnter,
        enterActive: css.opacityAnimEnterActive,
        exit: css.opacityAnimExit,
        exitActive: css.opacityAnimExitActive,
      }}
      in={visible}
      unmountOnExit
      timeout={300}
    >
      <div className={css.preloader}></div>
    </CSSTransition>
  );
};

export default Preloader;
