import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Item } from '../../constants/external-api';

import * as actions from './actions';

type Items = {
  items: Item[];
  cityName: string;
  isNotFounded: boolean;
  error: string | null;
  isLoading: boolean;
  similarResultsUrl: string | undefined;
};

const initialState: Items = {
  items: [],
  cityName: '',
  isNotFounded: false,
  error: null,
  isLoading: true,
  similarResultsUrl: undefined,
};

export const apartments = createSlice({
  name: 'apartments',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.items = action.payload.items ? action.payload.items : [];
      state.cityName = action.payload.cityName ? action.payload.cityName : '';
      state.isNotFounded = action.payload.isNotFounded ? action.payload.isNotFounded : false;
      state.similarResultsUrl = action.payload.similarResultsUrl;
      state.error = action.payload.error ?? null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setItems: (state, action: PayloadAction<Items['items']>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getItems.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actions.getItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || null;
      })
      .addCase(actions.getItems.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload.success) {
          state.items = action.payload.items;
          state.isNotFounded = action.payload.isSuggestedItems;
          state.cityName = action.payload.cityName;
          state.similarResultsUrl = action.payload.similarResultsUrl;
        } else {
          state.items = action.payload.items;
          state.error = action.payload.error;
          state.cityName = action.payload.cityName;
        }
      });
  },
});

export const { init, setLoading, setItems } = apartments.actions;
export default apartments.reducer;
