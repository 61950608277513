import React, { useState } from 'react';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';
import queryString from 'query-string';

import {
  selectItems,
  selectCity,
  selectIsNotFounded,
  selectIsLoading,
  selectError,
  selectSimilarResultsUrl,
} from 'client/redux/apartments/selectors';
import { useAppSelector, useAppDispatch } from 'client/hooks';
import { getItems } from 'client/redux/apartments/actions';
import Preloader from 'client/components/common/Preloader';
import { GENERAL_TEXTS } from 'client/translations/index';
import Header from 'client/components/common/Header';
import { RabbiCredit, RedButton } from 'client/components/common';
import { useCalculationType } from 'client/hooks';
import { Params } from 'controllers/apartments';
import { analytics } from 'client/utils/ga';
import { IS_LOCAL, CALCULATION_TYPES } from 'client/constants';

import TopSection from './TopSection';
import Apartment from './Apartment';
import StickyBottom from './StickyBottom';
import ThanksPopup from './ThanksPopup';
import SortSelect from './SortSelect';
import LeadsForm from './LeadsForm';

import css from './Results.module.scss';

interface Props {
  className?: string;
}

type Order = 'asc' | 'desc';

const Results: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isLoaderWithoutOpacity, setIsLoaderWithoutOpacity] = useState(true);
  const location = useLocation();
  const [order, setOrder] = useState(() => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma' });
    const result = params.order ?? 'asc';

    return result as Order;
  });
  const [isShowForm, setIsShowForm] = useState<boolean>(false);
  const [isShowTnaks, setIsShowThanks] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectItems);
  const cityName = useAppSelector(selectCity);
  const isNotFounded = useAppSelector(selectIsNotFounded);
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const similarResultsUrl = useAppSelector(selectSimilarResultsUrl);
  const { texts, type } = useCalculationType();
  const history = useHistory();
  const isInvestment = type === CALCULATION_TYPES.INVESTMENT;

  const setOrderBySelect = (value: 'asc' | 'desc') => {
    const params = queryString.parse(location.search, { arrayFormat: 'comma' });
    params.order = value;

    history.push({
      pathname: location.pathname,
      search: `?${queryString.stringify(params, { arrayFormat: 'comma', skipEmptyString: true })}`,
    });

    setOrder(value);
    if (!IS_LOCAL) {
      switch (value) {
        case 'asc':
          analytics.gtag.event('Sort by', { CUSTOM_PARAMETER: `${GENERAL_TEXTS.sortSelect.ascending}` });
          break;
        case 'desc':
          analytics.gtag.event('Sort by', { CUSTOM_PARAMETER: `${GENERAL_TEXTS.sortSelect.descending}}` });
          break;
        default:
          break;
      }
    }
    setIsLoaderWithoutOpacity(false);
    dispatch(getItems({ ...params, calculationType: type, order: value } as unknown as Params));
  };

  const showForm = () => {
    setIsShowForm(true);
    if (!IS_LOCAL) {
      analytics.gtag.event('Register now and we will get back to you with more details');
    }
  };

  const hideForm = () => {
    setIsShowForm(false);
  };

  const onSimilarResultsBtnClick = () => {
    if (!IS_LOCAL) {
      analytics.gtag.event('for more apartments');
    }
    window.open(similarResultsUrl, '_blank');
  };

  const showThanks = () => {
    setIsShowThanks(true);
  };

  const hideThanks = () => {
    setIsShowThanks(false);
  };

  React.useEffect(() => {
    if (_.size(items) === 0) {
      const params = queryString.parse(location.search, { arrayFormat: 'comma' });

      dispatch(getItems({ ...params, calculationType: type } as unknown as Params));
    }
  }, []);

  return (
    <>
      <Header />
      <div className={cn(css.results, className)}>
        <TopSection isNoResults={isNotFounded} cityName={cityName} />
        {isNotFounded && <p className={css.noResultsTitle}>{texts.resultsItems.noResultsTitle}</p>}
        <div className={css.sortSelectWrapper}>
          <SortSelect setOrder={setOrderBySelect} order={order} />
        </div>
        <div className={css.apartmentsGrid}>
          {!isLoading && error ? <p>{error}</p> : items.map((item) => <Apartment key={item.address} item={item} />)}
        </div>
        {!error && similarResultsUrl && (
          <div className={css.moreBtnWrapper}>
            <RedButton
              onClick={onSimilarResultsBtnClick}
              label={isInvestment ? GENERAL_TEXTS.results.moreBtnInvestment : GENERAL_TEXTS.results.moreBtn}
              fullWidth
            />
          </div>
        )}
      </div>
      <StickyBottom onShowForm={showForm} />
      <Preloader visible={isLoading && isLoaderWithoutOpacity} />
      <LeadsForm visible={isShowForm} onHideForm={hideForm} showThanks={showThanks} />
      <ThanksPopup visible={isShowTnaks} hideThanks={hideThanks} />

      <RabbiCredit className={css.rabbiCredit} />
    </>
  );
};

export default Results;
