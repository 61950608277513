import React from 'react';
import { CALCULATION_TYPES } from 'client/constants';
import { useMediaQuery } from '@mui/material';
import { getGradientStartEndColors } from 'client/utils/calculation-type-helper';

type BackgroundSvgProps = {
  calculationType: CALCULATION_TYPES;
};

export const BackgroundSvg = ({ calculationType }: BackgroundSvgProps) => {
  const isMobile = useMediaQuery('(max-width:745px)');
  const { startColor, endColor } = getGradientStartEndColors(calculationType);

  const returnBg = () => {
    if (isMobile) {
      return (
        <svg width="375" height="322" viewBox="0 0 375 322" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_496_56068)">
            <path
              d="M529 -39.8838C529 148.933 376.105 302 187.5 302C-1.10526 302 -154 148.933 -154 -39.8838C-154 -228.701 -1.10526 -86.8927 187.5 -86.8927C376.105 -86.8927 529 -228.701 529 -39.8838Z"
              fill="white"
              fillOpacity="0.6"
            />
            <path
              d="M529 -39.8838C529 148.933 376.105 302 187.5 302C-1.10526 302 -154 148.933 -154 -39.8838C-154 -228.701 -1.10526 -86.8927 187.5 -86.8927C376.105 -86.8927 529 -228.701 529 -39.8838Z"
              fill="url(#paint0_linear_496_56068)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_496_56068"
              x="-174"
              y="-158"
              width="723"
              height="480"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_56068" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_56068" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_496_56068"
              x1="485.858"
              y1="-97.6289"
              x2="-146.312"
              y2="310.817"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={endColor} />
              <stop offset="1" stopColor={startColor} />
            </linearGradient>
          </defs>
        </svg>
      );
    }

    return (
      <svg width="1541" height="450" viewBox="0 0 1541 450" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_496_56170)">
          <path
            d="M1520.27 -418.782C1507.79 51.6531 1161.96 431.502 747.834 429.635C333.712 427.767 8.11825 44.8901 20.6 -425.545C33.0817 -895.98 359.42 -541.153 773.542 -539.286C1187.66 -537.418 1532.75 -889.217 1520.27 -418.782Z"
            fill="white"
            fillOpacity="0.6"
          />
          <path
            d="M1520.27 -418.782C1507.79 51.6531 1161.96 431.502 747.834 429.635C333.712 427.767 8.11825 44.8901 20.6 -425.545C33.0817 -895.98 359.42 -541.153 773.542 -539.286C1187.66 -537.418 1532.75 -889.217 1520.27 -418.782Z"
            fill="url(#paint0_linear_496_56170)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_496_56170"
            x="0.252686"
            y="-689.351"
            width="1540.34"
            height="1138.99"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.454902 0 0 0 0 0.886275 0 0 0 0.8 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_56170" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_56170" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_496_56170"
            x1="1429.36"
            y1="-563.08"
            x2="-88.3525"
            y2="258.725"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={endColor} />
            <stop offset="1" stopColor={startColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  return <>{returnBg()}</>;
};
