import * as yup from 'yup';

import { Leads } from 'models/index';
import { endpoint, EndpointError } from 'endpoints/endpoint';
import * as Pagination from '../pagination';
import { ValidationShape, ERROR } from '../validation';
import { CALCULATION_TYPES } from 'client/constants';

export type PaginateParams = Pagination.Params<Omit<Leads.Model, 'id'>, null> & {
  leadType: CALCULATION_TYPES;
};

export type PaginateResult = {
  items: Array<Leads.Model>;
  page: number;
  pageSize: number;
  totalPages: number;
};
export type PaginateError = EndpointError;

export const paginationParamsSchema = yup.object().shape({
  page: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  pageSize: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  search: yup.string(),
  leadType: yup.string().oneOf(Object.values(CALCULATION_TYPES), 'invailid get params'),
  sort: yup.object().shape({
    field: yup.string().oneOf(['createdAt'], ERROR.INVALID_VALUE),
    direction: yup.string().oneOf(['asc', 'desc'], ERROR.INVALID_VALUE),
  }),
} as ValidationShape<PaginateParams>);

export const paginate = endpoint<PaginateParams, PaginateResult, PaginateError>({
  method: 'POST',
  url: () => '/leads/paginate',
});
