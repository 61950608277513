import * as React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import css from './TextFieldInput.module.scss';

interface Props {
  className?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  onChange?: (evt: any) => void;
  type?: string;
  pattern?: RegExp;
  isSubmitted?: boolean;
  direction?: 'ltr' | 'rtl';
}

const TextFieldInput: React.FC<Props> = (props) => {
  const {
    className = '',
    name = '',
    placeholder = '',
    onChange,
    type = '',
    pattern,
    isSubmitted,
    label,
    direction = 'rtl',
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);
  const [isFocused, setIsFocused] = React.useState(false);

  const fieldProps = {
    ...field,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      // field.onChange(event);
      onChangeHandler(event);
      if (props.onChange) props.onChange(event);
    },
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
      onFocusHandler();
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      onFocusHandler();
    },
    onReset: () => {
      console.info('reset');
    },
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (pattern) {
      if (e.target.value === '' || pattern.test(e.target.value.toLocaleLowerCase())) {
        helpers.setValue(e.target.value);
      }
    } else {
      helpers.setValue(e.target.value);
    }
  };

  const onFocusHandler = () => {
    if (!isFocused || (field.value && field.value !== '')) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  };

  React.useEffect(() => {
    console.info('isSubmitted', isSubmitted);
    // Reset field focus when form is submitted
    if (isSubmitted) setIsFocused(false);
  }, [isSubmitted]);

  return (
    <div
      className={classNames(
        className,
        css.container,
        isFocused && css.isFocused,
        meta.error && meta.touched && css.isError,
      )}
    >
      <label htmlFor={type}>{label}</label>
      <div className={css.inputWrapper}>
        <input
          className={classNames(css.formInput, isFocused && css.focused)}
          style={{ direction: direction }}
          {...fieldProps}
          type={type}
        />
        <p className={classNames(css.placeholder, isFocused && css.focused)}>{placeholder}</p>
      </div>

      <span className={css.error}>{meta.error && meta.touched ? meta.error : ''}</span>
    </div>
  );
};

export default TextFieldInput;
