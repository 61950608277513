export type Item = {
  address: string;
  price: string;
  buildingType: string;
  area?: string;
  roomsNumber: string;
  generalCondition: string;
  cityName: string;
  imageUrl: string;
  neighborhoodUrl: string;
  neighborhoodName: string;
  listingUrl: string;
};

export const MAKO_MADLAN_API_ENDPOINT = 'https://www.madlan.co.il/api2-external';
export const MAKO_MADLAN_API_COOKIE_HEADER =
  '_pxhd=L1Cs4hE7nUPP9EdnG0OmlyV8JNYCLDydYtgGIBHLvy89aFuL3lGOMAnQ0jO2vp3zPWEmyVWV4VCY8EJqhF34GQ%3D%3D%3AoWwLfJP%2FiK0tN9qUSVVu5VI8HeuUkj2ziBC9nIIi8HG0pjoxaG8rPTM3yUwSKWDI-UO2oMaB3c-8KxSM1m6jsjUJCzbesi9hMshFC7qLck0%3D';
