import cn from 'classnames';
import React, { useMemo } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import _ from 'lodash';

import { RESULTS_PAGE_ROUTE } from 'client/constants/routes';
import { GENERAL_TEXTS as texts } from 'client/translations';
import { RedButton } from 'client/components/common';
import { ALL_COUNTRY_DOC_ID, CALCULATION_TYPES, IS_LOCAL, CITIES_OPTIONS } from 'client/constants';
import { useAppSelector, useCalculationType } from 'client/hooks';

import { thousandsSeparator } from 'utils/thousand-separator';
import { analytics } from 'client/utils/ga';
import {
  fieldToNumber,
  getRealtyFieldFormName,
  getRealtySelectOptions,
  getValidationSchema,
  getConditionsArray,
} from './helpers';
import { RealtySelect } from './RealtySelect';
import { RangeSlider } from './RangeSlider';
import { CitySelect } from './CitySelect';

import css from './SearchForm.module.scss';
import { selectHost } from 'client/redux/settings/selectors';

interface Props {
  className?: string;
}

interface FormValues {
  city: string;
  budget: string[];
  type?: string[];
  condition?: string[];
}

export const SearchForm: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { rangeSliderInitialValues, type } = useCalculationType();
  const realtySelectOptions = getRealtySelectOptions(type);
  const history = useHistory();
  const settingsHost = useAppSelector(selectHost);
  const location = useLocation();
  const locationParams = queryString.parse(location.search, { arrayFormat: 'comma' });

  const getInitialValues = () => {
    const result = {
      city: '',
      budget: [thousandsSeparator(rangeSliderInitialValues[0]), thousandsSeparator(rangeSliderInitialValues[1])],
    };
    if (type === CALCULATION_TYPES.YOUNG) {
      _.set(result, 'condition', [realtySelectOptions[0].value]);
    }

    if (type === CALCULATION_TYPES.LUXURY) {
      _.set(result, 'type', [realtySelectOptions[0].value]);
    }

    // investment subdomain
    return result;
  };

  const formikContext = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getValidationSchema(type),
    onSubmit: async (values: FormValues) => {
      if (!IS_LOCAL) {
        anlyticsData(values, type);
      }
      const queryParams = queryString.stringify(
        {
          ...values,
          city: values.city || ALL_COUNTRY_DOC_ID,
          order: 'asc',
          budget: [fieldToNumber(values.budget[0]), fieldToNumber(values.budget[1])],
        },
        { arrayFormat: 'comma', skipEmptyString: true },
      );

      if (locationParams.iframe) {
        window.open(
          `${window.location.href
            .replace(/^\/+|\/+$/g, '')
            .replace('/?iframe=1', '')}${RESULTS_PAGE_ROUTE}/?${queryParams}`,
          '_blank',
        );
      } else {
        history.push(`${RESULTS_PAGE_ROUTE}/?${queryParams}`);
      }
    },
  });

  const onSubmitBtnClick = () => {
    if (!IS_LOCAL) {
      anlyticsData(formikContext.values, type);
    }
  };

  const href = useMemo(() => {
    const host = `https://${settingsHost}`;
    const queryParams = queryString.stringify(
      {
        ...formikContext.values,
        city: formikContext.values.city || ALL_COUNTRY_DOC_ID,
        order: 'asc',
        budget: [fieldToNumber(formikContext.values.budget[0]), fieldToNumber(formikContext.values.budget[1])],
      },
      { arrayFormat: 'comma', skipEmptyString: true },
    );
    return `${host.replace(/^\/+|\/+$/g, '').replace('/?iframe=1', '')}${RESULTS_PAGE_ROUTE}/?${queryParams}`;
  }, [formikContext.values, settingsHost]);

  return (
    <div className={cn(css.searchForm, className)}>
      <FormikContext.Provider value={formikContext}>
        <Form>
          <RangeSlider
            name="budget"
            min={rangeSliderInitialValues[0]}
            max={rangeSliderInitialValues[1]}
            calculationType={type}
          />
          <CitySelect calculationType={type} name="city" />
          {/* on investment subdomain we don't render RealtySelect component */}
          {type !== CALCULATION_TYPES.INVESTMENT && (
            <RealtySelect options={realtySelectOptions} name={getRealtyFieldFormName(type)} />
          )}

          {locationParams.iframe ? (
            <a
              className={css.submitButtonA}
              href={href}
              onClick={onSubmitBtnClick}
              target="_blank"
              rel="noreferrer"
            >{`${texts.searchForm.submitBtnLabel} >>`}</a>
          ) : (
            <RedButton
              label={`${texts.searchForm.submitBtnLabel} >>`}
              className={css.submitButton}
              fullWidth
              type="submit"
            />
          )}
        </Form>
      </FormikContext.Provider>
    </div>
  );
};

const anlyticsData = (values: FormValues, type: CALCULATION_TYPES) => {
  // City name
  let cityName;
  if (values.city === '') {
    cityName = ALL_COUNTRY_DOC_ID;
  } else {
    const cityResult = CITIES_OPTIONS.find((city) => {
      return city.value === values.city;
    });

    if (cityResult) {
      cityName = cityResult.label;
    } else {
      cityName = ALL_COUNTRY_DOC_ID;
    }
  }

  //Condition
  if (values && values.condition && type === CALCULATION_TYPES.YOUNG) {
    const conditions = getConditionsArray(type, values.condition);
    analytics.gtag.event('Property status', { CUSTOM_PARAMETER: `${conditions.join(', ')}` });
  }

  //Type
  if (values && values.type && type === CALCULATION_TYPES.LUXURY) {
    const conditions = getConditionsArray(type, values.type);
    analytics.gtag.event('Property Type', { CUSTOM_PARAMETER: `${conditions.join(', ')}` });
  }

  analytics.gtag.event('price range', { CUSTOM_PARAMETER: `${values.budget[0]} - ${values.budget[1]}` });
  analytics.gtag.event('city', { CUSTOM_PARAMETER: `${cityName}` });
  analytics.gtag.event('for the most profitable investment');
};
